<div class="bg-brand-terciary pb-82 pt-108">
  <div class="container">
    <div class="row position-relative">
      <div class="col-lg-5 col-md-12 order-1 mb-lg-0 mb-5">
        <div class="left-por">
          <img width="176" src="assets/images/Logo-white.svg" alt="">
          <p class="text-white fs-16 mb-0 font-weight-bold "><span
            class="copyright">&#169;</span>{{ 'kwot_company' | translate }}</p>
          <p class="text-white fs-16 pt-3 font-weight-bold ">{{'since_company' | translate}}</p>
          <!--          <p class="text-white fs-16 pt-3 font-weight-bold  become-vendor">-->
          <!--            <a [href]="appConfig?.becomeAVendorUrl"-->
          <!--              target="_blank">Become a vendor</a>-->
          <!--          </p>-->
          <ul class="d-flex list-unstyled pt-3  p-0 m-0">
            <li>
              <a href="https://www.facebook.com/kwotofficial" target="_blank">
                <img src="assets/images/icons/fb-white.svg" alt="">
              </a>
            </li>
            <li>
              <a href="https://instagram.com/kwotofficial" target="_blank">
                <img src="assets/images/icons/insta-white.svg" alt="">
              </a>
            </li>
            <li>
              <a href="https://twitter.com/kwotofficial" target="_blank">
                <img src="assets/images/icons/twitter-white.svg " alt="">
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/kwotofficial" target="_blank">
                <img src="assets/images/icons/linkedin-white.svg" alt="">
              </a>
            </li>
            <!--            <li>-->
            <!--              <a>-->
            <!--                <img src="assets/images/icons/youtube-white.svg" alt="">-->
            <!--              </a>-->
            <!--            </li>-->
          </ul>
        </div>
      </div>
      <div class="col-md-3 col-sm-6 mb-lg-0 order-sm-2 order-3 mb-5">
        <ul class="list-unstyled m-0 p-0">
          <li><a [routerLink]="['/user', 'return-policy']">{{'return_policy' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'terms-condition']">{{'terms_condition_buyer' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'terms-condition-seller']">{{'terms_condition_seller' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'privacy-policy']">{{'privacy_policy' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'cookie-policy']">{{'cookie_policy' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'shipping-policy']">{{'shipping_info' | translate}}</a></li>
        </ul>
      </div>
      <div class="col-md-2 order-sm-3 order-4 col-sm-6" *ngIf="currentUser">
        <ul class="list-unstyled m-0 p-0">
          <li><a [routerLink]="['/user', 'profile']">{{'profile' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'orders']">{{'my_orders' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'settings']">{{'settings' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'wish-list']">{{'wishlist' | translate}}</a></li>
        </ul>
      </div>
      <div class="col-md-2 order-sm-4 order-5 col-sm-6">
        <ul class="list-unstyled m-0 p-0">
          <li><a [routerLink]="['/user', 'feedback']">{{'feedback' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'faq']">{{'FAQ' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'contact-us']">{{'help_contact' | translate}}</a></li>
          <li><a [routerLink]="['/user', 'about-us']">{{'about_us' | translate}}</a></li>
        </ul>
      </div>
      <div class="col-12 order-sm-5 order-2">
        <div class="ios-img text-sm-end">
          <img [lazyLoad]="'https://s3.amazonaws.com/kwot.files/iso9001.jpg'" class="img-fluid me-3"
               defaultImage="assets/images/loaders/skeleton_loader.gif">
          <img [lazyLoad]="'https://s3.amazonaws.com/kwot.files/iso27001.jpg'" class="img-fluid"
               defaultImage="assets/images/loaders/skeleton_loader.gif">
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mobile-view-footer mobile-footer d-md-none">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <ul class="list-unstyled m-0 p-0 d-flex align-items-center justify-content-between">
          <li>
            <a class="active" [routerLink]="['/user', 'home']"><img src="assets/images/icons/home-icon.svg" alt=""></a>
          </li>
          <li>
            <a [routerLink]="['/user', 'wish-list']"><img src="assets/images/icons/heart-icon.svg" alt=""></a>
          </li>
          <li>
            <a [routerLink]="['/user', 'cart']"><img src="assets/images/icons/cart-icon.svg" alt=""></a>
          </li>
          <li>
            <a [routerLink]="['/user', 'profile']"><img src="assets/images/icons/user-b-icon.svg" alt=""></a>
          </li>
          <li>
            <a [routerLink]="['/user', 'profile']"><img src="assets/images/icons/bar-icon.svg" alt=""></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
