import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Order, OrderStatusText, ReturnOrderStatusText} from "@kwot/data-models";
import {Subject, takeUntil} from "rxjs";
import {CustomTableComponent} from "@kwot/admin-vendor-shared";
import {select, Store} from "@ngrx/store";
import {TranslateService} from "@ngx-translate/core";
import {VendorState} from "../../+state/vendor.reducer";
import {ResetVendorState, VendorOrderManage, VendorOrdersList} from "../../+state/vendor.actions";
import {getVendorOrderInfo, getVendorOrders} from "../../+state/vendor.selectors";
import {WindowService} from "../../../../../shared/src/lib/services/window.service";

@Component({
  selector: 'kwot-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit, OnDestroy {

  ordersList: Order[] = [];
  filters: any[] = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'pending',
      label: 'Pending',
    },
    {
      value: 'shipped',
      label: 'Shipped',
    },
    {
      value: 'return',
      label: 'Return request',
    },
    {
      value: 'canceled',
      label: 'Canceled',
    },
  ];
  selectedFilter: string | number = '';
  unsubscriber = new Subject();
  customDatatableSettings: any = {};
  orderStatus = OrderStatusText;
  returnOrderStatus = ReturnOrderStatusText;
  ordersFoundFirstTime = false;
  isMobile: boolean;
  screenSize: number;

  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;

  constructor(
    private vendorSore: Store<VendorState>,
    private translateService: TranslateService,
    private windowService: WindowService,
  ) {
    this.vendorSore.dispatch(ResetVendorState({params: {error: '', success: '', orders: null, orderInfo: null}}));
    this.subscribeToStore();
    this.windowService.onResize$.subscribe((data) => {
      this.isMobile = data.width <= 767;
    });
  }

  subscribeToStore() {
    this.vendorSore.pipe(select(getVendorOrderInfo))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(orderInfo => {
        if (orderInfo) {
          this.getAllOrders();
        }
      })

    this.vendorSore.pipe(select(getVendorOrders))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(orders => {
        if (orders) {
          this.ordersList = [...orders.data];
          if (this.ordersList.length > 0 && !this.ordersFoundFirstTime) {
            this.ordersFoundFirstTime = true
          }
          if (this.customTableComponent.ajaxCallback) {
            this.customTableComponent.ajaxCallback({
              recordsTotal: orders.count.total,
              recordsFiltered: orders.count.total,
              data: []
            })

            setTimeout(() => {
              this.customTableComponent.reAdjustColumnsInTable();
            }, 500);
          }
        }
      })
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      paging: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      searchPlaceholder: this.translateService.instant('search_by', {field: 'order'}),
      orderColumns: ['orderNo', 'sku', 'date', 'buyer', 'amount', 'status'],
      order: [[2, 'desc']]
    }
    this.screenSize = this.windowService.getScreenSize();
    this.isMobile = this.screenSize <= 767;
  }

  getAllOrders = (page = 0, perPage = 10, search = '', order = {}, criteria: string | number = '') => {
    this.vendorSore.dispatch(VendorOrdersList({
      params: {
        page, limit: perPage,
        ...(search ? {search} : {}),
        ...(criteria ? {criteria} : {}),
        ...order
      }
    }))
  }

  filterOrders(value: string | number) {
    this.selectedFilter = value
    this.getAllOrders(undefined, undefined, undefined, undefined, value);
  }

  rejectReturn(orderId: string) {
    this.vendorSore.dispatch(VendorOrderManage({
      body: {
        orderId: orderId,
        returnStatus: 3
      }
    }))
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete()
  }
}
