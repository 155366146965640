import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

declare const $: any;

@Directive({
  selector: '[appColorPicker]'
})
export class ColorPickerDirective implements OnInit, OnChanges {
  @Input() value: any;
  @Input() isFlat: boolean = false;
  @Input() showInput: boolean = true;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    $(this.el.nativeElement).spectrum({
      type: 'text',
      preferredFormat: 'hex',
      flat: this.isFlat,
      showInput: this.showInput,
      allowEmpty: false,
      showSelectionPalette: false,
      showAlpha: false,
      showButtons: false,
      showInitial: true,
      containerClassName: 'color-container',
      change: (color: any) => {
        if (color) {
          this.changeEvent(color);
        }
      },
      move: (color: any) => {
        if (color) {
          let element = $(this.el.nativeElement).spectrum('container').find('.sp-picker-container .sp-top .sp-top-inner .sp-color .sp-sat .sp-val .sp-dragger');
          if (element) {
            element.css('background', color.toHexString());
          }
          this.changeEvent(color);
        }
      }
    });
  }

  changeEvent(color: any) {
    $(this.el.nativeElement).val(color.toHexString());
    if ('createEvent' in this.el.nativeElement.ownerDocument) {
      const evt = this.el.nativeElement.ownerDocument.createEvent('HTMLEvents');
      evt.initEvent('change', false, true);
      this.el.nativeElement.dispatchEvent(evt);
    } else {
      this.el.nativeElement.fireEvent('onchange');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value && changes.value.currentValue) {
      if (changes.value.currentValue) {
        $(this.el.nativeElement).spectrum('set', changes.value.currentValue);
      }
    }
  }
}
