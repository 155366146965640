import {Inject, Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {map} from 'rxjs';
import {AuthState, getLoggedInUser} from "@kwot/auth";
import {select, Store} from "@ngrx/store";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";

@Injectable({
  providedIn: 'root'
})
export class VerificationGuard implements CanActivate {

  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private ls: LocalstorageService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
  }

  canActivate() {
    return this.authStore.pipe(select(getLoggedInUser)).pipe(
      map(currentUser => {
        if (!currentUser) {
          this.router.navigate(['/vendor', 'login']);
          return false;
        }
        currentUser = this.ls.updateUserKey('get');
        if (currentUser && currentUser.isAdminVerified === 'PENDING' && currentUser.profileStatus !== 7) {
          this.navigateCurrentRoutes(Number(currentUser.profileStatus));
          return false;
        }
        return true;
      })
    )
  }

  navigateCurrentRoutes(profileStatus: number) {
    if (this.appConfig.applicationType === 'b2b') {
      if (profileStatus === 0) {
        this.router.navigate(['/vendor', 'store-setup', 'store-type']);
      }
      if (profileStatus === 1) {
        this.router.navigate(['/vendor', 'store-setup', 'store-preference']);
      }
    } else {
      if (profileStatus === 0 || profileStatus === 1) {
        this.router.navigate(['/vendor', 'store-setup', 'store-preference']);
      }
    }
    if (profileStatus === 2) {
      this.router.navigate(['/vendor', 'store-setup', 'store-name']);
    }
    if (profileStatus === 3) {
      this.router.navigate(['/vendor', 'store-setup', 'store-media']);
    }
    if (profileStatus === 4) {
      this.router.navigate(['/vendor', 'store-setup', 'seller-documents']);
    }
    if (profileStatus === 5) {
      this.router.navigate(['/vendor', 'store-setup', 'seller-billing']);
    }
    if (profileStatus === 6) {
      this.router.navigate(['/vendor', 'store-setup', 'seller-bank-details']);
    }
    if (profileStatus === 7) {
      this.router.navigate(['/vendor', 'store-setup', 'store-product']);
    }
  }

}
