import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from "./components/dashboard/dashboard.component";
import {MenuComponent} from "./components/menu/menu.component";
import {ProductsListComponent} from "./components/products-list/products-list.component";
import {ViewProductComponent} from "./components/view-product/view-product.component";
import {OrdersListComponent} from "./components/orders-list/orders-list.component";
import {ProfileRejectedComponent} from "./_components/profile-rejected/profile-rejected.component";
import {PayoutDetailComponent} from "./components/payout-detail/payout-detail.component";
import {BillingDetailComponent} from "./components/billing-detail/billing-detail.component";
import {FinanceComponent} from "./components/finance/finance.component";
import {ReviewsComponent} from "./components/reviews/reviews.component";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {SharedModule} from "@kwot/shared";
import {ReactiveFormsModule} from "@angular/forms";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {VendorEffects} from "./+state/vendor.effects";
import * as fromVendor from './+state/vendor.reducer';
import {NgApexchartsModule} from "ng-apexcharts";
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import {RouterModule, Routes} from "@angular/router";
import {AdminVendorSharedModule} from "@kwot/admin-vendor-shared";
import {AuthGuard} from "./guards/auth.guard";
import {VerificationGuard} from "./guards/verification.guard";
import {PaymentPolicyComponent} from "../../../vendor-buyer-shared/src/lib/components/payment-policy/payment-policy.component";

const routes: Routes = [

  {
    path: 'store-setup',
    loadChildren: () =>
      import(
        '../../../../libs/vendor-shared/src/lib/modules/store-setup-screens/store-setup-screens.module'
        ).then((module) => module.StoreSetupScreensModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: 'products',
    children: [
      {
        path: '',
        component: ProductsListComponent,
      },
      {
        path: 'add-product',
        loadChildren: () =>
          import('@kwot/product-add-screens').then((module) => {
            module.ProductAddScreensModule.data = {
              hideSidebar: true,
              isAfterDashboard: true,
            };
            return module.ProductAddScreensModule;
          }),
      },
      {
        path: 'edit-product/:productId',
        loadChildren: () =>
          import('@kwot/product-add-screens').then((module) => {
            module.ProductAddScreensModule.data = {
              hideSidebar: true,
              isAfterDashboard: true,
            };
            return module.ProductAddScreensModule;
          }),
      },
      {
        path: 'copy-product/:productId',
        loadChildren: () =>
          import('@kwot/product-add-screens').then((module) => {
            module.ProductAddScreensModule.data = {
              hideSidebar: true,
              isAfterDashboard: true,
            };
            return module.ProductAddScreensModule;
          }),
      },
      {
        path: 'view-product/:productId',
        component: ViewProductComponent,
      },
    ],
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: 'orders',
    component: OrdersListComponent,
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('@kwot/orders').then((module) => module.OrdersModule),
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: 'reviews',
    component: ReviewsComponent,
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: 'finances',
    component: FinanceComponent,
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: 'payoutDetail',
    component: PayoutDetailComponent,
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: 'billingDetail',
    component: BillingDetailComponent,
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: 'payment-policy',
    component: PaymentPolicyComponent,
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('@kwot/user-profile').then(
        (module) => module.UserProfileModule
      ),
    canActivate: [AuthGuard, VerificationGuard],
  },
  {
    path: 'store-settings',
    loadChildren: () =>
      import(
        '../../../../libs/vendor-shared/src/lib/modules/store-settings/store-settings.module'
        ).then((module) => module.StoreSettingsModule),
    canActivate: [AuthGuard, VerificationGuard],
  },
]

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AdminVendorSharedModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('vendor', fromVendor.reducer),
    EffectsModule.forFeature([VendorEffects]),
    NgApexchartsModule,
    NgxIntlTelInputModule
  ],
  declarations: [
    DashboardComponent, MenuComponent, ProductsListComponent,
    ViewProductComponent, OrdersListComponent, ProfileRejectedComponent,
    ReviewsComponent, FinanceComponent, PayoutDetailComponent, BillingDetailComponent
  ],
  exports: [SharedModule, MenuComponent]
})
export class VendorSharedModule {
}
