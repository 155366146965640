import {createFeatureSelector, createSelector} from "@ngrx/store";
import {VendorState} from "./vendor.reducer";

const getVendorState = createFeatureSelector<VendorState>('vendor');

export const getVendorSuccess = createSelector(getVendorState, (state: VendorState) => state.success);

export const getVendorError = createSelector(getVendorState, (state: VendorState) => state.error);

export const getVendorDetails = createSelector(getVendorState, (state: VendorState) => state.vendor);

export const getIsStoreNameAvailable = createSelector(getVendorState, (state: VendorState) => state.isStoreNameAvailable);

export const getCategories = createSelector(getVendorState, (state: VendorState) => state.categories);

export const getProducts = createSelector(getVendorState, (state: VendorState) => state.products);

export const getVendorProduct = createSelector(getVendorState, (state: VendorState) => state.product);

export const getVendorOrders = createSelector(getVendorState, (state: VendorState) => state.orders);

export const getVendorDashboard = createSelector(getVendorState, (state: VendorState) => state.dashboard);

export const getVendorOrderInfo = createSelector(getVendorState, (state: VendorState) => state.orderInfo);

export const getReviews = createSelector(getVendorState, (state: VendorState) => state.reviews);

export const getwallet = createSelector(getVendorState, (state: VendorState) => state.wallet);

export const getWalletTransaction = createSelector(getVendorState, (state: VendorState) => state.walletTransaction);

export const getPayoutData = createSelector(getVendorState, (state: VendorState) => state.payout);

export const getPolicyData = createSelector(getVendorState, (state: VendorState) => state.policy);
