<div class="bank-details position-relative">
  <div class="container-fluid">
    <div class="row">

      <div class="col-md-9 pe-0">
        <div class="bank-details-header d-flex align-items-center justify-content-between">
          <div class="back-button">
            <div class="back-btn d-flex align-items-center" [routerLink]="['/vendor', 'finances']">
              <img src="/assets/images/icons/back.svg" class="cursor-pointer"/>
              <p class="title-back mb-0 fs-16 cursor-pointer">{{'back' | translate}}</p>
            </div>
          </div>
        </div>
        <form [formGroup]="billingForm" (ngSubmit)="!billingForm.invalid && submitForm()" novalidate>
          <div class="bank-details-form">
            <div class="bank-details-header d-flex align-items-center justify-content-between">
              <button type="submit" class="btn-action w-auto"
                      [ngClass]="{'btn-disabled': billingForm.invalid, 'btn-action': !billingForm.invalid}">Save
              </button>
            </div>
            <div class="details">
              <h2 class="fs-48 fw-bold mb-4">
                {{'billing_details' | translate}}
              </h2>
              <h6 class="fs-24 fw-bold">
                {{'tell_about_yourself' | translate}}
              </h6>
            </div>
            <div class="d-flex align-items-center">
              <label class="action-switch-green-btn me-2 m-0">
                <input type="checkbox" formControlName="isBuisness" (change)="changeBillingType($event)">
                <span class="slider round"></span>
              </label>
              <h6 class="ms-2 fs-16">
                {{(form.isBuisness.value ? 'i_m_business' : 'i_m_individual') | translate}}
              </h6>
            </div>
            <div class="details mt-4 row" *ngIf="form.isBuisness.value">
              <div class="form-group col-md-6">
                <div class="d-flex justify-content-between">
                  <label>{{'name_of_entity' | translate}} </label>
                  <ng-container *ngIf="form.buisnessName.errors && form.buisnessName.errors">
                    <label *ngIf="form.buisnessName.errors.required"
                           class="text-danger mb-0 me-2 fs-10 fw-bold">
                      {{ 'errors.is_required' | translate: {field: 'name_of_entity' | translate} }}</label>
                  </ng-container>
                </div>
                <input class="form-control" type="text" formControlName="buisnessName" inputRef
                       placeholder="{{'placeholder.type' | translate:{field: 'name_of_entity' | translate | lowercase} }}">
              </div>
              <div class="form-group col-md-6">
                <div class="d-flex justify-content-between">
                  <label>{{'business_reg_number' | translate}} *</label>
                  <ng-container
                    *ngIf="form.registrationNumber.errors && (form.registrationNumber.touched || form.registrationNumber.dirty)">
                    <label *ngIf="form.registrationNumber.errors.required"
                           class="text-danger mb-0 me-2 fs-10 fw-bold">
                      {{ 'errors.is_required' | translate: {field: 'business_reg_number' | translate} }}</label>
                  </ng-container>
                </div>
                <input class="form-control" type="text" formControlName="registrationNumber" inputRef
                       placeholder="{{'placeholder.type' | translate:{field: 'business_reg_number' | translate | lowercase} }}">
              </div>
            </div>
            <div class="details mt-4">
              <div class="row">
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'first_name' | translate}} *</label>
                    <ng-container *ngIf="form.firstName.errors && (form.firstName.touched || form.firstName.dirty)">
                      <label *ngIf="form.firstName.errors.required"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.is_required' | translate: {field: 'first_name' | translate} }}</label>
                    </ng-container>
                  </div>
                  <input class="form-control" type="text" formControlName="firstName" inputRef
                         placeholder="{{'placeholder.type' | translate:{field: 'first_name' | translate | lowercase} }}">
                </div>
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'last_name' | translate}} *</label>
                    <ng-container *ngIf="form.lastName.errors && (form.lastName.touched || form.lastName.dirty)">
                      <label *ngIf="form.lastName.errors.required"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.is_required' | translate: {field: 'last_name' | translate} }}</label>
                    </ng-container>
                  </div>
                  <input class="form-control" type="text" formControlName="lastName" inputRef
                         placeholder="{{'placeholder.type' | translate:{field: 'last_name' | translate | lowercase} }}">
                </div>
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'dob' | translate}} *</label>
                    <ng-container *ngIf="form.dob.errors && (form.dob.touched || form.dob.dirty)">
                      <label *ngIf="form.dob.errors.required"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.is_required' | translate: {field: 'dob' | translate} }}</label>
                    </ng-container>
                  </div>
                  <input class="form-control" bsDatepicker type="text" formControlName="dob" readonly
                         [bsConfig]="{
                                isAnimated: true, adaptivePosition: true,
                                showWeekNumbers: false, containerClass: 'custom-datepicker',
                                maxDate: maxDate, dateInputFormat: 'DD/MM/YYYY'
                              }"
                         placeholder="DD/MM/YYYY">
                </div>
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'tax_number' | translate}}</label>
                    <ng-container *ngIf="form.nif.errors && (form.nif.touched || form.nif.dirty)">
                      <label *ngIf="form.nif.errors.required"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.is_required' | translate: {field: 'tax_number' | translate} }}</label>
                      <label *ngIf="form.nif.errors.pattern"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.pattern' | translate: {field: 'tax_number' | translate} }}</label>
                    </ng-container>
                  </div>
                  <input class="form-control" type="text" formControlName="nif" inputRef
                         placeholder="{{'placeholder.type' | translate:{field: 'NIF'} }}">
                </div>
              </div>
            </div>
            <div class="details mt-3">
              <h6 class="fs-24 fw-bold">
                {{'home_address' | translate}}
              </h6>
              <div class="row">
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'checkout_address' | translate}} *</label>
                    <ng-container
                      *ngIf="form.streetName.errors && (form.streetName.touched || form.streetName.dirty)">
                      <label *ngIf="form.streetName.errors.required"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.is_required' | translate: {field: 'number' | translate | lowercase} }}</label>
                    </ng-container>
                  </div>
                  <input class="form-control" inputRef type="text" formControlName="streetName" placeholder="Address 1">
                </div>
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'flat_number' | translate}}</label>
                  </div>
                  <input class="form-control" type="text" formControlName="adressLine2"
                         placeholder="{{'placeholder.type' | translate:{field: 'address_lane' | translate | lowercase} }}">
                </div>

                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'country_of_residence' | translate}} *</label>
                    <ng-container *ngIf="form.country.errors && (form.country.touched || form.country.dirty)">
                      <label *ngIf="form.country.errors.required"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.is_required' | translate: {field: 'country_of_residence' | translate} }}</label>
                    </ng-container>
                  </div>
                  <kwot-custom-select
                    class="edit-bank-country"
                    [searchable]="true"
                    [selectOptions]="countries"
                    selectedValue="country"
                    [formGroup]="billingForm"
                    (optionSelected)="countrySelected($event)"
                    placeholder="{{'select_country' | translate}}">
                  </kwot-custom-select>
                </div>
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'state_or_region' | translate}} *</label>
                    <ng-container *ngIf="form.state.errors && (form.state.touched || form.state.dirty)">
                      <label *ngIf="form.state.errors.required"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.is_required' | translate: {field: 'state_or_region' | translate} }}</label>
                    </ng-container>
                  </div>
                  <kwot-custom-select
                    class="edit-bank-country"
                    [searchable]="true"
                    [selectOptions]="states"
                    selectedValue="state"
                    [formGroup]="billingForm"
                    placeholder="{{'choose_one' | translate}}">
                  </kwot-custom-select>
                </div>

                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'city_or_lane' | translate}} *</label>
                    <ng-container *ngIf="form.city.errors && (form.city.touched || form.city.dirty)">
                      <label *ngIf="form.city.errors.required"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.is_required' | translate: {field: 'city_or_lane' | translate} }}</label>
                    </ng-container>
                  </div>
                  <input class="form-control" type="text" formControlName="city" inputRef
                         placeholder="{{'placeholder.type' | translate:{field: 'city_or_lane' | translate | lowercase} }}">
                </div>
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'postal_code' | translate}}</label>
                    <!--                        <ng-container *ngIf="form.postalCode.errors && form.postalCode.touched">-->
                    <!--                          <label *ngIf="form.postalCode.errors.required"-->
                    <!--                                 class="text-danger mb-0 me-2 fs-10 fw-bold">-->
                    <!--                            {{ 'errors.is_required' | translate: {field: 'postal_code' | translate} }}</label>-->
                    <!--                        </ng-container>-->
                  </div>
                  <input class="form-control" type="text" numbersOnly placeholder="00000-X"
                         formControlName="postalCode">
                </div>
                <div class="form-group col-md-6">
                  <div class="d-flex justify-content-between">
                    <label>{{'phone_number' | translate}} *</label>
                    <ng-container *ngIf="form.phone.errors && (form.phone.touched || form.phone.dirty)">
                      <label *ngIf="form.phone?.errors?.required"
                             class="text-danger mb-0 me-2 fs-10 fw-bold">
                        {{ 'errors.is_required' | translate: {field: 'phone_number' | translate} }}</label>
                    </ng-container>
                  </div>
                  <ngx-intl-tel-input
                    class="phone-input-control"
                    cssClass="phone-input form-control"
                    formControlName="phone"
                    [enablePlaceholder]="true"
                    customPlaceholder="000 000 000"
                    [phoneValidation]="true"
                    [numberFormat]="PhoneNumberFormat.International"
                    [enableAutoCountrySelect]="true"
                    [searchCountryFlag]="true"
                    [separateDialCode]="true"
                    [selectFirstCountry]="false"
                    (countryChange)="phoneCountryChanged($event)"
                    [selectedCountryISO]="selectedPhoneCountry"
                    [maxLength]="18"
                    name="phone"
                  >
                  </ngx-intl-tel-input>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

