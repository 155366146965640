<div class="card-two">
  <swiper [slidesPerView]="1" [spaceBetween]="10" (swiper)="onSwiper($event)"
          (slideChange)="onSlideChange()" [pagination]="{clickable: true}">
    <ng-container *ngIf="product">
      <ng-template swiperSlide>
        <img [lazyLoad]="getImage(product, 'medium')" defaultImage="assets/images/loaders/skeleton_loader.gif" alt=""
             (click)="routeClick(product.slug)">
      </ng-template>
      <ng-container *ngIf="product?.extraImages?.length > 0">
        <ng-template swiperSlide>
          <img defaultImage="assets/images/loaders/skeleton_loader.gif" (click)="routeClick(product.slug)"
               [lazyLoad]="getExtraImage(image, 'medium')"
               alt="" *ngFor="let image of product?.extraImages">
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!product;">
      <ng-template swiperSlide><img src="assets/images/recent.jpg" alt=""></ng-template>
    </ng-container>
  </swiper>
</div>
