<ng-template #helpDesk>
  <div class="help me-3 d-md-block d-none">
    <a (click)="openOdooModel()" class="mb-0 fw-medium need-help cursor-pointer">{{'need_help' | translate}}</a>
  </div>
</ng-template>
<kwot-header [currentUser]="currentUser" [helpDesk]="helpDesk" userType="vendor"></kwot-header>
<div class="side-menu">
  <div class="main-menu">
    <ul class="p-0 m-0">
      <li [ngClass]="{'active': (!currentRoute || currentRoute === 'dashboard')}">
        <a [routerLink]="['/vendor', 'dashboard']" class="fs-16">
          <img class="dark-icon me-md-3" src="/assets/images/icons/dashboard-dark-icon.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/dashboard-light-icon.svg" alt="">
          <span> {{'dashboard' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': (currentRoute === 'products')}">
        <a [routerLink]="['/vendor', 'products']" class="fs-16">
          <img class="dark-icon me-md-3" src="/assets/images/icons/products-icon-dark.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/products-icon-light.svg" alt="">
          <span>{{'products_no_bracket' | translate}}</span>
        </a>
      </li>
      <li [ngClass]="{'active': (currentRoute === 'orders')}">
        <a [routerLink]="['/vendor', 'orders']" class="fs-16">
          <img class="dark-icon me-md-3" src="/assets/images/icons/order-dark-icon.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/order-light-icon.svg" alt="">
          <span>{{'orders' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/vendor', 'dashboard']" disabled class="fs-16" containerClass="custom-tooltip"
           tooltip="{{'coming_soon' | translate}}" placement="right">
          <img class="dark-icon me-md-3" src="/assets/images/icons/inbox-dark-icon.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/inbox-light-icon.svg" alt="">
          <span> {{'inbox' | translate}} </span>
        </a>
      </li>
<!--      <li>-->
<!--        <a [routerLink]="['/vendor', 'dashboard']" disabled class="fs-16" containerClass="custom-tooltip"-->
<!--           tooltip="{{'coming_soon' | translate}}" placement="right">-->
<!--          <img class="dark-icon me-md-3" src="/assets/images/icons/total-buyers-dark.svg" alt="">-->
<!--          <img class="light-icon me-md-3" src="/assets/images/icons/total-buyers-light.svg" alt="">-->
<!--          <span> {{'buyers' | translate}} </span>-->
<!--        </a>-->
<!--      </li>-->
      <li [ngClass]="{'active': (currentRoute === 'reviews')}">
        <a [routerLink]="['/vendor', 'reviews']" class="fs-16">
          <img class="dark-icon me-md-3" src="/assets/images/icons/review-dark.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/review-light.svg" alt="">
          <span> {{'reviews' | translate}}</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/vendor', 'finances']" class="fs-16">
          <img class="dark-icon me-3" src="/assets/images/icons/financial-dark-icon.svg" alt="">
          <img class="light-icon me-3" src="/assets/images/icons/financial-light-icon.svg" alt="">
          <span> {{'finances' | translate}} </span>
        </a>
      </li>
      <li [ngClass]="{'active': (currentRoute === 'store-settings')}">
        <a [routerLink]="['/vendor', 'store-settings']" class="fs-16">
          <img class="dark-icon me-md-3" src="/assets/images/icons/settings-icon-dark.svg" alt="">
          <img class="light-icon me-md-3" src="/assets/images/icons/settings-icon-light.svg" alt="">
          <span> {{'store_settings' | translate}} </span>
        </a>
      </li>
    </ul>
  </div>
</div>
