import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[noDecimal]'
})
export class NoDecimalDirective {

  constructor(private _el: ElementRef) {
  }

  @HostListener('keypress', ['$event']) onInputChange(event: any) {
    return event.charCode >= 48 && event.charCode <= 57;
  }

}
