<div class="app-header-white d-none d-lg-block"></div>
<div class="py-lg-3 pt-3 pb-4" [ngClass]="{'close-icon': showCrossIcon}">
  <div class="container-fluid">
    <div class="d-flex justify-content-between mb-3">
      <span id="close" *ngIf="showCrossIcon">
        <img class="close-icon cursor-pointer" src="assets/images/icons/Cross.svg" alt="" (click)="bsModalRef.hide()">
      </span>
    </div>
  </div>
</div>
<div class="main-content pb-72 mt-lg-5">
  <div class="container">
    <span class="fs-16 fs-xs-12 color255A61 font-weight-medium" *ngIf="appConfig.type == 'user'">
      {{ 'kwot' | translate }}
      <span class="neutral_40 text-uppercase">/ {{ currentTitle | translate }}</span>
    </span>
    <div class="col-12 pt-2">
      <h1 class="text-center fs-32 fs-xs-24 brand-black title-cart mb-5 font-weight-bold">
        {{ currentTitle | translate }}
      </h1>
    </div>

    <div>
      <div class="tab-content">
        <div id="shipping" [innerHTML]="currentContent"></div>
      </div>
    </div>
  </div>
</div>
