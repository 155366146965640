import {Component, OnDestroy, OnInit} from '@angular/core';
import {countryCodes, countryCurrency, countryStates, Store as StoreModel} from "@kwot/data-models";
import {Subject, takeUntil} from "rxjs";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {select, Store} from "@ngrx/store";
import * as moment from "moment";
import {PhoneNumberFormat, CountryISO} from "ngx-intl-tel-input";
import {getVendorDetails} from '../../+state/vendor.selectors';
import {GetVendorBusinessDetails, StoreSetupStoreProfile} from '../../+state/vendor.actions';
import {VendorState} from '../../+state/vendor.reducer';

@Component({
  selector: 'kwot-billing-detail',
  templateUrl: './billing-detail.component.html',
  styleUrls: ['./billing-detail.component.scss']
})
export class BillingDetailComponent implements OnInit, OnDestroy {

  vendor: StoreModel;
  unsubscriber = new Subject();
  billingForm: UntypedFormGroup;
  countries: any[] = [];
  states: any[] = [];
  maxDate: any;
  logoutInitiated = false;
  defaultCountry: any = '';
  selectedPhoneCountry: any = '';
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;

  constructor(
    private vendorStore: Store<VendorState>,
    private formBuilder: UntypedFormBuilder
  ) {
    this.billingForm = this.formBuilder.group({
      country: [null, [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      nif: ['', [Validators.pattern(new RegExp("^[A-Za-z0-9]*$"))]],
      streetName: ['', [Validators.required]],
      adressLine2: [''],
      city: ['', [Validators.required]],
      state: [null, [Validators.required]],
      postalCode: [''], //Validators.required
      phone: ['', [Validators.required]],
      phoneCountry: ['', [Validators.required]],
      isBuisness: [false, [Validators.required]],

      buisnessName: [''],
      registrationNumber: [''],
    })
    this.countries = countryCurrency.map(item => ({id: item.iso2, name: item.name}));
    this.subscribeToStore();
    this.billingForm.get('phone').valueChanges.subscribe(val => {
      if (val && typeof val === 'object') {
        this.billingForm.patchValue({
          phoneCountry: val.dialCode.replace('+', '')
        })
      }
    })
  }

  subscribeToStore() {
    this.vendorStore.pipe(select(getVendorDetails))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(vendor => {
        if (vendor) {
          this.vendor = {...vendor};
          this.defaultCountry = this.countries.find(item => item.name === this.vendor.country);
          if (this.defaultCountry) {
            this.defaultCountry = this.defaultCountry.id;
          }
          let name = (this.vendor && this.vendor.name && this.vendor.name.split(' ')) || [];
          let patchValues: any = {
            country: this.defaultCountry || null,
            firstName: name[0] || '',
            lastName: name[1] || '',
          };
          this.countrySelected({value: patchValues.country});
          if (vendor.hasOwnProperty('billingDetails') && vendor.billingDetails) {
            this.changeBillingType({target: {checked: vendor.billingDetails.isBuisness}})
            patchValues = {
              phoneCountry: vendor.billingDetails.phoneCountry,
              country: vendor.billingDetails.country || this.defaultCountry || null,
              firstName: vendor.billingDetails.firstName || name[0] || '',
              lastName: vendor.billingDetails.lastName || name[1] || '',
              dob: moment(vendor.billingDetails.dob, 'YYYY-MM-DD[T]HH[:]mm[:]ss[.000Z]').format('DD/MM/YYYY'),
              nif: vendor.billingDetails.nif,
              streetName: vendor.billingDetails.streetName,
              // streetNumber: vendor.billingDetails.streetNumber,
              adressLine2: vendor.billingDetails.adressLine2,
              city: vendor.billingDetails.city,
              state: vendor.billingDetails.state,
              postalCode: vendor.billingDetails.postalCode,
              phone: vendor.billingDetails.phone,
              isBuisness: vendor.billingDetails.isBuisness,
            }

            if (vendor.hasOwnProperty('buisnessBillingDetails') && vendor.buisnessBillingDetails) {
              patchValues = {
                ...patchValues,
                buisnessName: vendor.buisnessBillingDetails.buisnessName,
                registrationNumber: vendor.buisnessBillingDetails.registrationNumber,
              }
            }
            const countryCode = countryCodes.find((c: any) => c[vendor.billingDetails.phoneCountry || null]);
            if (countryCode) {
              // @ts-ignore
              this.selectedPhoneCountry = countryCode[vendor.billingDetails.phoneCountry];
            }
            this.countrySelected({value: vendor.billingDetails.country || this.defaultCountry});
          }
          this.billingForm.patchValue(patchValues)
        }
      })
  }

  ngOnInit(): void {
    this.maxDate = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
    this.vendorStore.dispatch(GetVendorBusinessDetails());
  }

  submitForm() {
    if (this.billingForm.invalid) {
      return;
    }
    this.saveForm();
  }

  saveForm() {
    const body = {
      ...this.billingForm.value,
      dob: moment(this.billingForm.value.dob).format('YYYY-MM-DD[T]HH[:]mm[:]ss[.000Z]'),
      phone: this.billingForm.value.phone?.number || '',
      profileStatus: 5,
      currentProfileStatus: this.vendor.profileStatus > 5 ? this.vendor.profileStatus : 5
    }

    if (!body.isBuisness) {
      delete body.buisnessName;
      delete body.registrationNumber;
    } else {
      delete body.country;
      delete body.adressLine2;
    }

    this.vendorStore.dispatch(StoreSetupStoreProfile({body, query: {ignoreStatusUpdate: true}}));
  }

  countrySelected(event: any) {
    if (event) {
      this.billingForm.patchValue({
        state: null
      });
      this.states = countryStates.filter(item => item.country_code === event.value)
        .map(item => ({id: item.state_code, name: item.name}));
      if (this.states.length <= 0) {
        this.billingForm.get('state').clearValidators();
      } else {
        this.billingForm.get('state').setValidators([Validators.required]);
      }
    }
    this.billingForm.get('state').updateValueAndValidity();
  }

  phoneCountryChanged(event: any) {
    this.billingForm.patchValue({
      phoneCountry: event.dialCode
    })
    // @ts-ignore
    this.selectedPhoneCountry = this.CountryISO[event.iso2];
  }

  changeBillingType(event: any) {
    this.billingForm.patchValue({
      buisnessName: '', registrationNumber: ''
    })
    if (event.target.checked) {
      this.billingForm.get('buisnessName')?.setValidators([Validators.required])
      this.billingForm.get('registrationNumber')?.setValidators([Validators.required])
      this.billingForm.get('nif')?.setValidators([Validators.required])
    } else {
      this.billingForm.get('buisnessName')?.clearValidators();
      this.billingForm.get('registrationNumber')?.clearValidators();
      this.billingForm.get('nif')?.clearValidators();
    }
    this.billingForm.get('buisnessName')?.updateValueAndValidity();
    this.billingForm.get('registrationNumber')?.updateValueAndValidity();
  }

  get form() {
    return this.billingForm.controls;
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}

