import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})

export class PopupComponent implements OnInit {

  public closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(private bsModalRef: BsModalService){}

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.closeEvent.emit(true);
    this.bsModalRef.hide();
  }

}
