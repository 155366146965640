<div class="modal-body">
  <div class="d-flex align-items-center justify-content-center mb-3">
    <span id="close">
    <img class="close-icon cursor-pointer" src="/assets/images/icons/Cross.svg" alt="" (click)="bsModalRef.hide()">
  </span>
  </div>
  <div class="action--form">
    <div class="form-details">
      <img class="confirm-image" src="assets/images/Logo.svg">
      <h2 class="text-center fw-bold">{{'profile_reject_modal_title' | translate}}</h2>
      <h6 class="fw-medium message text-center mb-4">{{'profile_reject_modal_subtitle' | translate}}</h6>
      <h6 class="fw-medium subtitle text-center mb-4">{{blockedReason}}</h6>
      <h6 class="fw-medium subtitle text-center mb-4">{{blockedMessage}}</h6>
      <button class="btn-cancel" (click)="bsModalRef.hide()">{{'check_your_seller_info' | translate}}</button>
    </div>
  </div>
</div>
