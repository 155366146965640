import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'mapForSelect'
})
export class MapForSelectPipe implements PipeTransform {

  transform(input: any[]): any[] {
    return input.map(item => ({name: item, id: item}))
  }
}
