<div class="vendor-dashboard">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 order-md-1 order-2 d-none" *ngIf="dashboard?.storeProfileData?.isAdminVerified !== 'ACCEPTED'">
        <div class="complete-profile">
          <h2 class="fs-32 fw-bold pb-2">
            {{'complete_your_profile' | translate}}
          </h2>
          <div class="d-flex align-items-center">
            <div class="progress w-100">
              <div class="progress-bar" role="progressbar" style="width: 80%" aria-valuenow="80" aria-valuemin="0"
                   aria-valuemax="100"></div>
            </div>
            <p class="mb-0 fs-12 fw-bold ms-3">
              80%
            </p>
          </div>
          <div class="pending-profile">
            <h3 class="fs-16 fw-bold m-0">
              {{'your_website_profile' | translate}}
            </h3>
            <div class="img-wrapper">
              <img src="assets/images/icons/upload.svg" class="img-fluid" alt="upload icon"/>
            </div>
          </div>
          <div class="pending-profile">
            <h3 class="fs-16 fw-bold m-0">
              {{'payout_details' | translate}}
            </h3>
            <div class="img-wrapper">
              <img src="assets/images/icons/upload.svg" class="img-fluid" alt="upload icon"/>
            </div>
          </div>
          <div class="pending-profile">
            <h3 class="fs-16 fw-bold m-0">
              {{'tax_identity_information' | translate}}
            </h3>
            <div class="img-wrapper">
              <img src="assets/images/icons/plus.svg" class="img-fluid" alt="upload icon"/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 order-md-2 order-2 mb-2" *ngIf="dashboard?.storeProfileData?.isAdminVerified === 'PENDING'">
        <div class="profile-verification">
          <div class="img-wrapper text-center d-md-block d-none">
            <img src="assets/images/profile-verify.png" class="img-fluid" alt="upload icon"/>
          </div>
          <h2 class="fs-24 fw-bold pb-2 text-md-start">
            {{'your_not_verified' | translate}}
          </h2>
          <p class="mb-0 fs-16 ">
            {{'your_not_verified_note' | translate}}
          </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 order-3 mb-2" *ngIf="dashboard?.storeProfileData?.isAdminVerified === 'REJECTED'">
        <div class="profile-verification">
          <div class="img-wrapper text-center d-md-block d-none">
            <img src="assets/images/profile-verify.png" class="img-fluid" alt="upload icon"/>
          </div>
          <h2 class="fs-24 fw-bold pb-2 ">
            {{'your_profile_rejected' | translate}}
          </h2>
          <p class="mb-0 fs-16 ">
            {{'your_profile_rejected_note' | translate}}
          </p>
          <div class="verify-account btn-transparent cursor-pointer" (click)="showRejectReason()">
            {{'view_response' | translate}}
          </div>
        </div>
      </div>
      <div class="col-lg-4 order-3 d-none">
        <div class="profile-verification">
          <div class="img-wrapper text-center">
            <img src="assets/images/bank-verify.png" class="img-fluid" alt="upload icon"/>
          </div>
          <h2 class="fs-24 fw-bold pb-2">
            {{'verify_your_bank_account' | translate}}
          </h2>
          <p class="fs-16 ">
            {{'verify_your_bank_account_note' | translate}}
          </p>
          <div class="verify-account btn-transparent">
            {{'verify_account' | translate}}
          </div>
          <!--          <a href="#" class="btn-transparent">-->
          <!--            Verify account-->
          <!--          </a>-->
        </div>
      </div>
      <div class="col-12 order-3">
        <div class="dashboard-header d-sm-flex align-items-center justify-content-between mb-3 mt-md-5">
          <h3 class="fs-32 fw-bold mb-sm-0">
            {{'your_store_stats' | translate}}
          </h3>
          <div class="form-group mb-0">
            <kwot-custom-select
              class="filter-wallet-data"
              [searchable]="true"
              [placeholder]="'Select Date'"
              [selectOptions]="month"
              (optionSelected)="filterData($event)"
              [(selectedValue)]="monthRange">
            </kwot-custom-select>
          </div>
        </div>
      </div>
      <div class="col-lg-4 order-3">
        <div class="store-status mb-3">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <div class="d-flex align-items-center">
              <div class="img-wrapper me-2">
                <img src="assets/images/icons/revenue.svg" class="img-fluid" alt="upload icon"/>
              </div>
              <h6 class="fs-14 mb-0">
                {{'revenue' | translate}}
              </h6>
            </div>
            <h6 class="fs-14 mb-0">
              {{'visits' | translate}}: <span> {{dashboard?.storeVisitors || 0}} </span>
            </h6>
          </div>
          <div class="price">
            <h3>
              <span>{{ totalPrice | currencyConvert | async}}</span>
            </h3>
            <div class="chart">
              <apx-chart
                [series]="chartOptions.series"
                [grid]="chartOptions.grid"
                [chart]="chartOptions.chart"
                [xaxis]="chartOptions.xaxis"
                [stroke]="chartOptions.stroke"
                [colors]="chartOptions.colors"
                [yaxis]="chartOptions.yaxis"
                [dataLabels]="chartOptions.dataLabels"
                [tooltip]="chartOptions.tooltip"
              >
              </apx-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 order-3">
        <div class="row">
          <div class="col-xl-3 col-sm-4 col-6">
            <div class="store-status-details mb-3">
              <div class="d-flex align-items-center">
                <div class="img-wrapper me-2">
                  <img src="assets/images/icons/ended-order.svg" class="img-fluid" alt="icon"/>
                </div>
                <h6 class="fs-14 mb-0">
                  {{'completed_orders' | translate}}
                </h6>
              </div>
              <h3 class="price fs-24 fw-bold">
                {{dashboard?.endedOrder || 0}}
              </h3>
            </div>
          </div>
          <div class="col-xl-3 col-sm-4 col-6">
            <div class="store-status-details mb-3">
              <div class="d-flex align-items-center">
                <div class="img-wrapper me-2">
                  <img src="assets/images/icons/pending-order.svg" class="img-fluid" alt="icon"/>
                </div>
                <h6 class="fs-14 mb-0">
                  {{'pending_orders' | translate}}
                </h6>
              </div>
              <h3 class="price fs-24 fw-bold">
                {{dashboard?.pendingOrder || 0}}
              </h3>
            </div>
          </div>
          <div class="col-xl-3 col-sm-4 col-6">
            <div class="store-status-details mb-3">
              <div class="d-flex align-items-center">
                <div class="img-wrapper me-2">
                  <img src="assets/images/icons/total-buyers.svg" class="img-fluid" alt="icon"/>
                </div>
                <h6 class="fs-14 mb-0">
                  {{'total_buyers' | translate}}
                </h6>
              </div>
              <h3 class="price fs-24 fw-bold">
                {{dashboard?.totalBuyers || 0}}
              </h3>
            </div>
          </div>
          <div class="col-xl-3 col-sm-4 col-6">
            <div class="store-status-details mb-3">
              <div class="d-flex align-items-center">
                <div class="img-wrapper me-2">
                  <img src="assets/images/icons/new-buyers.svg" class="img-fluid" alt="icon"/>
                </div>
                <h6 class="fs-14 mb-0">
                  {{'new_buyers' | translate}}
                </h6>
              </div>
              <h3 class="price fs-24 fw-bold">
                {{dashboard?.newBuyers || 0}}
              </h3>
            </div>
          </div>
          <div class="col-xl-3 col-sm-4 col-6">
            <div class="store-status-details mb-3">
              <div class="d-flex align-items-center">
                <div class="img-wrapper me-2">
                  <img src="assets/images/icons/return-request.svg" class="img-fluid" alt="icon"/>
                </div>
                <h6 class="fs-14 mb-0">
                  {{'return_request' | translate}}
                </h6>
              </div>
              <h3 class="price fs-24 fw-bold">
                {{dashboard?.returnRequest || 0}}
              </h3>
            </div>
          </div>
          <div class="col-xl-3 col-sm-4 col-6">
            <div class="store-status-details mb-3">
              <div class="d-flex align-items-center">
                <div class="img-wrapper me-2">
                  <img src="assets/images/icons/cancel-order.svg" class="img-fluid" alt="icon"/>
                </div>
                <h6 class="fs-14 mb-0">
                  {{'canceled_orders' | translate}}
                </h6>
              </div>
              <h3 class="price fs-24 fw-bold">
                {{dashboard?.canceldOrder || 0}}
              </h3>
            </div>
          </div>
          <div class="col-xl-3 col-sm-4 col-6">
            <div class="store-status-details mb-3">
              <div class="d-flex align-items-center">
                <div class="img-wrapper me-2">
                  <img src="assets/images/icons/open-tickets.svg" class="img-fluid" alt="icon"/>
                </div>
                <h6 class="fs-14 mb-0">
                  {{'open_tickets' | translate}}
                </h6>
              </div>
              <h3 class="price fs-24 fw-bold">
                {{dashboard?.openTickets || 0}}
              </h3>
            </div>
          </div>
          <div class="col-xl-3 col-sm-4 col-6">
            <div class="store-status-details mb-3">
              <div class="d-flex align-items-center">
                <div class="img-wrapper me-2">
                  <img src="assets/images/icons/solved-tickets.svg" class="img-fluid" alt="icon"/>
                </div>
                <h6 class="fs-14 mb-0">
                  {{'solved_tickets' | translate}}
                </h6>
              </div>
              <h3 class="price fs-24 fw-bold">
                {{dashboard?.solvedTickets || 0}}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 order-md-3 order-4">
        <div class="store-review h-100">
          <div class="title">
            <h2 class="fs-32 fw-bold">
              {{'your_store_reviews' | translate}}
            </h2>
          </div>
          <div class="store-review-bg" *ngIf="dashboard?.reviews.length <= 0">
            <div class="w-100">
              <div class="img-wrapper text-center mb-2">
                <img src="assets/images/profile-verify.png" class="img-fluid" alt="upload icon"/>
              </div>
              <h6 class="fs-16 text-center mx-auto">
                {{'here_you_see_reviews' | translate}}
              </h6>
            </div>
          </div>
          <div class="row" *ngIf="dashboard?.reviews.length > 0">
            <div class="col-lg-12" *ngFor="let review of dashboard?.reviews">
              <div class="store-status-details product-status mb-3">
                <div class="d-flex align-items-center">
                  <div class="img-wrapper me-2">
                    <img [lazyLoad]="review?.product?.coverImage" [ngStyle]="{width: '60px', height: '50px'}"
                         [defaultImage]="'/assets/images/Photo.jpg'" class="img-fluid" alt="icon"/>
                  </div>
                  <p class="fs-14 fw-bold mb-0">
                    {{review?.product?.productName}}
                  </p>
                </div>
                <h3 class="price fs-24 fw-bold">
                  <ul class="list-unstyled p-0 m-0 d-flex">
                    <li *ngFor="let stars of [].constructor(review.rating || 0)">
                      <img [ngStyle]="{width: '15px', height: '15px'}" src="assets/images/icons/star-icon.svg" alt=""/>
                    </li>
                    <li *ngFor="let stars of [].constructor(5 - (review.rating || 0))">
                      <img [ngStyle]="{width: '15px', height: '15px'}" src="assets/images/icons/star-border-icon.svg"
                           alt=""/>
                    </li>
                  </ul>
                  <span class="fs-16 fw-normal">
                    {{review.review}}
                  </span>
                </h3>
              </div>
            </div>
            <div class="col-lg-12">
              <span class="view-all" [routerLink]="['/vendor', 'reviews']">{{'see_more' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 mb-sm-0 order-3 mb-sm-5 pb-sm-0 pb-sm-4">
        <div class="store-review h-100">
          <div class="title">
            <h2 class="fs-32 fw-bold">
              {{'products_stock' | translate}}
            </h2>
          </div>
          <div class="store-review-bg"
               *ngIf="(dashboard?.activeProducts === 0 && dashboard?.soldOutProducts === 0); else productStatus">
            <div class="w-100">
              <div class="img-wrapper text-center mb-2">
                <img src="assets/images/product-stock.png" class="img-fluid" alt="upload icon"/>
              </div>
              <h6 class="fs-16 text-center mb-3 mx-auto">
                {{'no_product' | translate}}
              </h6>
              <button class="btn-action mx-auto d-block" [routerLink]="['/vendor', 'products', 'add-product']">
                {{'start_adding_products' | translate}}
              </button>
            </div>
          </div>
          <ng-template #productStatus>
            <div class="row">
              <div class="col-lg-12">
                <div class="store-status-details product-status flex-row mb-3" [routerLink]="['/vendor', 'products']">
                  <div class="d-flex align-items-center">
                    <div class="img-wrapper me-2">
                      <img src="assets/images/icons/tick.svg" class="img-fluid" alt="icon"/>
                    </div>
                    <h6 class="fs-14 mb-0">
                      {{'active_products' | translate}}
                    </h6>
                  </div>
                  <h3 class="price fs-24 fw-bold">
                    {{dashboard?.activeProducts || 0}}
                  </h3>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="store-status-details product-status flex-row mb-3" [routerLink]="['/vendor', 'products']">
                  <div class="d-flex align-items-center">
                    <div class="img-wrapper me-2">
                      <img src="assets/images/icons/sold_out.svg" class="img-fluid" alt="icon"/>
                    </div>
                    <h6 class="fs-14 mb-0">
                      {{'sold_products' | translate}}
                    </h6>
                  </div>
                  <h3 class="price fs-24 fw-bold">
                    {{dashboard?.soldOutProducts || 0}}
                  </h3>
                </div>
              </div>
              <div class="col-lg-12">
                <span class="view-all" [routerLink]="['/vendor', 'products']">{{'view_all_products' | translate}}</span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="modal fade" id="verify-account" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content border-0">
            <div class="modal-header d-flex align-items-center justify-content-end">
              <!--              <h5 class="modal-title text-center fs-16 fw-bold">color</h5>-->
              <button type="button" class="new-option-close btn-close" data-bs-dismiss="modal" aria-label="Close">
                <img src="assets/images/icons/Cross.svg" alt="close icon"/>
              </button>
            </div>
            <div class="modal-body">
              <div class="details text-center">
                <h3 class="fs-24 fw-bold">
                  Verify your bank account
                </h3>
                <p>
                  We made a small deposit to your bank account ending in 5849. Tell us the right amount and you’ll be
                  all set.
                </p>
                <p>
                  Quick note: Always enter the latest amount we send-any deposit amounts we sent previously won’t work.
                </p>
                <div class="form-group text-start">
                  <label> Deposit amount </label>
                  <div class="position-relative">
                    <input type="text" class="form-control" placeholder="Type here the deposit amount">
                    <span class="fs-14">
                      £
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn-disabled modal-button">
                Verify account
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
