<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
             let-onCheckedChange="onCheckedChange">
  <div class="tree-item-details" [ngClass]="{'selected': value === item.value}">
    <div *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
      <i *ngSwitchCase="true" class="fa fa-chevron-right"></i>
      <i *ngSwitchCase="false" class="fa fa-chevron-down"></i>
    </div>
    <div class="category" (click)="select(item)">
      <img [lazyLoad]="getImage(item.value)" *ngIf="showImage" defaultImage="assets/images/Photo1.jpg"
           alt="{{item.name}}"/>
      {{ item.text }}
    </div>
  </div>
</ng-template>
<ng-template #headerTemplate let-config="config" let-item="item" let-onCollapseExpand="onCollapseExpand"
             let-onCheckedChange="onCheckedChange" let-onFilterTextChange="onFilterTextChange">
  <div *ngIf="config.hasFilter" class="row row-filter">
    <div class="col-12">
      <input class="form-control" type="text" [placeholder]="'Search category'" [(ngModel)]="filterText"
             (ngModelChange)="onFilterTextChange($event)"/>
    </div>
  </div>
</ng-template>
<div class="dropdown-tree">
  <ngx-dropdown-treeview
    [disabled]="isDisabled" [ngxDisabledOnSelector]="'button.dropdown-toggle'"
    [config]="config" [headerTemplate]="headerTemplate" [items]="items"
    [itemTemplate]="itemTemplate">
  </ngx-dropdown-treeview>
</div>
