import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class StoreSetupService {
  private saveLogoutSubject: BehaviorSubject<boolean>;
  public saveLogout$: Observable<boolean>;

  private safeLogoutSubject: BehaviorSubject<boolean>;
  public safeLogout$: Observable<boolean>;

  constructor() {
    this.saveLogoutSubject = new BehaviorSubject<boolean>(false);
    this.saveLogout$ = this.saveLogoutSubject.asObservable();

    this.safeLogoutSubject = new BehaviorSubject<boolean>(false);
    this.safeLogout$ = this.safeLogoutSubject.asObservable();
  }

  updateLogout() {
    this.saveLogoutSubject.next(true);
  }

  updateSaved() {
    this.safeLogoutSubject.next(true);
  }

  updateValues() {
    this.saveLogoutSubject.next(false);
    this.safeLogoutSubject.next(false);
  }

}
