<div class="loader1" *ngIf="(loader$ | async) || loadingRouteConfig">
  <!--  <img class="desktop-loader" src="/assets/images/loaders/desktop_modal.gif"/>-->
  <!--  <img class="mobile-loader" src="/assets/images/loaders/mobile_modal.gif"/>-->
  <div class="d-flex flex-column" style="width: 200px">
    <span class="loader mx-auto"></span>
    <div class="upload-progress" *ngIf="(uploader$ | async)">
      <div class="progress-item">
        <p class="text-center text-light">Uploading</p>
        <div class="progress">
          <div class="progress-bar" role="progressbar" [style]="{width: (progress$ | async) + '%'}"
               aria-valuenow="25" aria-valuemin="0"
               aria-valuemax="100">{{(progress$ | async) + '%'}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container>
  <kwot-vendor-menu
    *ngIf="currentUser && currentRoute !== 'store-setup' && showMenu"
    [currentUser]="currentUser"></kwot-vendor-menu>
  <div *ngIf="currentUser && (!currentUser.isEmailVerified && !currentUser.isPhoneVerified) && currentRoute === 'store-setup'"
       class="vendor-profile-not-verified container-fluid m-0 p-0">
    <div class="col-md-12 section">
      {{'please_complete_account_verification' | translate}}
      <button class="send-verification" (click)="sendVerificationLink()">{{'here' | translate | lowercase}}</button>
    </div>
  </div>
  <div [ngClass]="{'page-container': !!currentUser && currentRoute !== 'store-setup' && showMenu}">
    <div *ngIf="currentUser && (!currentUser.isEmailVerified && !currentUser.isPhoneVerified) && showMenu && currentRoute !== 'store-setup'"
         class="vendor-profile-not-verified container-fluid mb-3">
      <div class="col-md-12 section">
        {{'please_complete_account_verification' | translate}}
        <button class="send-verification" (click)="sendVerificationLink()">{{'here' | translate | lowercase}}</button>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</ng-container>
