import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, takeUntil} from "rxjs";
import {LoaderService, LocalstorageService} from "@kwot/app-config";
import {VendorState} from "../../../../libs/vendor-shared/src/lib/+state/vendor.reducer";
import {select, Store} from "@ngrx/store";
import {
  getPolicyData,
  getVendorError,
  getVendorSuccess
} from "../../../../libs/vendor-shared/src/lib/+state/vendor.selectors";
import {ToastrService} from "ngx-toastr";
import {User} from "@kwot/data-models";
import {AuthState, getLoggedInUser} from "@kwot/auth";
import {NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
  AcceptPolicyDetail,
  GetPolicyDetail,
  ResendProfileVerification
} from "../../../../libs/vendor-shared/src/lib/+state/vendor.actions";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ConfirmPolicyComponent} from "../../../../libs/vendor-buyer-shared/src/lib/components/confirm-policy/confirm-policy.component";

@Component({
  selector: 'kwot-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {
  loader$: Observable<boolean>;
  uploader$: Observable<boolean>;
  progress$: Observable<number>;
  unsubscriber = new Subject();
  currentUser: User;
  currentRoute: string;
  loadingRouteConfig: boolean;
  isProfileVerification: boolean = false;
  showMenu: boolean = true;
  isPolicyDetailsCalled = false;

  constructor(
    private loaderService: LoaderService,
    private changeDetector: ChangeDetectorRef,
    private authStore: Store<AuthState>,
    private vendorStore: Store<VendorState>,
    private toastr: ToastrService,
    private router: Router,
    private translateService: TranslateService,
    private localStorageService: LocalstorageService,
    private modelService: BsModalService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter(t => t);
        if (url[1]) {
          this.currentRoute = url[1];
          this.validatePolicy();
          if (this.currentRoute === 'dashboard') {
            this.showMenu = true;
            this.currentUser = this.localStorageService.updateUserKey('get');
          }
          if (this.currentRoute.includes('validate-otp') || this.currentRoute.includes('email-verification') || this.currentRoute.includes('reactivate-account') || this.currentRoute.includes('reset-password')) {
            this.showMenu = false;
          } else {
            this.showMenu = true;
            this.currentUser = this.localStorageService.updateUserKey('get');
          }
        }
      }
    })
  }

  validatePolicy() {
    let policyToCheck: any[] = [];
    if (this.currentRoute === 'dashboard') {
      policyToCheck = ['sellerPolicy', 'sellerTerms', 'cookiePolicy'];
    }
    if (this.currentRoute === 'finances') {
      policyToCheck = ['paymentPolicy'];
    }
    if (policyToCheck.length > 0) {
      this.vendorStore.dispatch(GetPolicyDetail({params: {policies: JSON.stringify(policyToCheck)}}))
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.loader$ = this.loaderService.loader$;
    this.uploader$ = this.loaderService.uploader$;
    this.progress$ = this.loaderService.progress$;
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        const validateOTP = this.localStorageService.updateRegisterNumber('get');
        if (!validateOTP && currentUser) {
          this.currentUser = currentUser;
        } else {
          this.currentUser = null;
        }
      })

    this.vendorStore.pipe(select(getVendorError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error && error !== '---IGNORE---') {
          this.toastr.error(error);
        }
      })
    this.vendorStore.pipe(select(getVendorSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.isProfileVerification) {
            this.isProfileVerification = false;
            if (this.currentUser.email) {
              this.toastr.warning(this.translateService.instant('verify_email_sent'), this.translateService.instant('email_sent'));
            } else {
              this.showMenu = false;
              this.localStorageService.updateRegisterNumber('store', this.currentUser.phone);
              this.localStorageService.updateCountryCode('store', this.currentUser.phoneCountry);
              this.localStorageService.updateRedirectUrl('store', 'dashboard');
              this.localStorageService.updateCommonKey('store', {redirect: false});
              this.router.navigate(['/vendor', 'validate-otp'])
              this.toastr.warning(this.translateService.instant('verify_otp_sent'), this.translateService.instant('sms_sent'));
            }
          } else {
            if (success !== '---IGNORE---') {
              this.toastr.success(success);
            }
          }
        }
      })
    this.vendorStore.pipe(select(getPolicyData))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(policy => {
        if (policy) {
          let policyToVerify: any = [];
          policy.policies.forEach((item: any) => {
            if (policy.user[item.terms] !== item.version) {
              policyToVerify.push(item);
            }
          })

          this.verifyPolicies(policyToVerify);
        }
      })
  }

  verifyPolicies(policies: any) {
    if (policies.length > 0) {
      const modelRef: BsModalRef = this.modelService.show(ConfirmPolicyComponent, {
        keyboard: false,
        animated: true,
        ignoreBackdropClick: true,
        class: 'modal-lg modal-dialog-centered',
        initialState: {
          policies: policies
        }
      });
      modelRef.content.closeEvent.subscribe((result: any) => {
        this.vendorStore.dispatch(AcceptPolicyDetail({params: {policies: JSON.stringify(policies.map((t: any) => t.terms))}}));
      });
    }
  }

  sendVerificationLink() {
    this.isProfileVerification = true;
    this.vendorStore.dispatch(ResendProfileVerification({
      params: {
        redirect_url: `${window.location.origin}/vendor/verify-email`
      }
    }));
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }
}
