import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Categories, countryCurrency, Product, User} from "@kwot/data-models";
import {ActivatedRoute, Router} from "@angular/router";
import {
  DeleteVendorProduct,
  GetVendorDetails,
  GetVendorProduct,
  ResetVendorState,
  UpdateVendorProduct
} from "../../+state/vendor.actions";
import {select, Store} from "@ngrx/store";
import {VendorState} from "../../+state/vendor.reducer";
import {getVendorDetails, getVendorProduct, getVendorSuccess} from "../../+state/vendor.selectors";
import {Subject, takeUntil} from "rxjs";
import {ToastrService} from "ngx-toastr";
import {AuthState, getLoggedInUser} from "@kwot/auth";
import {TranslateService} from "@ngx-translate/core";
import {CustomTableComponent} from "@kwot/admin-vendor-shared";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CustomModalComponent} from "../../../../../shared/src/lib/components/custom-modal/custom-modal.component";
import {PascalCasePipe} from "../../../../../shared/src/lib/pipes/pascalCase.pipe";
import {UploadImageService} from "../../../../../shared/src/lib/services/upload-image.service";
import {APP_CONFIG} from "@kwot/app-config";

@Component({
  selector: 'kwot-view-product',
  templateUrl: './view-product.component.html',
  styleUrls: ['./view-product.component.scss'],
  providers: [PascalCasePipe]
})
export class ViewProductComponent implements OnInit, OnDestroy {

  product: Product;
  productId: string;
  unsubscriber = new Subject();
  currentUser: User;
  currencySymbol: any;
  customDatatableSettings: any = {
    paging: false,
    searching: false
  };
  tableList: any[] = [];
  tableHeaders: string[] = [];
  valueKeys: string[] = [];
  variationsOptions: any = {
    selectedPrimaryColor: [],
    selectedSecondaryColor: [],
    selectedPrimaryMaterial: [],
    selectedSecondaryMaterial: [],
    varyPrice: false,
    varyQuantity: false
  }
  @ViewChild('customTableComponent') customTableComponent: CustomTableComponent;
  isEditAction = false;
  isDeleteAction = false;
  modalRef?: BsModalRef | null;
  selectedCategory: Categories | any;
  priceRange: any;
  discountPrice: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private vendorStore: Store<VendorState>,
    private toastr: ToastrService,
    private authStore: Store<AuthState>,
    private translateService: TranslateService,
    private modelService: BsModalService,
    private router: Router,
    private pascalPipe: PascalCasePipe,
    private uploadImage: UploadImageService,
    @Inject(APP_CONFIG) public appConfig: any
  ) {
    this.vendorStore.dispatch(ResetVendorState({params: {error: '', success: '', product: null}}));
    this.productId = this.activeRoute.snapshot.params.productId;
    this.product = null;
    this.subscribeToStore();
  }

  ngOnInit(): void {
    this.vendorStore.dispatch(GetVendorDetails({id: this.currentUser.id}));
    if (this.productId) {
      this.vendorStore.dispatch(GetVendorProduct({id: this.productId}));
    }
  }

  subscribeToStore() {
    this.authStore.pipe(select(getLoggedInUser))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(currentUser => {
        if (currentUser) {
          this.currentUser = currentUser;
        }
      })
    this.vendorStore.pipe(select(getVendorSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.isDeleteAction) {
            this.isDeleteAction = false;
            this.modalRef?.hide();
            this.toastr.warning(this.translateService.instant('product_removed'));
            this.router.navigate(['/vendor', 'products'])
          }
        }
      })
    this.vendorStore.pipe(select(getVendorProduct))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(product => {
        if (product) {
          if (!this.isEditAction) {
            this.product = {...product};
            this.selectedCategory = product.categoryId;
            this.generateTable();
          } else {
            this.isEditAction = false;
            this.product.isActive = product.isActive;
            if (this.product.isActive) {
              this.toastr.success(this.translateService.instant('product_activated'));
            } else {
              this.toastr.warning(this.translateService.instant('product_deactivated'));
            }
            this.modalRef?.hide();
          }
        }
      })
    this.vendorStore.pipe(select(getVendorDetails))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(vendor => {
        if (vendor) {
          const country = countryCurrency.find(item => item.name === vendor.country);
          if (country) {
            this.currencySymbol = country.currency;
          }
        }
      })
  }

  generateOptions() {
    this.variationsOptions.selectedPrimaryColor = this.product?.primaryColour;
    this.variationsOptions.selectedPrimaryMaterial = this.product?.primaryMaterial;
    this.variationsOptions.selectedSecondaryMaterial = this.product?.secondaryMaterial;
    this.variationsOptions.selectedSecondaryColor = this.product?.secondaryColour;
    if (this.product?.variant?.length > 0) {
      let firstVariant = this.product?.variant[0];
      if (firstVariant) {
        this.variationsOptions.varyPrice = firstVariant.hasOwnProperty('price');
        this.variationsOptions.varyQuantity = firstVariant.hasOwnProperty('qtyAvailable');
      }
    }
  }

  generateTable() {
    this.generateOptions();
    this.tableHeaders = [];
    this.valueKeys = [];
    const variationList = Object.keys(this.selectedCategory);
    variationList.forEach((item: any) => {
      if (this.selectedCategory[item] && this.selectedCategory[item].isSelected && this.selectedCategory[item].isVariant) {
        this.valueKeys.push(item)
        this.tableHeaders.push(this.pascalPipe.transform(item))
      }
    })
    this.product.dynamicKey.forEach((item: any) => {
      if (item.key && item.key.isSelected && item.key.isVariant) {
        this.valueKeys.push(item.key.key)
        this.tableHeaders.push(this.pascalPipe.transform(item.key.key))
      }
    })
    if (this.variationsOptions.varyPrice) {
      this.tableHeaders.push(this.translateService.instant('price'));
    }
    if (this.variationsOptions.varyQuantity) {
      this.tableHeaders.push(this.translateService.instant('quantity'));
    }
    this.tableList = this.product?.variant.map((item: any) => {
      const newItem: any = {
        primaryColour: this.product?.primaryColour.find((c: any) => c._id === item.primaryColour),
        secondaryColour: this.product?.secondaryColour.find((c: any) => c._id === item.secondaryColour),
        primaryMaterial: this.product?.primaryMaterial.find((c: any) => c._id === item.primaryMaterial),
        secondaryMaterial: this.product?.secondaryMaterial.find((c: any) => c._id === item.secondaryMaterial),
        size: this.selectedCategory.size.list.find((c: any) => c === item.size),
        price: item.price,
        qtyAvailable: item.qtyAvailable || item.quantity,
      }
      this.product.dynamicKey.forEach((dyk: any) => {
        if (dyk.key && dyk.key.isSelected && dyk.key.isVariant) {
          let dynamicValue = item.other.find((c: any) => {
            if (typeof c.key === 'string') {
              return (dyk.key.id === c.key || dyk.key._id === c.key)
            } else {
              return (dyk.key._id === c.key?.id)
            }
          });
          newItem[dyk.key.key] = dynamicValue ? dynamicValue.value : '-';
        }
      })
      return newItem;
    });
    this.priceRange = this.product?.variant.map((item: any) => {
      const newItem: any = {
        priceRange: item.priceRange
      }
      return newItem;
    });
    this.discountPrice = this.product.priceRange.map((item: any) => {
      const newItem: any = {
        ...item
      }
      if(this.product?.price) {
        newItem['price'] = this.product.price as any - (this.product?.price as any * item.discount / 100)
      }
      return newItem;
    });

    this.adjustColumn();
  }

  adjustColumn() {
    setTimeout(() => {
      if (!this.customTableComponent) {
        this.adjustColumn();
      } else {
        setTimeout(() => {
          this.customTableComponent.reAdjustColumnsInTable();
        }, 500)
      }
    }, 500);
  }

  changeProductStatus(event: any, product: Product) {
    if (product.isActive) {
      event.preventDefault();
      event.stopPropagation();
      const modelRef: BsModalRef = this.modelService.show(CustomModalComponent, {
        keyboard: true,
        animated: true,
        ignoreBackdropClick: false,
        class: 'modal-md modal-dialog-centered custom-model'
      });
      modelRef.content.headerTitle = this.translateService.instant('deactivate_product_title');
      modelRef.content.title = this.translateService.instant('deactivate_product');
      modelRef.content.headerSubTitle = this.translateService.instant('deactivate_product_sub_title');
      modelRef.content.confirmText = this.translateService.instant('deactivate_product');
      modelRef.content.customClass = 'big-form-details';
      modelRef.content.closeEvent.subscribe((data: any) => {
        if (data) {
          this.activeDeActiveProduct(product)
        }
      })
    } else {
      this.activeDeActiveProduct(product);
    }
  }

  activeDeActiveProduct(product: Product) {
    this.isEditAction = true;
    const body: any = {
      id: product.id || product._id || '',
      isActive: !product.isActive
    }
    this.vendorStore.dispatch(UpdateVendorProduct({product: body}));
  }

  showActions(template: any) {
    this.modalRef = this.modelService.show(template, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered action-model'
    })
  }

  deleteProduct(product: Product) {
    const modelRef: BsModalRef = this.modelService.show(CustomModalComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model'
    });
    modelRef.content.title = this.translateService.instant('remove_product');
    modelRef.content.headerTitle = this.translateService.instant('remove_product_title');
    modelRef.content.headerSubTitle = this.translateService.instant('remove_product_sub_title');
    modelRef.content.confirmText = this.translateService.instant('remove_product');
    modelRef.content.customClass = 'big-form-details';
    modelRef.content.closeEvent.subscribe((data: any) => {
      if (data) {
        this.isDeleteAction = true;
        this.vendorStore.dispatch(DeleteVendorProduct({id: product.id}));
      }
    })
  }

  submitForApproval() {
    const body: any = {
      id: this.productId,
      rejectStatus: "PENDING"
    }
    this.vendorStore.dispatch(UpdateVendorProduct({product: body}));
  }

  redirectToPrev() {
    history.back()
  }

  getImage(item: any, size: any) {
    return this.uploadImage.getImages(item.coverImage, size);
  }

  getExtraImage(image: any, size: any) {
    return this.uploadImage.getImages(image, size);
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
