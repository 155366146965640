import {Component, Inject, Input, OnInit} from '@angular/core';
import {APP_CONFIG} from "@kwot/app-config";
import {User} from "@kwot/data-models";

@Component({
  selector: 'kwot-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() currentUser: User;

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
  ) {
  }

  ngOnInit(): void {
  }

}
