import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
// import Swiper core and required modules
import SwiperCore, {Navigation, Pagination} from "swiper";
import {APP_CONFIG} from "@kwot/app-config";
import {Router} from "@angular/router";
import {UploadImageService} from "../../services/upload-image.service";

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'kwot-card-two',
  templateUrl: './card-two.component.html',
  styleUrls: ['./card-two.component.scss']
})
export class CardTwoComponent implements OnInit {

  @Input() product?: any;
  @Output() click = new EventEmitter()

  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
    public router: Router,
    private uploadImage: UploadImageService,
  ) {
  }

  ngOnInit(): void {
  }

  onSwiper(swiper: any) {
  }

  onSlideChange() {
  }

  getImage(item: any, size: any) {
    return this.uploadImage.getImages(item.coverImage, size);
  }

  getExtraImage(image: any, size: any) {
    return this.uploadImage.getImages(image, size);
  }

  routeClick(id: any) {
    if (this.appConfig.type === 'admin') {
      return;
    }
    this.click.emit(true)
    this.router.navigate(['/', this.appConfig.type, 'product', id])
  }
}
