import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'kwot-payment-policy',
  templateUrl: './payment-policy.component.html',
  styleUrls: ['./payment-policy.component.scss']
})
export class PaymentPolicyComponent implements OnInit {

  activeTab: boolean = false;

  constructor(
    public bsModalRef: BsModalRef,
  ) {
  }

  ngOnInit(): void {
  }

}
