import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {DropdownTreeviewComponent, TreeviewConfig, TreeviewHelper, TreeviewI18n, TreeviewItem} from "ngx-treeview";
import {DropdownTreeviewSelectI18n} from './selectedValue';

@Component({
  selector: 'kwot-category-tree-select',
  templateUrl: './category-tree-select.component.html',
  styleUrls: ['./category-tree-select.component.scss'],
  providers: [
    {provide: TreeviewI18n, useClass: DropdownTreeviewSelectI18n}
  ]
})
export class CategoryTreeSelectComponent implements OnInit, OnChanges {

  config: TreeviewConfig;
  @Input() items: any[] | TreeviewItem[];
  @Input() originalItems: any[];
  @Input() isDisabled: boolean = false;
  @Input() displayWithParent: boolean = false;
  @Input() value: any;
  @Input() showImage: boolean = false;
  @Output() valueChange = new EventEmitter<any>();
  @ViewChild(DropdownTreeviewComponent, {static: false}) dropdownTreeviewComponent: DropdownTreeviewComponent;
  filterText: string;
  private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;

  constructor(
    public i18n: TreeviewI18n
  ) {
    this.dropdownTreeviewSelectI18n = i18n as DropdownTreeviewSelectI18n;
  }

  ngOnInit(): void {
    this.config = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasCollapseExpand: false,
      hasFilter: true,
      maxHeight: 500
    });
  }

  ngOnChanges(): void {
    this.updateSelectedItem();
  }

  select(item: TreeviewItem): void {
    if (!item.disabled) {
      this.selectItem(item);
    }
  }

  private updateSelectedItem(): void {
    if (this.items && this.value) {
      const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
      this.selectItem(selectedItem);
    }
  }

  getImage(value: string) {
    const category = this.originalItems.find((t: any) => t._id === value);
    if (category) {
      return category.icon
    }
  }

  private selectItem(item: TreeviewItem): void {
    if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
      this.dropdownTreeviewSelectI18n.selectedOptions = {
        displayWithParent: this.displayWithParent,
        originalItems: this.originalItems
      };
      this.dropdownTreeviewSelectI18n.selectedItem = item;
      if (this.dropdownTreeviewComponent) {
        this.dropdownTreeviewComponent.onSelectedChange([item]);
      }

      if (item) {
        if (this.dropdownTreeviewComponent) {
          document.body.click();
        }
        if (this.value !== item.value) {
          this.value = item.value;
          this.valueChange.emit(item.value);
        }
      }
    }
  }
}
