import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {map} from 'rxjs';
import {AuthState, getLoggedInUser} from "@kwot/auth";
import {select, Store} from "@ngrx/store";
import {LocalstorageService} from "@kwot/app-config";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private localStorageService: LocalstorageService
  ) {
  }

  canActivate() {
    return this.authStore.pipe(select(getLoggedInUser)).pipe(
      map(currentUser => {
        if (!currentUser) {
          this.router.navigate(['/vendor', 'login']);
          return false;
        }
        if (currentUser.email && !currentUser.isEmailVerified) {
          this.localStorageService.updateCommonKey('store', {redirect: false});
          this.router.navigate([`/vendor`, 'email-verification']);
          return false;
        }
        if (currentUser.phone && !currentUser.isPhoneVerified && !currentUser.isEmailVerified) {
          this.localStorageService.updateCommonKey('store', {redirect: false});
          this.localStorageService.updateRegisterNumber('store', currentUser.phone || '');
          this.localStorageService.updateCountryCode('store', currentUser.phoneCountry || '');
          this.localStorageService.updateRegisterUser('store', currentUser);
          this.router.navigate(['/', 'vendor', 'validate-otp'])
          return false;
        }
        return true;
      })
    )
  }

}
