import {Action, createReducer, on} from "@ngrx/store";
import {
  CheckStoreNameAvailability,
  CheckStoreNameAvailabilityError,
  CheckStoreNameAvailabilitySuccess,
  DeleteVendorProduct,
  DeleteVendorProductError,
  DeleteVendorProductSuccess,
  GetCategories,
  GetCategoriesError,
  GetCategoriesSuccess, GetMyReviews, GetMyReviewsError, GetMyReviewsSuccess,
  GetProductReviews, GetProductReviewsError,
  GetProductReviewsSuccess,
  GetVendorAllProducts,
  GetVendorAllProductsError,
  GetVendorAllProductsSuccess,
  GetVendorBankDetails,
  GetVendorBankDetailsError,
  GetVendorBankDetailsSuccess,
  GetVendorBusinessDetails,
  GetVendorBusinessDetailsError,
  GetVendorBusinessDetailsSuccess,
  GetVendorDetails,
  GetVendorDetailsError,
  GetVendorDetailsSuccess,
  GetVendorProduct,
  GetVendorProductError,
  GetVendorProductSuccess, GetWalletBalance, GetWalletBalanceError, GetWalletBalanceSuccess,
  ResendProfileVerification,
  ResendProfileVerificationError,
  ResendProfileVerificationSuccess,
  ResetVendorState,
  StoreSetupStorePreference,
  StoreSetupStorePreferenceError,
  StoreSetupStorePreferenceSuccess,
  StoreSetupStoreProfile,
  StoreSetupStoreProfileError,
  StoreSetupStoreProfileSuccess,
  UpdateVendorProduct,
  UpdateVendorProductError,
  UpdateVendorProductSuccess,
  VendorDashboard,
  VendorDashboardError,
  VendorDashboardSuccess,
  VendorOrderManage,
  VendorOrderManageError,
  VendorOrderManageSuccess,
  VendorOrdersList,
  VendorOrdersListError,
  VendorOrdersListSuccess,
  GetWalletTransaction,
  GetWalletTransactionSuccess,
  GetWalletTransactionError,
  AddScheduleData,
  AddScheduleDataSuccess,
  AddScheduleDataError,
  AddPayoutData,
  AddPayoutDataSuccess,
  AddPayoutDataError,
  GetPayoutDetailError,
  GetPayoutDetailSuccess, GetPayoutDetail, GetPolicyDetail, GetPolicyDetailError, GetPolicyDetailSuccess,
  AcceptPolicyDetail, AcceptPolicyDetailSuccess, AcceptPolicyDetailError
} from "./vendor.actions";
import {Categories, Order, PaginatedOrders, PaginatedProducts, Product, Store, WalletDetails, PayoutDetails} from "@kwot/data-models";

export interface VendorState {
  success: string;
  error: string;
  categories: Categories[],
  vendor: Store,
  isStoreNameAvailable: string,
  products: PaginatedProducts,
  product: Product
  orders: PaginatedOrders,
  dashboard: any,
  orderInfo: Order | null;
  reviews: any,
  wallet: WalletDetails | null,
  walletTransaction: Store | null,
  payout: PayoutDetails[],
  policy: any,
}

const initVendorState: VendorState = {
  success: '', error: '', categories: [], vendor: null,
  isStoreNameAvailable: '', products: null, product: null,
  orders: null, dashboard: null, orderInfo: null,
  reviews: null , wallet: null, walletTransaction: null, payout: [], policy: null
}

const vendorReducer = createReducer(initVendorState,
  on(StoreSetupStorePreference, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(StoreSetupStorePreferenceSuccess, (state, {updatedVendor}) => ({
    ...state,
    vendor: {
      ...state.vendor,
      ...updatedVendor
    },
    error: '',
    success: ''
  })),
  on(StoreSetupStorePreferenceError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),
  on(GetCategories, (state) => ({
    ...state,
    categories: [],
    error: '',
    success: ''
  })),
  on(GetCategoriesSuccess, (state, {categories}) => ({
    ...state,
    categories,
    error: '',
    success: ''
  })),
  on(GetCategoriesError, (state, {error}) => ({
    ...state,
    categories: [],
    error,
    success: ''
  })),
  on(GetVendorDetails, (state) => ({
    ...state,
    vendor: null,
    error: '',
    success: ''
  })),
  on(GetVendorDetailsSuccess, (state, {vendor}) => ({
    ...state,
    vendor,
    error: '',
    success: ''
  })),
  on(GetVendorDetailsError, (state, {error}) => ({
    ...state,
    vendor: null,
    error,
    success: ''
  })),
  on(CheckStoreNameAvailability, (state) => ({
    ...state,
    isStoreNameAvailable: '',
    error: '',
    success: ''
  })),
  on(CheckStoreNameAvailabilitySuccess, (state, {isStoreNameAvailable}) => ({
    ...state,
    isStoreNameAvailable,
    error: '',
    success: ''
  })),
  on(CheckStoreNameAvailabilityError, (state, {error, isStoreNameAvailable}) => ({
    ...state,
    isStoreNameAvailable,
    error,
    success: ''
  })),

  on(StoreSetupStoreProfile, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(StoreSetupStoreProfileSuccess, (state, {updatedVendor}) => ({
    ...state,
    vendor: {
      ...state.vendor,
      ...updatedVendor
    },
    error: '',
    success: ''
  })),
  on(StoreSetupStoreProfileError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetVendorBusinessDetails, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(GetVendorBusinessDetailsSuccess, (state, {updatedVendor}) => ({
    ...state,
    vendor: {
      ...state.vendor,
      ...updatedVendor
    },
    error: '',
    success: ''
  })),
  on(GetVendorBusinessDetailsError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetVendorBankDetails, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(GetVendorBankDetailsSuccess, (state, {updatedVendor}) => ({
    ...state,
    vendor: {
      ...state.vendor,
      ...updatedVendor
    },
    error: '',
    success: ''
  })),
  on(GetVendorBankDetailsError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetVendorAllProducts, (state) => ({
    ...state,
    products: null,
    error: '',
    success: ''
  })),
  on(GetVendorAllProductsSuccess, (state, {products}) => ({
    ...state,
    products,
    error: '',
    success: ''
  })),
  on(GetVendorAllProductsError, (state, {error}) => ({
    ...state,
    products: null,
    error,
    success: ''
  })),

  on(DeleteVendorProduct, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(DeleteVendorProductSuccess, (state, {id}) => {
    let products: any = [];
    let count: any = {
      page: 0,
      total: 0
    };
    if (state.products) {
      let productIndex = state.products.data.findIndex(c => (c.id === id || c._id === id));
      products = [...state.products.data];
      count = {...state.products.count};
      if (productIndex !== -1) {
        products.splice(productIndex, 1)
        count.total = count.total - 1;
      }
    }
    return {
      ...state,
      products: {
        data: products,
        count: count
      },
      error: '',
      success: '---IGNORE---'
    }
  }),
  on(DeleteVendorProductError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetVendorProduct, (state) => ({
    ...state,
    product: null,
    error: '',
    success: ''
  })),
  on(GetVendorProductSuccess, (state, {product}) => ({
    ...state,
    product,
    error: '',
    success: ''
  })),
  on(GetVendorProductError, (state, {error}) => ({
    ...state,
    product: null,
    error,
    success: ''
  })),

  on(ResendProfileVerification, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(ResendProfileVerificationSuccess, (state) => ({
    ...state,
    error: '',
    success: '---IGNORE---'
  })),
  on(ResendProfileVerificationError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(UpdateVendorProduct, (state) => ({
    ...state,
    product: null,
    error: '',
    success: ''
  })),
  on(UpdateVendorProductSuccess, (state, {product}) => ({
    ...state,
    product,
    error: '',
    success: ''
  })),
  on(UpdateVendorProductError, (state, {error}) => ({
    ...state,
    product: null,
    error,
    success: ''
  })),

  on(VendorOrdersList, (state) => ({
    ...state,
    orders: null,
    error: '',
    success: ''
  })),
  on(VendorOrdersListSuccess, (state, {orders}) => ({
    ...state,
    orders,
    error: '',
    success: ''
  })),
  on(VendorOrdersListError, (state, {error}) => ({
    ...state,
    orders: null,
    error,
    success: ''
  })),

  on(VendorDashboard, (state) => ({
    ...state,
    dashboard: null,
    error: '',
    success: ''
  })),
  on(VendorDashboardSuccess, (state, {dashboard}) => ({
    ...state,
    dashboard,
    error: '',
    success: ''
  })),
  on(VendorDashboardError, (state, {error}) => ({
    ...state,
    dashboard: null,
    error,
    success: ''
  })),

  on(VendorOrderManage, (state) => ({...state, orderInfo: null, error: '', success: ''})),
  on(VendorOrderManageSuccess, (state, {orderInfo}) => ({...state, orderInfo, error: '', success: ''})),
  on(VendorOrderManageError, (state, {error}) => ({...state, orderInfo: null, error, success: ''})),

  on(GetProductReviews, (state) => ({...state, reviews: null, error: '', success: ''})),
  on(GetProductReviewsSuccess, (state, {reviews}) => ({...state, reviews, error: '', success: ''})),
  on(GetProductReviewsError, (state, {error}) => ({...state, reviews: null, error, success: ''})),

  on(GetMyReviews, (state) => ({...state, reviews: null, error: '', success: ''})),
  on(GetMyReviewsSuccess, (state, {reviews}) => ({...state, reviews, error: '', success: ''})),
  on(GetMyReviewsError, (state, {error}) => ({...state, reviews: null, error, success: ''})),

  on(GetWalletBalance, (state) => ({
    ...state,
    wallet: null,
    error: '',
    success: ''
  })),
  on(GetWalletBalanceSuccess, (state, {wallet}) => ({
    ...state,
    wallet,
    error: '',
    success: ''
  })),
  on(GetWalletBalanceError, (state, {error}) => ({
    ...state,
    vendor: null,
    error,
    success: ''
  })),



  on(GetWalletTransaction, (state) => ({
    ...state,
    walletTransaction: null,
    error: '',
    success: ''
  })),
  on(GetWalletTransactionSuccess, (state, {walletTransaction}) => ({
    ...state,
    walletTransaction,
    error: '',
    success: ''
  })),
  on(GetWalletTransactionError, (state, {error}) => ({
    ...state,
    vendor: null,
    error,
    success: ''
  })),

  on(AddScheduleData, (state) => ({
    ...state,
    wallet: null,
    error: '',
    success: ''
  })),
  on(AddScheduleDataSuccess, (state, {wallet}) => ({
    ...state,
    wallet,
    error: '',
    success: ''
  })),
  on(AddScheduleDataError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(AddPayoutData, (state) => ({
    ...state,
    payout: null,
    error: '',
    success: ''
  })),
  on(AddPayoutDataSuccess, (state, {payout}) => ({
    ...state,
    payout,
    error: '',
    success: ''
  })),
  on(AddPayoutDataError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetPayoutDetail, (state) => ({
    ...state,
    payout: null,
    error: '',
    success: ''
  })),
  on(GetPayoutDetailSuccess, (state, {payout}) => ({
    ...state,
    payout,
    error: '',
    success: ''
  })),
  on(GetPayoutDetailError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(GetPolicyDetail, (state) => ({
    ...state,
    policy: null,
    error: '',
    success: ''
  })),
  on(GetPolicyDetailSuccess, (state, {policy}) => ({
    ...state,
    policy,
    error: '',
    success: ''
  })),
  on(GetPolicyDetailError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(AcceptPolicyDetail, (state) => ({
    ...state,
    policy: null,
    error: '',
    success: ''
  })),
  on(AcceptPolicyDetailSuccess, (state) => ({
    ...state,
    error: '',
    success: ''
  })),
  on(AcceptPolicyDetailError, (state, {error}) => ({
    ...state,
    error,
    success: ''
  })),

  on(ResetVendorState, (state, {params}) => ({
    ...state,
    ...params
  }))
)

export function reducer(state = initVendorState, action: Action) {
  return vendorReducer(state, action);
}
