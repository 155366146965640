<div class="size-guid m-5">
  <span (click)="onNoClick()" class="close cursor-pointer">
    <img src="assets/images/icons/Cross.svg" alt="" />
  </span>
  <h6 class="text-center fs-16 font-weight-bold brand-black mb-4">
    {{'size_guide' | translate}}
  </h6>
  <div class="cm-in text-center">
    <!-- <mat-slide-toggle class="fs-16 font-weight-medium"><span class="pl-c active">cm</span><span>in</span></mat-slide-toggle> -->
    <div class="cm-in pb-4 text-center">
      {{ 'size_masurement.cm' | translate }}
      <p class="mb-0 ms-1 fs-16 store-content-details">
        <label class="action-switch-green-btn me-2 m-0">
          <input type="checkbox" />{{ 'size_masurement.in' | translate }}
          <span class="slider round"></span>
        </label>
      </p>
      {{ 'size_masurement.in' | translate }}
    </div>
  </div>
  <div class="table-responsive table-kwot">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">{{ 'size' | translate }}</th>
          <th scope="col">{{ 'chest' | translate }}</th>
          <th scope="col">{{ 'waist' | translate }}</th>
          <th scope="col">{{ 'seat' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ 'size_masurement.xs' | translate }}</td>
          <td>{{ '82' | translate }}</td>
          <td>{{ '62' | translate }}</td>
          <td>{{ '90' | translate }}</td>
        </tr>
        <tr>
          <td class="bg-white p-1" colspan="4"></td>
        </tr>
        <tr>
          <td>{{ 'size_masurement.s' | translate }}</td>
          <td>{{ '82' | translate }}</td>
          <td>{{ '62' | translate }}</td>
          <td>{{ '90' | translate }}</td>
        </tr>
        <tr>
          <td class="bg-white p-1" colspan="4"></td>
        </tr>
        <tr>
          <td>{{ 'size_masurement.m' | translate }}</td>
          <td>{{ '82' | translate }}</td>
          <td>{{ '62' | translate }}</td>
          <td>{{ '90' | translate }}</td>
        </tr>
        <tr>
          <td class="bg-white p-1" colspan="4"></td>
        </tr>
        <tr>
          <td>{{ 'size_masurement.l' | translate }}</td>
          <td>{{ '82' | translate }}</td>
          <td>{{ '62' | translate }}</td>
          <td>{{ '90' | translate }}</td>
        </tr>
        <tr>
          <td class="bg-white p-1" colspan="4"></td>
        </tr>
        <tr>
          <td>{{ 'size_masurement.xl' | translate }}</td>
          <td>{{ '82' | translate }}</td>
          <td>{{ '62' | translate }}</td>
          <td>{{ '90' | translate }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #ProductReviewsTemplate>
  <div class="pp-popup">
    <span (click)="onNoClick()" class="close cursor-pointer">
      <img src="assets/images/icons/Cross.svg" alt="" />
    </span>
    <div>
      <h6 class="text-center fs-16 font-weight-bold brand-black mb-4">
        {{'rate_product' | translate}}
      </h6>
      <h2 class="fs-24 mb-1 font-weight-bold brand-black">
        {{'rate_review_product' | translate}}
      </h2>
      <p class="fs-16 font-weight-bold neutral_40">{{'Product Name goes here' | translate }}</p>

      <ul
        class="rating-star py-3 d-flex justify-content-center list-unstyled d-flex"
      >
        <li>
          <img width="14.4" src="assets/images/icons/star-icon.svg" alt="" />
        </li>
        <li>
          <img src="assets/images/icons/star-border-icon.svg" alt="" />
        </li>
        <li>
          <img src="assets/images/icons/star-border-icon.svg" alt="" />
        </li>
        <li>
          <img src="assets/images/icons/star-border-icon.svg" alt="" />
        </li>
      </ul>
      <div class="input-textaera mb-4">
        <h6 class="fs-12 neutral_60 font-weight-bold mb-1">{{ 'reviews' | translate}}</h6>
        <textarea
          name=""
          id=""
          cols="30"
          rows="7"
          placeholder="Type here your opinion about the product"
        ></textarea>
      </div>
      <div class="upload-pic">
        <h5 class="mb-3 fs-16 font-weight-medium">
          {{'upload_pic' | translate}}
        </h5>
        <ul class="d-flex list-unstyled p-0 m-0 flex-wrap">
          <li>
            <div class="photo-up position-relative">
              <img class="img-c" src="assets/images/Photo.jpg" alt="" />
              <span class="delete cursor-pointer">
                <img
                  width="32px"
                  src="assets/images/icons/Delete-bg-white.svg"
                  alt=""
                />
              </span>
            </div>
          </li>
          <li>
            <img
              class="cursor-pointer"
              src="assets/images/icons/Photo.svg"
              alt=""
            />
          </li>
        </ul>
        <button class="mt-4 btn btn-action">{{'post' | translate}}</button>
      </div>
    </div>
  </div>
</ng-template>
