<div class="modal-body">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="d-flex align-items-center justify-content-end mb-3">
          <span id="close" *ngIf="activeTab">
            <img class="close-icon cursor-pointer" src="assets/images/icons/Cross.svg" alt=""
                 (click)="bsModalRef.hide()">
          </span>
        </div>
        <div class="back-btn d-flex align-items-center" *ngIf="!activeTab" [routerLink]="['/vendor', 'finances']">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer"/>
          <p class="title-back mb-0 fs-16 cursor-pointer">{{'back' | translate}}</p>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab">{{'THE KWOT PAYMENT POLICY' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="privacy" class="">
            <p>This aspect of the business is concerned with how we intend to handle the payment process of each
              transaction that goes through our platform. </p>
            <p>This policy will be made accessible to Vendors and KWOT management team for reference purposes and
              clarity as the case may be.</p>
            <p>KWOT is partnering with both a local bank (STANBIC IBTC) and International Payment Solutions platforms
              (Paystack) for now, to ease the payment and funds transfer process for Vendors and ease tracking for KWOT
              admin.</p>
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab">{{'VENDOR BANK ACCOUNT DETAILS/ REGISTRATION FOR KWOT' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="bank" class="">
            <p>KWOT has decided to use STANBIC IBTC as its Nigerian Settlement bank for each transaction completed with
              KWOT. As such, the following are key for each vendor upon sign-uowith KWOT:</p>
            <ol>
              <li> All vendors are advised to register with their Stanbic IBTC Account to receive payment</li>
              <li> All vendors to ensure they onlyregister with their active accounts to receive funds.</li>
              <li> Vendors are advised that the settlement date for a StanbicIBTC toStanbic IBTCaccount is T+
                1(Transaction date when amount was received from KWOT+ 1 day), while for other banks it is T
                +2(Transaction date when amount was received from KWOT+ 2days)or can be longer in peculiar cases.
              </li>
            </ol>
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab">{{'THE END-TO-END VENDOR PAYMENT PROCESSING CHAIN' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="vendorPayment" class="">
            <p>At the end of each successfulpayment by the Buyer/ Once the funds have beenreceived in the KWOTReceiving
              Bank Account, the following chain is followed:</p>
            <img src="https://s3.amazonaws.com/kwot.files/payment-policy.png" class="img-fluid vendorPayment-img"
                 alt="">
            <p><b>NOTE:</b>This is the typical processing time and may slightly vary in peculiar cases</p>
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab">{{'HANDLING REFUND REQUESTS' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="refund" class="">
            <p>When the BUYER has a refund request, the below chain is followed by VENDORS</p>
            <img src="https://s3.amazonaws.com/kwot.files/paymeny-policy-1.png" class="img-fluid refund-img" alt="">
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab">{{'EXPLANATIONON REFUND REQUESTS HANDLED BY SELLER' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="explanation" class="">
            <p>1) For each refund request raised by a BUYER, the SELLER is to carry out his due diligence to ensure the
              claims for refund are genuine based on the KWOT RETURN POLICY. This presents 2 cases.</p>
            <p>2) In the case that after due diligence, the customer’s claims are wrong,the CUSTOMER is notified that NO
              REFUND will be processed.</p>
            <p>3) In the casetheCustomer’s claims are true with substantial evidence , Seller is to present 2 options to
              the CUSTOMER: To get a complete refund or to exchange for a similar item in good condition.</p>
            <p>4) Where the client opts for an immediate refund, the Seller forfeits the money for that transaction.</p>
            <p>5) Where the Customer opts for an exchange, the Seller’s funds are still unconfirmed until the Customer
              accepts the item given in exchange as being in good condition.</p>
          </div>
        </div>
        <div class="tabs">
          <ul class="nav nav-pills">
            <li class="cursor-pointer">
              <h1 data-toggle="tab">{{'EXPLANATION ON REFUND REQUESTS HANDLED BY SELLER' | translate}}</h1>
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div id="payment" class="">
            <p>While KWOT has partnered with well experience payment solution partners, there could be occasional
              challenges faced. The following points are recommended:</p>
            <p>1) All bank details should be carefully and properlytyped in.</p>
            <p>2) For ease of settlement, the Vendors are advised to open an account with our Local Settlement Bank
              Partner.</p>
            <p>3) Vendor can cross-check their available balance in their wallets after each transaction holding period
              (2 days). For any discrepancies,it shouldbe escalated to KWOT admin/ helpdesk.</p>
            <p>4) For cases that involve a refund, the seller should immediately take ownership of the transaction and
              see to it that it is solved, or a refund made.</p>
            <p>5) In any case that involves an issue with the payment solution providers, the KWOT admin should be
              immediately contacted for a solution to be worked out.</p>
            <p>6) In no case should a vendor attempt to by-pass the usual money transfer process set by KWOT.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

