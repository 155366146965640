<div class="products-list">
  <div class="products-list-title">
    <div class="row align-items-center">
      <div class="col-lg-12 pe-4">
        <div class="back d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img src="/assets/images/icons/back.svg" class="cursor-pointer" [routerLink]="['/vendor', 'dashboard']"/>
            <p class="title-back mb-0 fs-16 cursor-pointer"
               [routerLink]="['/vendor', 'dashboard']">{{'back' | translate}}</p>
          </div>
          <button class="btn-action" [routerLink]="['add-product']"
                  *ngIf="productsList.length > 0 || productsFoundFirstTime">
            <i class="fa fa-plus"></i>{{'add_product' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="products-list-wrapper">
    <div [ngClass]="{'d-none': productsList.length <= 0 && !productsFoundFirstTime}">
      <h2 class="fs-32 fw-bold">{{'products_no_bracket' | translate}}</h2>
      <div class="custom-table">
        <div class="tag-filters">
        <span class="tag" [ngClass]="{'active': selectedFilter === filter.value}"
              (click)="filterProduct(filter.value)"
              *ngFor="let filter of filters">{{filter.label}}</span>
        </div>
        <div [ngClass]="{'d-none': productsList.length <= 0}">
          <kwot-custom-table
            #customTableComponent
            [customOptions]="customDatatableSettings"
            [ajaxFunction]="getAllProducts"
            [tableHead]="tableHead" [tableBody]="tableBody">
          </kwot-custom-table>
          <ng-template #tableHead>
            <thead *ngIf="!isMobile">
            <tr>
              <td>
                <p
                  class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                  {{'product' | translate}}
                </p>
              </td>
              <td>
                <p
                  class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                  {{'status' | translate}}
                </p>
              </td>
              <td>
                <p
                  class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                  {{'category' | translate}}
                </p>
              </td>
              <td>
                <p
                  class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                  {{'price' | translate}}
                </p>
              </td>
              <td>
                <p
                  class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                  {{'stock' | translate}}
                </p>
              </td>
              <td></td>
            </tr>
            </thead>
          </ng-template>
          <ng-template #tableBody>
            <tbody *ngIf="productsList.length <= 0">
            <tr>
              <td colspan="6">{{'no_data_found' | translate}}</td>
            </tr>
            </tbody>
            <tbody *ngIf="productsList.length > 0 && !isMobile">
            <tr *ngFor="let product of productsList" [ngClass]="{'in-active': !product.isActive}">
              <td>
                <p class="mb-0 fs-16 cursor-pointer store-content-details" [routerLink]="['view-product', product._id]">
                  <img [lazyLoad]="getImage(product, 'small')"
                       [defaultImage]="'/assets/images/Photo.jpg'" class="search-icon me-2" alt="">
                  {{product.productName}}
                </p>
              </td>
              <td>
                <p class="mb-0 fs-16 store-content-details">
                <span class="status {{(product.isActive ? 'active': 'inactive') | slugify}}">
                  <span class="badge"></span>
                  <ng-container *ngIf="product.isActive">{{('active' | translate)}}</ng-container>
                  <ng-container *ngIf="!product.isActive">
                    <ng-container *ngIf="product.rejectStatus === 'REJECTED'">
                      Rejected
                    </ng-container>
                    <ng-container *ngIf="product.rejectStatus === 'PENDING'">
                      Awaiting Approval
                    </ng-container>
                    <ng-container *ngIf="!product.rejectStatus">
                      {{'inactive' | translate}}
                    </ng-container>
                  </ng-container>
                </span>
                </p>
              </td>
              <td>
                <p class="mb-0 fs-16 store-content-details">
                <span class="category">
                  {{product.category?.name}}
                </span>
                </p>
              </td>
              <td>
                <p class="mb-0 fs-16 store-content-details">
                  {{product.price | currencyConvert | async  }}
                </p>
              </td>
              <td>
                <p class="mb-0 fs-16 store-content-details">
                  {{product.qtyAvailable}} {{'in_stock' | translate}}
                </p>
              </td>
              <td class="action">
                <button type="button" class="btn-icon float-end" placement="bottom right"
                        containerClass="custom-popover no-arrow"
                        [outsideClick]="true" container="body" [adaptivePosition]="false"
                        [popover]="popTemplate">
                  <i class="fas fa-ellipsis-v"></i>
                </button>
                <ng-template #popTemplate>
                  <div class="item" [routerLink]="['view-product', product._id]">
                    <img src="/assets/images/icons/edit-eye.svg" class="icon"/>
                    <span>{{(!product.isB2B ? 'view_on_kwot': 'view_on_kwotB2b') | translate}}</span>
                  </div>
                  <div class="item" [routerLink]="['edit-product', product._id]">
                    <img src="/assets/images/icons/edit-white.svg" class="icon"/>
                    <span>{{'edit' | translate}}</span>
                  </div>
                  <div class="item" [routerLink]="['copy-product', product._id]">
                    <img src="/assets/images/icons/copy-icon-white.svg" class="icon"/>
                    <span>{{'duplicate' | translate}}</span>
                  </div>
                  <div class="item justify-content-between pe-0">
                    <span>{{'active' | translate}}</span>
                    <div class="text-start d-flex align-items-center">
                      <label class="action-switch-green-btn me-2 m-0" (click)="changeProductStatus($event, product)">
                        <input type="checkbox" [checked]="product.isActive">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                  <div class="item justify-content-center border-bottom-0 pb-2" (click)="deleteProduct(product)">
                    <img src="/assets/images/icons/Delete-icon.svg" class="remove-icon icon"/>
                    <p class="mb-0">{{'remove_product' | translate}}</p>
                  </div>
                </ng-template>
              </td>
            </tr>
            </tbody>
            <tbody *ngIf="productsList.length > 0 && isMobile">
            <tr class="mobile-product-list">
              <td colspan="6">
                <div class="products-list-mobile" *ngFor="let product of productsList"
                     [ngClass]="{'in-active': !product.isActive}">
                  <div class="img-wrapper">
                    <img [lazyLoad]="product.coverImage"
                         [defaultImage]="'/assets/images/Photo.jpg'" class="search-icon me-2" alt="">
                  </div>
                  <div class="products-list-details text-start">
                    <div class="details">
                      <h6 class="fs-14 ">
                        {{product.productName}}
                      </h6>
                      <h5 class="fs-14 fw-bold ">
                        <p class="mb-0 fs-16 store-content-details">
                          {{product.price | currencyConvert | async}}
                        </p>
                      </h5>
                      <div class="status-details d-flex align-items-center">
                        <div class="active-button pe-3">
                          <p class="mb-0 fs-16 store-content-details">
                              <span class="status {{(product.isActive ? 'active': 'inactive') | slugify}}">
                                <span class="badge"></span>
                                <ng-container *ngIf="product.isActive">{{('active' | translate)}}</ng-container>
                                <ng-container *ngIf="!product.isActive">
                                  <ng-container *ngIf="product.rejectStatus === 'REJECTED'">
                                    Rejected
                                  </ng-container>
                                  <ng-container *ngIf="product.rejectStatus === 'PENDING'">
                                    Awaiting Approval
                                  </ng-container>
                                  <ng-container *ngIf="!product.rejectStatus">
                                    {{'inactive' | translate}}
                                  </ng-container>
                                </ng-container>
                              </span>
                          </p>
                        </div>
                        <div class="stock-details">
                          <p class="mb-0 fs-16 store-content-details">
                            {{product.qtyAvailable}} {{'in_stock' | translate}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="action">
                      <button type="button" class="btn-icon float-end" placement="bottom right"
                              containerClass="custom-popover no-arrow"
                              [outsideClick]="true" container="body" [adaptivePosition]="false"
                              [popover]="popTemplate">
                        <i class="fas fa-ellipsis-v"></i>
                      </button>
                      <ng-template #popTemplate>
                        <div class="item" [routerLink]="['view-product', product._id]">
                          <img src="/assets/images/icons/edit-eye.svg" class="icon"/>
                          <span>{{(!product.isB2B ? 'view_on_kwot': 'view_on_kwotB2b') | translate}}</span>
                        </div>
                        <div class="item" [routerLink]="['edit-product', product._id]">
                          <img src="/assets/images/icons/edit-white.svg" class="icon"/>
                          <span>{{'edit' | translate}}</span>
                        </div>
                        <div class="item" [routerLink]="['copy-product', product._id]">
                          <img src="/assets/images/icons/copy-icon-white.svg" class="icon"/>
                          <span>{{'duplicate' | translate}}</span>
                        </div>
                        <div class="item justify-content-between pe-0">
                          <span>{{'active' | translate}}</span>
                          <div class="text-start d-flex align-items-center">
                            <label class="action-switch-green-btn me-2 m-0"
                                   (click)="changeProductStatus($event, product)">
                              <input type="checkbox" [checked]="product.isActive">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div class="item justify-content-center border-bottom-0 pb-2" (click)="deleteProduct(product)">
                          <img src="/assets/images/icons/Delete-icon.svg" class="remove-icon icon"/>
                          <p class="mb-0">{{'remove_product' | translate}}</p>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </ng-template>
        </div>
      </div>
    </div>
    <div [ngClass]="{'d-none': productsList.length > 0}">
      <div class="bank-not-verified d-none">
        <img src="/assets/images/icons/warning-icon.svg" alt="warning" class="img-fluid">
        <div class="info">
          <h4 class="fw-bold mb-0">{{'verify_bank' | translate}}</h4>
          <p class="subtitle">{{'you_can_start_after_bank_verified' | translate}}</p>
        </div>
        <div class="verify-account btn-transparent">{{'verify_account' | translate}}</div>
      </div>
      <div class="no-product">
        <img src="/assets/images/no-product-img.png" alt="No Products" class="img-fluid">
        <h2 class="text-center fw-bold mb-0" [innerHTML]="'here_you_see_products_of_store' | translate"></h2>
        <p class="subtitle text-center">{{'review_manage_product' | translate}}</p>
        <button class="btn-action" [routerLink]="['add-product']">{{'add_product' | translate}}</button>
      </div>
    </div>
  </div>
</div>
