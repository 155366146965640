import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Subject} from "rxjs";

@Component({
  selector: 'kwot-profile-rejected',
  templateUrl: './profile-rejected.component.html',
  styleUrls: ['./profile-rejected.component.scss']
})
export class ProfileRejectedComponent implements OnInit {

  blockedReason: string;
  blockedMessage: string;
  public closeEvent: Subject<any>;
  constructor(
    public bsModalRef: BsModalRef,
  ) {
  }

  ngOnInit(): void {
    this.closeEvent = new Subject();
  }

}
