import {UntypedFormBuilder, Validators} from '@angular/forms';
import {UntypedFormGroup} from '@angular/forms';
import {Component, Inject, OnInit} from '@angular/core';
import {APP_CONFIG, LocalstorageService} from '@kwot/app-config';
import {Title} from "@angular/platform-browser";
import {Store} from "@ngrx/store";
import {countryCodes, Store as StoreModel, User} from "@kwot/data-models";
import {PhoneNumberFormat, CountryISO} from "ngx-intl-tel-input";
import {WindowService} from "../../../../../shared/src/lib/services/window.service";
import {VendorBuyerState} from "../../+state/vendor-buyer.reducer";
import {addContactData} from "../../+state/vendor-buyer.actions";
import {ToastrService} from "ngx-toastr";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'kwot-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  currentUser: any;
  contactForm: UntypedFormGroup;
  isMobile: boolean;
  screenSize: number;
  contactUs: any[] = [
    {
      id: 1,
      name: 'Request/Suggestion'
    },
    {
      id: 2,
      name: 'Ask Question',
    },
    {
      id: 3,
      name: 'Raise Complaint'
    },
  ];
  profile: User | StoreModel | null;
  PhoneNumberFormat = PhoneNumberFormat;
  CountryISO = CountryISO;
  selectedPhoneCountry: any;
  reasonId: any;
  reason: any;

  constructor(
    private localStorageService: LocalstorageService,
    private formBuilder: UntypedFormBuilder,
    private titleService: Title,
    private windowService: WindowService,
    private vendorBuyerState: Store<VendorBuyerState>,
    @Inject(APP_CONFIG) public appConfig: any,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
  ) {
    this.windowService.onResize$.subscribe((data) => {
      this.isMobile = data.width <= 767;
    })
  }

  ngOnInit(): void {
    this.currentUser = this.localStorageService.updateUserKey('get');
    this.createForm();
    this.patchValues();
    this.screenSize = this.windowService.getScreenSize();
    this.isMobile = this.screenSize <= 767;
    if (this.appConfig.type === 'user') {
      this.titleService.setTitle('Kwot - Amazing Africa. Now, delivered at your doorstep');
    }
  }

  patchValues() {
    if (this.currentUser) {
      const countryCode = countryCodes.find((c: any) => c[this.currentUser.phoneCountry || null]);
      if (countryCode) {
        // @ts-ignore
        this.selectedPhoneCountry = countryCode[this.currentUser.phoneCountry];
      }
      this.contactForm.patchValue({
        partner_name: this.appConfig.type === 'vendor' ? this.currentUser.name : this.currentUser.firstName + ' ' + this.currentUser.lastName,
        partner_email: this.currentUser.email,
        phoneCountry: this.currentUser.phoneCountry,
        partner_phone: this.currentUser.phone,
      })
    }
  }

  createForm() {
    this.contactForm = this.formBuilder.group({
      partner_name: ['', Validators.required],
      partner_email: ['', [Validators.required, Validators.pattern(RegExp(/^[a-zA-z0-9._%+-]+@[a-zA-z0-9.-]+\.[a-zA-z]{2,4}$/))]],
      phoneCountry: [''],
      partner_phone: [''],
      priority: [null, Validators.required],
      description: ['', Validators.required],
    });
  }

  reasonData(id: any) {
    this.reasonId = id.value.toString()
  }

  get form() {
    return this.contactForm.controls;
  }

  onSubmitContact() {
    if (this.contactForm.invalid) {
      return;
    }
    this.vendorBuyerState.dispatch(addContactData({
      body: {
        isFeedback: false,
        partner_name: this.contactForm.value.partner_name ? this.contactForm.value.partner_name : '',
        name: this.contactForm.value.partner_name ? this.contactForm.value.partner_name : '',
        partner_email: this.contactForm.value.partner_email ? this.contactForm.value.partner_email : '',
        priority: this.reasonId,
        description: this.contactForm.value.description,
        partner_phone: this.contactForm.value.partner_phone?.number || '',
      }
    }))

    this.toastr.success(`Thank you for contacting with us . You will recieve a email soon on ${this.contactForm.value.partner_email}`);
    this.contactForm.reset();
    this.bsModalRef.hide();
  }
}
