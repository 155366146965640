import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {VendorState} from "../../+state/vendor.reducer";
import {CustomTableComponent} from "@kwot/admin-vendor-shared";
import {getProducts, getVendorProduct, getVendorSuccess} from "../../+state/vendor.selectors";
import {Subject, takeUntil} from "rxjs";
import {
  DeleteVendorProduct,
  GetVendorAllProducts,
  ResetVendorState,
  UpdateVendorProduct
} from "../../+state/vendor.actions";
import {Product} from "@kwot/data-models";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {CustomModalComponent} from "../../../../../shared/src/lib/components/custom-modal/custom-modal.component";
import {WindowService} from "../../../../../shared/src/lib/services/window.service";
import {UploadImageService} from "../../../../../shared/src/lib/services/upload-image.service";

@Component({
  selector: 'kwot-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit, OnDestroy {

  selectedFilter: string | number = '';
  filters: any[] = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'active',
      label: 'Active',
    },
    {
      value: 'inactive',
      label: 'Inactive',
    },
    {
      value: "soldOut",
      label: 'Sold out',
    },
    {
      value: "reject",
      label: 'Rejected',
    }
  ];
  customDatatableSettings: any = {};
  productsList: any[] = [];
  unsubscriber = new Subject();
  editId = '';
  allData: any;
  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;
  isDeleteAction = false;
  productsFoundFirstTime = false;
  isMobile: boolean;
  screenSize:number;

  constructor(
    private translateService: TranslateService,
    private vendorStore: Store<VendorState>,
    private toastr: ToastrService,
    private modelService: BsModalService,
    private windowService: WindowService,
    private uploadImage: UploadImageService,
  ) {
    this.vendorStore.dispatch(ResetVendorState({params: {error: '', success: '', products: null, product: null}}));
    this.subscribeToStore();
    this.windowService.onResize$.subscribe((data) => {
      this.isMobile = data.width <= 767;
    });
  }

  subscribeToStore() {
    this.vendorStore.pipe(select(getProducts))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(products => {
        if (products) {
          this.allData = products;
          this.productsList = [...products.data];
          if (this.productsList.length > 0 && !this.productsFoundFirstTime) {
            this.productsFoundFirstTime = true
          }
          if (this.customTableComponent.ajaxCallback) {
            this.customTableComponent.ajaxCallback({
              recordsTotal: products.count.total,
              recordsFiltered: products.count.total,
              data: []
            })

            setTimeout(() => {
              this.customTableComponent.reAdjustColumnsInTable();
            }, 500);
          }
        }
      })
    this.vendorStore.pipe(select(getVendorSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.isDeleteAction) {
            this.isDeleteAction = false;
            this.getAllProducts(undefined, undefined, undefined, undefined);
            this.toastr.warning(this.translateService.instant('product_removed'));
          }
        }
      })
    this.vendorStore.pipe(select(getVendorProduct))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(product => {
        if (product) {
          if (this.editId) {
            const productIndex = this.productsList.findIndex(item => (item.id === this.editId) || (item._id === this.editId));
            const updateProduct = {...this.productsList[productIndex]};
            updateProduct.isActive = !updateProduct.isActive;

            if (updateProduct.isActive) {
              this.toastr.success(this.translateService.instant('product_activated'));
            } else {
              this.toastr.warning(this.translateService.instant('product_deactivated'));
            }
            this.productsList[productIndex] = updateProduct;
            if (this.customTableComponent.ajaxCallback) {
              this.customTableComponent.ajaxCallback({
                recordsTotal: this.allData.count.total,
                recordsFiltered: this.allData.count.total,
                data: []
              })

              setTimeout(() => {
                this.customTableComponent.reAdjustColumnsInTable();
              }, 500);
            }
            this.editId = '';
          }
        }
      })
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      paging: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      searchPlaceholder: this.translateService.instant('search_by', {field: this.translateService.instant('products_no_bracket')}),
      orderColumns: ['productName', 'status', 'category', 'price', 'stock'],
      order: [[1, 'desc']]
    }
    this.screenSize = this.windowService.getScreenSize();
    this.isMobile = this.screenSize <= 767;
  }

  getAllProducts = (page = 0, perPage = 10, search = '', order = {
    filterBy: 'status',
    filterType: 'dec'
  }, criteria: string | number = '') => {
    this.vendorStore.dispatch(GetVendorAllProducts({
      params: {
        page, limit: perPage,
        search,
        ...(criteria ? {criteria} : {}),
        ...order
      }
    }))
  }

  changeProductStatus(event: any, product: Product) {
    if (product.isActive) {
      event.preventDefault();
      event.stopPropagation();
      const modelRef: BsModalRef = this.modelService.show(CustomModalComponent, {
        keyboard: true,
        animated: true,
        ignoreBackdropClick: false,
        class: 'modal-md modal-dialog-centered custom-model'
      });
      modelRef.content.headerTitle = this.translateService.instant('deactivate_product_title');
      modelRef.content.title = this.translateService.instant('deactivate_product');
      modelRef.content.headerSubTitle = this.translateService.instant('deactivate_product_sub_title');
      modelRef.content.confirmText = this.translateService.instant('deactivate_product');
      modelRef.content.customClass = 'big-form-details';
      modelRef.content.closeEvent.subscribe((data: any) => {
        if (data) {
          this.activeDeActiveProduct(product)
        }
      })
    } else {
      if (product.addressId) {
        this.activeDeActiveProduct(product);
      } else {
        event.preventDefault();
        event.stopPropagation();
        this.toastr.warning('Please complete the product details to make the product active');
      }
    }
  }

  activeDeActiveProduct(product: Product) {
    this.editId = product.id || product._id || '';
    const body: any = {
      id: product.id || product._id || '',
      isActive: !product.isActive
    }
    this.vendorStore.dispatch(UpdateVendorProduct({product: body}));
  }

  filterProduct(value: string | number) {
    this.selectedFilter = value
    this.getAllProducts(undefined, undefined, undefined, undefined, value);
  }

  deleteProduct(product: Product) {
    const modelRef: BsModalRef = this.modelService.show(CustomModalComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model'
    });
    modelRef.content.title = this.translateService.instant('remove_product');
    modelRef.content.headerTitle = this.translateService.instant('remove_product_title');
    modelRef.content.headerSubTitle = this.translateService.instant('remove_product_sub_title');
    modelRef.content.confirmText = this.translateService.instant('remove_product');
    modelRef.content.customClass = 'big-form-details';
    modelRef.content.closeEvent.subscribe((data: any) => {
      if (data) {
        this.isDeleteAction = true;
        this.vendorStore.dispatch(DeleteVendorProduct({id: (product.id || product._id)}));
      }
    })
  }

  getImage(item: any, size: any) {
    return this.uploadImage.getImages(item.coverImage, size);
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete()
  }
}
