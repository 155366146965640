import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {VendorState} from "../../+state/vendor.reducer";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {countryCurrency, Store as StoreModel} from "@kwot/data-models";
import {Subject, takeUntil} from "rxjs";
import {getVendorDetails} from '../../+state/vendor.selectors';
import {GetVendorBankDetails, StoreSetupStoreProfile} from '../../+state/vendor.actions';
import {StoreSetupService} from '../../modules/store-setup-screens/service/store-setup.service';

@Component({
  selector: 'kwot-payout-detail',
  templateUrl: './payout-detail.component.html',
  styleUrls: ['./payout-detail.component.scss']
})
export class PayoutDetailComponent implements OnInit, OnDestroy {

  vendor: StoreModel;
  unsubscriber = new Subject();
  bankForm: UntypedFormGroup;
  countries: any[] = [];
  bankName: any[] = [];
  logoutInitiated = false;

  constructor(
    private vendorStore: Store<VendorState>,
    private formBuilder: UntypedFormBuilder,
    private storeSetupService: StoreSetupService
  ) {
    this.bankForm = this.formBuilder.group({
      bankName: ['', Validators.required],
      // bankCode: [''],
      bankCountry: [null, Validators.required],
      accountType: ['', Validators.required],
      accountString: ['', Validators.required],
      // bankVerificationNumber: [''],
      isFeatured: [false, Validators.requiredTrue],
      beneficiaryCode: ['', Validators.required],
      beneficiaryName: ['', Validators.required],
    })
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.vendorStore.pipe(select(getVendorDetails))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(vendor => {
        if (vendor) {
          this.vendor = vendor;
          if (vendor.hasOwnProperty('bankDetails') && vendor.bankDetails) {
            this.bankForm.patchValue({
              bankName: vendor.bankDetails.bankName,
              // bankCode: vendor.bankDetails.bankCode,
              bankCountry: vendor.bankDetails.bankCountry,
              accountType: vendor.bankDetails.accountType,
              accountString: vendor.bankDetails.accountString,
              // bankVerificationNumber: vendor.bankDetails.bankVerificationNumber,
              isFeatured: true,
              beneficiaryCode: vendor.bankDetails.beneficiaryCode,
              beneficiaryName: vendor.bankDetails.beneficiaryName,
            })
          }
          if (this.logoutInitiated) {
            this.storeSetupService.updateSaved();
          }
        }
      })
  }

  ngOnInit(): void {
    this.countries = countryCurrency.map(item => ({id: item.iso2, name: item.name}));
    this.vendorStore.dispatch(GetVendorBankDetails());
    this.storeSetupService.saveLogout$.pipe(takeUntil(this.unsubscriber))
      .subscribe(result => {
        if (result) {
          this.logoutInitiated = true;
          this.submitForm();
        }
      })
  }

  submitForm() {
    if (this.bankForm.invalid) {
      if (this.logoutInitiated) {
        this.saveForm();
        this.bankForm.patchValue({...this.bankForm.value})
      }
      return;
    }
    this.saveForm();
    this.bankForm.patchValue({...this.bankForm.value})
  }

  saveForm() {
    const body = {
      ...this.bankForm.value,
      accountString: this.bankForm.value.accountString.toString(),
      profileStatus: 6,
      currentProfileStatus: this.vendor.profileStatus > 7 ? this.vendor.profileStatus : 6
    }
    delete body.isFeatured;
    this.vendorStore.dispatch(StoreSetupStoreProfile({body, query: {ignoreStatusUpdate: true}}));
  }

  get form() {
    return this.bankForm.controls;
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }


}
