import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {

  transform(input: string, searchValue: string, replaceValue: string = ''): string {
    if (!input) return '';
    return input.replace(searchValue, replaceValue);
  }
}
