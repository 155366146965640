import {Injectable} from '@angular/core';
import {TreeviewItem, TreeviewSelection, DefaultTreeviewI18n} from 'ngx-treeview';

@Injectable()
export class DropdownTreeviewSelectI18n extends DefaultTreeviewI18n {
  private internalSelectedItem: TreeviewItem;
  private options: any;
  private parents: any[] = [];

  set selectedItem(value: TreeviewItem) {
    this.internalSelectedItem = value;
  }

  get selectedItem(): TreeviewItem {
    return this.internalSelectedItem;
  }

  set selectedOptions(options: any) {
    this.options = options;
  }

  get selectedOptions(): any {
    return this.options;
  }

  override getText(selection: TreeviewSelection): string {
    if (this.options && this.options.displayWithParent) {
      if (this.options.originalItems) {
        if (this.internalSelectedItem) {
          this.parents = [];
          this.findParents({_id: this.internalSelectedItem.value});
          if (this.parents.length > 0) {
            return this.parents.join(' -> ') + ' -> ' + this.internalSelectedItem.text
          }
        }
      }
    }
    return this.internalSelectedItem ? this.internalSelectedItem.text : 'Please select';
  }

  findParents(item: any) {
    if (item) {
      const currentItem = this.options.originalItems.find((t: any) => t._id === item._id);
      if (currentItem && currentItem.parentId) {
        const parent = this.options.originalItems.find((t: any) => t._id === currentItem.parentId._id)
        if (parent) {
          this.parents.unshift(parent.name);
          this.findParents(parent);
        }
      }
    }
  }
}
