<div class="app-product p-5">
  <div class="px-32 d-flex text-center">
    <h4 class="font-weight-bold ff-adamina brand-black w-100">
      {{ 'measurements' | translate }}
    </h4>
    <button type="button" class="btn-close" (click)="bsModalRef.hide()">
    </button>
  </div>
  <form [formGroup]="measurementForm" novalidate (ngSubmit)="!measurementForm.invalid && submitForm()">
    <div class="container">
      <div class="form-group row mt-2">
        <div class="d-flex justify-content-between">
          <label class="neutral_60 font-weight-bold mb-0 ml-2 ff-dmsans fs-12 ps-2">
            {{ 'metrics' | translate }} *
          </label>
          <ng-container
            *ngIf="(form.metrics.dirty || form.metrics.touched) && form.metrics.errors">
            <p *ngIf="form.metrics.errors.required"
               class="text-danger font-weight-bold mb-0 ml-2 ff-dmsans fs-10 ps-2">
              {{'errors.is_required' | translate: {field: 'metrics' | translate} }}
            </p>
          </ng-container>
        </div>
        <div class="dropdown">
          <kwot-custom-select
            class="measurement"
            [searchable]="true"
            [selectOptions]="measurement"
            selectedValue="metrics"
            [formGroup]="measurementForm"
            placeholder="{{'Measurement' | translate }}">
          </kwot-custom-select>
        </div>
      </div>

      <div class="form-group text-left row" *ngIf="_measurementsList?.length > 0">
        <ng-container *ngFor="let item of _measurementsList">
          <div class="col-6 mt-2">
            <div class="d-flex justify-content-between">
              <label class="neutral_60 font-weight-bold mb-0 ml-2 ff-dmsans fs-12 ps-2">
                {{ item.name }} {{checkIfRequired(item.id) ? '*' : ''}}
              </label>
              <ng-container
                *ngIf="(form[item.id].dirty || form[item.id].touched) && form[item.id].errors">
                <p *ngIf="form[item.id].errors.required"
                   class="text-danger font-weight-bold mb-0 ml-2 ff-dmsans fs-10 ps-2">
                  {{'errors.is_required' | translate: {field: item.name} }}
                </p>
              </ng-container>
            </div>
            <input type="number" numbersOnly class="form-control fc-input bg-white" formControlName="{{item.id}}"
                   placeholder="Enter {{item.name}} ({{form.metrics.value}})"/>
          </div>
        </ng-container>
      </div>
      <div class="w-100 ms-auto text-center">
        <button class="btn-action px-32 w-auto">
          {{'save' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>
