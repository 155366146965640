import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {measurementsList} from "@kwot/data-models";
import {Subject, takeUntil} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import {select, Store} from "@ngrx/store";
import {SharedState} from "../../+state/shared.reducer";
import {SaveMeasurements} from "../../+state/shared.actions";
import {getUserMeasurements} from "../../+state/shared.selectors";
import {APP_CONFIG, LocalstorageService} from "@kwot/app-config";

@Component({
  selector: 'kwot-custom-measurement',
  templateUrl: './custom-measurement.component.html',
  styleUrls: ['./custom-measurement.component.scss']
})
export class CustomMeasurementComponent implements OnInit, OnDestroy {
  measurementForm: UntypedFormGroup;
  measurement: any[] = [
    {name: "CM", id: "cm"},
    {name: "INCH", id: "inch"}
  ];
  _measurementsList = measurementsList;
  public closeEvent: Subject<any>;
  unsubscriber = new Subject();
  // productDetails: any
  allowGuest = false
  orderMeasurements: any
  orderId: any
  userId: any

  constructor(
    private formBuilder: UntypedFormBuilder,
    public bsModalRef: BsModalRef,
    private sharedStore: Store<SharedState>,
    private ls: LocalstorageService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
  }

  subscribeToStore() {
    this.sharedStore.pipe(select(getUserMeasurements))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(measurements => {
        if (measurements) {
          this.measurementForm.patchValue({
            ...measurements
          })
          this.closeEvent.next({...measurements});
          this.bsModalRef.hide();
        }
      })
  }

  createForm() {
    const form: any = {};
    measurementsList.forEach((item: any) => {
      // let demo = this.productDetails.sizeMe.list.find((t: any) => t === item.id);
      form[item.id] = ['']
    })
    this.measurementForm = this.formBuilder.group({
      metrics: ['CM', Validators.required],
      ...form
    })
  }

  ngOnInit(): void {
    this.createForm();
    this.closeEvent = new Subject();
    let user: any = {};
    if (this.appConfig.type === 'user') {
      this.subscribeToStore()
      let user = this.ls.updateUserKey('get');
      if (!user && this.allowGuest) {
        user = this.ls.updateGuestUserKey('get');
      }
    } else {
      user.measurements = this.orderMeasurements;
    }
    if (user) {
      const form: any = {
        metrics: user?.measurements?.metrics
      };
      measurementsList.forEach((item: any) => {
        form[item.id] = user?.measurements ? user?.measurements[item.id] : ''
      })
      this.measurementForm.patchValue({
        ...form
      })
    }
  }

  checkIfRequired(field: any) {
    return this.form[field].hasValidator(Validators.required)
  }

  get form() {
    return this.measurementForm.controls;
  }

  submitForm() {
    if (this.measurementForm.invalid) {
      return;
    }
    let body = {};
    if (this.appConfig.type === 'user') {
      body = this.measurementForm.value;
    } else {
      body = {
        data: this.measurementForm.value,
        userId: this.userId,
        orderId: this.orderId
      }
    }
    this.sharedStore.dispatch(SaveMeasurements({body, allowGuest: this.allowGuest}))
    if (this.appConfig.type === 'admin') {
      this.closeEvent.next({...this.measurementForm.value});
      this.bsModalRef.hide();
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
