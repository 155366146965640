import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LoaderService, LocalstorageService} from "@kwot/app-config";
import {VendorService} from "../services/vendor.service";
import {
  AddPayoutData, AddPayoutDataError, AddPayoutDataSuccess,
  AddScheduleData, AddScheduleDataError, AddScheduleDataSuccess,
  CheckStoreNameAvailability,
  CheckStoreNameAvailabilityError,
  CheckStoreNameAvailabilitySuccess,
  DeleteVendorProduct,
  DeleteVendorProductError,
  DeleteVendorProductSuccess,
  GetCategories,
  GetCategoriesError,
  GetCategoriesSuccess,
  GetMyReviews,
  GetMyReviewsError,
  GetMyReviewsSuccess, GetPayoutDetail, GetPayoutDetailError, GetPayoutDetailSuccess,
  GetProductReviews,
  GetProductReviewsError,
  GetProductReviewsSuccess,
  GetVendorAllProducts,
  GetVendorAllProductsError,
  GetVendorAllProductsSuccess,
  GetVendorBankDetails,
  GetVendorBankDetailsError,
  GetVendorBankDetailsSuccess,
  GetVendorBusinessDetails,
  GetVendorBusinessDetailsError,
  GetVendorBusinessDetailsSuccess,
  GetVendorDetails,
  GetVendorDetailsError,
  GetVendorDetailsSuccess,
  GetVendorProduct,
  GetVendorProductError,
  GetVendorProductSuccess,
  GetWalletBalance,
  GetWalletBalanceError,
  GetWalletBalanceSuccess,
  GetWalletTransaction, GetWalletTransactionError,
  GetWalletTransactionSuccess,
  ResendProfileVerification,
  ResendProfileVerificationError,
  ResendProfileVerificationSuccess,
  StoreSetupStorePreference,
  StoreSetupStorePreferenceError,
  StoreSetupStorePreferenceSuccess,
  StoreSetupStoreProfile,
  StoreSetupStoreProfileError,
  StoreSetupStoreProfileSuccess,
  UpdateVendorProduct,
  UpdateVendorProductError,
  UpdateVendorProductSuccess,
  VendorDashboard,
  VendorDashboardError,
  VendorDashboardSuccess,
  VendorOrderManage,
  VendorOrderManageError,
  VendorOrderManageSuccess,
  VendorOrdersList,
  VendorOrdersListError,
  VendorOrdersListSuccess,
  GetPolicyDetail,
  GetPolicyDetailSuccess,
  GetPolicyDetailError, AcceptPolicyDetail, AcceptPolicyDetailSuccess, AcceptPolicyDetailError
} from "./vendor.actions";
import {catchError, map, of, switchMap} from "rxjs";
import {LoginSuccess} from "@kwot/auth";

@Injectable()
export class VendorEffects {
  constructor(
    private actions$: Actions,
    private loaderService: LoaderService,
    private vendorService: VendorService,
    private ls: LocalstorageService
  ) {
  }

  updateStorePreference$ = createEffect(() => this.actions$.pipe(
    ofType(StoreSetupStorePreference),
    switchMap((action) => {
      this.loaderService.show();
      const body = {...action.body};
      delete body.currentProfileStatus;
      return this.vendorService.updateStoreProfile(body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            const updatedVendor = {
              ...action.body,
              country: action.body.country_pro,
              profileStatus: action.body.currentProfileStatus
            }
            delete updatedVendor.currentProfileStatus;
            return StoreSetupStorePreferenceSuccess({updatedVendor});
          }
          return StoreSetupStorePreferenceError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(StoreSetupStorePreferenceError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getCategories$ = createEffect(() => this.actions$.pipe(
    ofType(GetCategories),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getAllCategories(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetCategoriesSuccess({categories: resp.data});
          }
          return GetCategoriesError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetCategoriesError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  vendorDetails$ = createEffect(() => this.actions$.pipe(
    ofType(GetVendorDetails),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getVendorDetails(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetVendorDetailsSuccess({vendor: resp.data});
          }
          return GetVendorDetailsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetVendorDetailsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  checkStoreName$ = createEffect(() => this.actions$.pipe(
    ofType(CheckStoreNameAvailability),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.checkStoreNameAvailability(action.name).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return CheckStoreNameAvailabilitySuccess({isStoreNameAvailable: 'available'});
          }
          return CheckStoreNameAvailabilityError({
            error: this.loaderService.getErrorMessage(resp),
            isStoreNameAvailable: 'un-available'
          });
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(CheckStoreNameAvailabilityError({
            error: this.loaderService.getErrorMessage(error),
            isStoreNameAvailable: 'un-available'
          }))
        })
      )
    })
  ))

  updateStoreSetupProfile$ = createEffect(() => this.actions$.pipe(
    ofType(StoreSetupStoreProfile),
    switchMap((action) => {
      this.loaderService.show();
      const body = {...action.body};
      delete body.currentProfileStatus;
      return this.vendorService.updateStoreProfile(body, action.query).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            let user = this.ls.updateUserKey('get');
            if (user) {
              user = {
                ...user,
                profileStatus: body.profileStatus
              }
              this.ls.updateUserKey('store', user);
            }
            return StoreSetupStoreProfileSuccess({updatedVendor: resp.data});
          }
          return StoreSetupStoreProfileError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(StoreSetupStoreProfileError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getVendorBusinessDetails$ = createEffect(() => this.actions$.pipe(
    ofType(GetVendorBusinessDetails),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getVendorBusinessDetails().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            let updatedVendor = {
              ...((resp.data && resp.data.billingDetails) ? {billingDetails: resp.data.billingDetails} : {}),
              ...((resp.data && resp.data.buisnessBillingDetails) ? {buisnessBillingDetails: resp.data.buisnessBillingDetails} : {}),
              profileStatus: 4
            }
            return GetVendorBusinessDetailsSuccess({updatedVendor});
          }
          return GetVendorBusinessDetailsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetVendorBusinessDetailsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getVendorBankDetails$ = createEffect(() => this.actions$.pipe(
    ofType(GetVendorBankDetails),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getVendorBankDetails().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            let updatedVendor = {
              ...(resp.data ? {bankDetails: resp.data} : {}),
              profileStatus: 5
            }
            return GetVendorBankDetailsSuccess({updatedVendor});
          }
          return GetVendorBankDetailsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetVendorBankDetailsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getVendorAllProducts$ = createEffect(() => this.actions$.pipe(
    ofType(GetVendorAllProducts),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getVendorAllProducts(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetVendorAllProductsSuccess({products: resp.data || {data: [], count: 0}});
          }
          return GetVendorAllProductsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetVendorAllProductsError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  deleteProduct$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteVendorProduct),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.deleteVendorProduct(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return DeleteVendorProductSuccess({id: action.id});
          }
          return DeleteVendorProductError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(DeleteVendorProductError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getProduct$ = createEffect(() => this.actions$.pipe(
    ofType(GetVendorProduct),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getVendorProduct(action.id).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetVendorProductSuccess({product: resp.data});
          }
          return GetVendorProductError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetVendorProductError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  resendVerification$ = createEffect(() => this.actions$.pipe(
    ofType(ResendProfileVerification),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.resendProfileVerification(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return ResendProfileVerificationSuccess();
          }
          return ResendProfileVerificationError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(ResendProfileVerificationError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  updateVendorProduct$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateVendorProduct),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.updateProduct(action.product).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return UpdateVendorProductSuccess({product: resp.data});
          }
          return UpdateVendorProductError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(UpdateVendorProductError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getAllOrders$ = createEffect(() => this.actions$.pipe(
    ofType(VendorOrdersList),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getAllOrders(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return VendorOrdersListSuccess({orders: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return VendorOrdersListError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(VendorOrdersListError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getDashboard$ = createEffect(() => this.actions$.pipe(
    ofType(VendorDashboard),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getVendorDashboard(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return VendorDashboardSuccess({dashboard: resp.data});
          }
          return VendorDashboardError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(VendorDashboardError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  orderManagement$ = createEffect(() => this.actions$.pipe(
    ofType(VendorOrderManage),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.orderManagement(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return VendorOrderManageSuccess({orderInfo: resp.data});
          }
          return VendorOrderManageError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(VendorOrderManageError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  getProductReviews$ = createEffect(() => this.actions$.pipe(
    ofType(GetProductReviews),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getProductReviews(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetProductReviewsSuccess({reviews: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return GetProductReviewsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetProductReviewsError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  getMyReviews$ = createEffect(() => this.actions$.pipe(
    ofType(GetMyReviews),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getMyReviews(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetMyReviewsSuccess({reviews: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          return GetMyReviewsError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetMyReviewsError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  getWalletBalance$ = createEffect(() => this.actions$.pipe(
    ofType(GetWalletBalance),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getVendorWalletBalance().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetWalletBalanceSuccess({wallet: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          // @ts-ignore
          return GetWalletBalanceError({error: this.loaderService.getErrorMessage()});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetWalletBalanceError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))
  getWalletTransactionBalance$ = createEffect(() => this.actions$.pipe(
    ofType(GetWalletTransaction),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getWalletTransaction(action.params).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetWalletTransactionSuccess({walletTransaction: resp.data || {data: [], count: {total: 0, page: 0}}});
          }
          // @ts-ignore
          return GetWalletTransactionError({error: this.loaderService.getErrorMessage()});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetWalletTransactionError({error: this.loaderService.getErrorMessage(error)}));
        })
      )
    })
  ))

  addScheduleData$ = createEffect(() => this.actions$.pipe(
    ofType(AddScheduleData),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.addWalletAmount(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return AddScheduleDataSuccess({wallet: resp.data});
          }
          return AddScheduleDataError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(AddScheduleDataError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  addPayoutData$ = createEffect(() => this.actions$.pipe(
    ofType(AddPayoutData),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.addPayoutData(action.body).pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return AddPayoutDataSuccess({payout: resp.data});
          }
          return AddPayoutDataError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(AddPayoutDataError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getPayoutDetail$ = createEffect(() => this.actions$.pipe(
    ofType(GetPayoutDetail),
    switchMap((action) => {
      this.loaderService.show();
      return this.vendorService.getPayoutDetail().pipe(
        map((resp: any) => {
          this.loaderService.hide();
          if (resp.status) {
            return GetPayoutDetailSuccess({payout: resp.data});
          }
          return GetPayoutDetailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          this.loaderService.hide();
          return of(GetPayoutDetailError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  getPolicyDetail$ = createEffect(() => this.actions$.pipe(
    ofType(GetPolicyDetail),
    switchMap((action) => {
      return this.vendorService.getPolicyData(action.params).pipe(
        map((resp: any) => {
          if (resp.status) {
            return GetPolicyDetailSuccess({policy: resp.data});
          }
          return GetPolicyDetailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          return of(GetPolicyDetailError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

  acceptPolicyDetail$ = createEffect(() => this.actions$.pipe(
    ofType(AcceptPolicyDetail),
    switchMap((action) => {
      return this.vendorService.updatePolicyData(action.params).pipe(
        map((resp: any) => {
          if (resp.status) {
            return AcceptPolicyDetailSuccess();
          }
          return AcceptPolicyDetailError({error: this.loaderService.getErrorMessage(resp)});
        }),
        catchError(error => {
          return of(AcceptPolicyDetailError({error: this.loaderService.getErrorMessage(error)}))
        })
      )
    })
  ))

}
