import {Component, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from "@ngrx/store";
import {VendorState} from "../../+state/vendor.reducer";
import {ResetVendorState, VendorDashboard} from "../../+state/vendor.actions";
import {Subject, takeUntil} from "rxjs";
import {getVendorDashboard} from "../../+state/vendor.selectors";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ProfileRejectedComponent} from "../../_components/profile-rejected/profile-rejected.component";
import * as moment from "moment";

@Component({
  selector: 'kwot-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {

  public chartOptions: any;
  dashboard: any;
  unsubscriber = new Subject();
  chartData: any[] = [];
  month: any[] = [
    {
      name: 'Current Week',
      id: `${moment().startOf('week').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}/CW`
    },
    {
      name: 'Current Month',
      id: `${moment().startOf('month').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}/CM`
    },
    {
      name: 'Current Year',
      id: `${moment().startOf('year').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}/CY`
    },
    {
      name: 'Last Week',
      id: `${moment().startOf('week').subtract(7, 'days').format('YYYY-MM-DD')}/${moment().endOf('week').subtract(7, 'day').format('YYYY-MM-DD')}/LW`
    },
    {
      name: 'Last Month',
      id: `${moment().startOf('month').subtract(1, 'month').format('YYYY-MM-DD')}/${moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD')}/LM`
    },
    {
      name: 'Last Year',
      id: `${moment().startOf('year').subtract(1, 'year').format('YYYY-MM-DD')}/${moment().endOf('year').subtract(1, 'year').format('YYYY-MM-DD')}/LY`
    },
  ];
  vendorOrders: any = [];
  totalPrice = 0;
  dates: any = [];
  range: any;
  monthRange: any;

  constructor(
    private vendorStore: Store<VendorState>,
    private modelService: BsModalService
  ) {
    this.vendorStore.dispatch(ResetVendorState({params: {error: '', success: '', dashboard: null}}))
    this.subscribeToStore();

    this.chartOptions = {
      series: [],
      grid: {
        xaxis: {
          lines: {
            show: false
          }
        },
        yaxis: {
          lines: {
            show: false
          }
        },
        padding: {
          left: 22,
        }
      },
      chart: {
        type: "line",
        width: '100%',
        height: 180,
        toolbar: {
          show: false
        },
        redrawOnParentResize: true,
        redrawOnWindowResize: true
      },
      colors: ['#000000'],
      stroke: {
        curve: 'smooth',
        colors: ['#000000'],
        width: 4,
      },
      xaxis: {
        type: 'category',
        categories: [],
        axisBorder: {
          show: true,
          color: '#D1D6DC',
          height: 1,
          width: '100%',
          offsetX: -22,
          offsetY: 0
        },
        labels: {
          show: true,
          style: {
            colors: '#727981'
          }
        },
        tickAmount: 7,
        axisTicks: {
          show: true
        },
      },
      yaxis: {
        axisTicks: {
          show: false
        },
        forceNiceScale: true,
        min: 0,
        labels: {
          show: false,
        },
        axisBorder: {
          show: true,
          color: '#D1D6DC',
          height: 1,
          offsetX: -15,
          offsetY: 0
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: (seriesData: any) => {
            return parseInt(seriesData);
          }
        }
      }
    }
    this.monthRange = `${moment().startOf('month').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}/CM`
  }

  subscribeToStore() {
    this.vendorStore.pipe(select(getVendorDashboard))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(dashboard => {
        if (dashboard) {
          this.dashboard = {...dashboard};
          this.chartData = [...dashboard.graphData];

          this.vendorOrders = {};
          this.totalPrice = 0;
          this.chartData.forEach((data) => {
            let format  = (this.range == 'CY' || this.range == 'LY') ? 'MM-YYYY' : 'D MMM';
            let date = moment(data.createdAt).format(format)
            this.totalPrice += Number((data.price * data.quantity).toFixed(2))
            if (!this.vendorOrders[date]) {
              this.vendorOrders[date] = 0;
            }
            this.vendorOrders[date] += 1;
          })

          let series = [];
          let data: any = [];
          this.dates.forEach((each: any) => {
            data.push(parseInt(this.vendorOrders[each] || 0));
          });

          series.push({
            name: 'Orders',
            data: data,
          })

          this.chartOptions.series = series
        }
      })
  }

  ngOnInit(): void {
    this.vendorStore.dispatch(VendorDashboard({
      params: {
        monthRange: this.monthRange
      }
    }));
    const date = this.monthRange
    this.getDates(date)
  }

  showRejectReason() {
    const modelRef: BsModalRef = this.modelService.show(ProfileRejectedComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-lg modal-dialog-centered custom-model',
      backdrop: true,
    });
    modelRef.content.blockedReason = this.dashboard?.storeProfileData?.rejectReason;
    modelRef.content.blockedMessage = this.dashboard?.storeProfileData?.rejectMessage;
    modelRef.content.closeEvent.subscribe((result: any) => {
      if (result) {
      }
    });
  }

  filterData(event: any) {
    this.vendorStore.dispatch(VendorDashboard({
      params: {
        monthRange: event.value || this.monthRange
      }
    }))
    this.getDates(event.value || this.monthRange)
  }

  getDates(date: any) {
    this.dates = [];
    let [start, end, range] = date.split('/');
    start = moment(start);
    end = moment(end);
    this.range = range;

    if (this.range == 'CY' || this.range == 'LY') {
      while (start.isBefore(end)) {
        this.dates.push(start.format("MM-YYYY"));
        start = start.add(1, "month");
      }
    } else {
      this.dates.push(start.format('D MMM'))
      while (start.add(1, 'days').diff(end) < 0) {
        this.dates.push(moment(start.clone().toDate()).format('D MMM'));
      }
      this.dates.push(end.format('D MMM'))
    }

    this.chartOptions.xaxis = {
      ...this.chartOptions.xaxis,
      categories: this.dates
    }
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete();
  }

}
