<div class="bank-details position-relative">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="bank-details-header d-flex align-items-center justify-content-between">
          <div class="back-button">
            <div class="back-btn d-flex align-items-center" [routerLink]="['/vendor', 'finances']">
              <img src="/assets/images/icons/back.svg" class="cursor-pointer"/>
              <p class="title-back mb-0 fs-16 cursor-pointer">{{'back' | translate}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="container">
        <div class="row">
          <div class="col-md-9">
            <form [formGroup]="bankForm" (ngSubmit)="!bankForm.invalid && submitForm()" novalidate>
              <div class="bank-details-form">
                <div class="bank-details-header d-flex align-items-center justify-content-between">
                  <button type="submit" class="btn-action w-auto" [ngClass]="{'btn-disabled': bankForm.invalid, 'btn-action': !bankForm.invalid}">Save</button>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="title">
                      <h3 class="fs-32 fw-bold">
                        Edit Bank details
                      </h3>
                      <p class="fs-16">
                        Kwot Payments gives buyers the most payment options and gives you Kwot's seller protection.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{'bank_country' | translate}} *</label>
                      <ng-container *ngIf="form.bankCountry.errors && (form.bankCountry.touched || form.bankCountry.dirty)">
                        <label *ngIf="form.bankCountry.errors.required"
                               class="text-danger mb-0 me-2 fs-10 fw-bold">
                          {{ 'errors.is_required' | translate: {field: 'bank_country' | translate} }}</label>
                      </ng-container>
                      <kwot-custom-select
                        class="edit-bank-country"
                        [searchable]="true"
                        [selectOptions]="countries"
                        selectedValue="bankCountry"
                        [formGroup]="bankForm"
                        placeholder="{{'select_country' | translate}}">
                      </kwot-custom-select>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <div class="d-flex justify-content-between">
                      <label>{{'bank_name' | translate}} *</label>
                      <ng-container *ngIf="form.bankName.errors && (form.bankName.touched || form.bankName.dirty)">
                        <label *ngIf="form.bankName.errors.required"
                               class="text-danger mb-0 me-2 fs-10 fw-bold">
                          {{ 'errors.is_required' | translate: {field: 'bank_name' | translate} }}</label>
                      </ng-container>
                    </div>
                    <input class="form-control" type="text" formControlName="bankName" inputRef
                           placeholder="{{'placeholder.type' | translate:{field: 'bank_name' | translate | lowercase} }}">
                  </div>

                  <div class="form-group col-md-6">
                    <div class="d-flex justify-content-between">
                      <label>{{'account_type' | translate}} *</label>
                      <ng-container *ngIf="form.accountType.errors && (form.accountType.touched || form.accountType.dirty)">
                        <label *ngIf="form.accountType.errors.required"
                               class="text-danger mb-0 me-2 fs-10 fw-bold">
                          {{ 'errors.is_required' | translate: {field: 'account_type' | translate} }}</label>
                      </ng-container>
                    </div>
                    <input class="form-control" type="text" formControlName="accountType" inputRef
                           placeholder="{{'placeholder.type' | translate:{field: 'account_type' | translate | lowercase} }}">
                  </div>
<!--                  <div class="form-group col-md-6">-->
<!--                    <div class="d-flex justify-content-between">-->
<!--                      <label>{{'bank_code' | translate}}</label>-->
<!--&lt;!&ndash;                      <ng-container *ngIf="form.bankCode.errors && (form.bankCode.touched || form.bankCode.dirty)">&ndash;&gt;-->
<!--&lt;!&ndash;                        <label *ngIf="form.bankCode.errors.required"&ndash;&gt;-->
<!--&lt;!&ndash;                               class="text-danger mb-0 me-2 fs-10 fw-bold">&ndash;&gt;-->
<!--&lt;!&ndash;                          {{ 'errors.is_required' | translate: {field: 'bank_code' | translate} }}</label>&ndash;&gt;-->
<!--&lt;!&ndash;                      </ng-container>&ndash;&gt;-->
<!--                    </div>-->
<!--                    <input class="form-control" type="text" formControlName="bankCode" inputRef-->
<!--                           placeholder="{{'placeholder.type' | translate:{field: 'bank_code' | translate | lowercase} }}">-->
<!--                  </div>-->

                  <div class="form-group col-md-6">
                    <div class="d-flex justify-content-between">
                      <label>{{'beneficiary_code' | translate}} *</label>
                        <ng-container *ngIf="form.beneficiaryCode.errors && (form.beneficiaryCode.touched || form.beneficiaryCode.dirty)">
                          <label *ngIf="form.beneficiaryCode.errors.required"
                                 class="text-danger mb-0 me-2 fs-10 fw-bold">
                            {{ 'errors.is_required' | translate: {field: 'beneficiary_code' | translate} }}</label>
                        </ng-container>
                    </div>
                    <input class="form-control" type="text" formControlName="beneficiaryCode" inputRef
                           placeholder="{{'placeholder.type' | translate:{field: 'beneficiary_code' | translate | lowercase} }}">
                  </div>

                  <div class="form-group col-md-6">
                    <div class="d-flex justify-content-between">
                      <label>{{'beneficiary_name' | translate}} *</label>
                        <ng-container *ngIf="form.beneficiaryName.errors && (form.beneficiaryName.touched || form.beneficiaryName.dirty)">
                          <label *ngIf="form.beneficiaryName.errors.required"
                                 class="text-danger mb-0 me-2 fs-10 fw-bold">
                            {{ 'errors.is_required' | translate: {field: 'beneficiary_name' | translate} }}</label>
                        </ng-container>
                    </div>
                    <input class="form-control" type="text" formControlName="beneficiaryName" inputRef
                           placeholder="{{'placeholder.type' | translate:{field: 'beneficiary_name' | translate | lowercase} }}">
                  </div>

                  <div class="form-group col-md-6">
                    <div class="d-flex justify-content-between">
                      <label>{{'account_number' | translate}} *</label>
                      <ng-container *ngIf="form.accountString.errors && (form.accountString.touched || form.accountString.dirty)">
                        <label *ngIf="form.accountString.errors.required"
                               class="text-danger mb-0 me-2 fs-10 fw-bold">
                          {{ 'errors.is_required' | translate: {field: 'account_number' | translate} }}</label>
                      </ng-container>
                    </div>
                    <input class="form-control" type="text" formControlName="accountString" inputRef
                           placeholder="{{'placeholder.type' | translate:{field: 'account_number' | translate | lowercase} }}">
                  </div>
<!--                  <div class="form-group col-md-6">-->
<!--                    <div class="d-flex justify-content-between">-->
<!--                      <label>{{'bank_verification_number' | translate}}</label>-->
<!--&lt;!&ndash;                      <ng-container *ngIf="form.bankVerificationNumber.touched && (form.bankVerificationNumber.dirty || form.bankVerificationNumber.errors)">&ndash;&gt;-->
<!--&lt;!&ndash;                        <label *ngIf="form.bankVerificationNumber.errors.required"&ndash;&gt;-->
<!--&lt;!&ndash;                               class="text-danger mb-0 me-2 fs-10 fw-bold">&ndash;&gt;-->
<!--&lt;!&ndash;                          {{ 'errors.is_required' | translate: {field: 'bank_verification_number' | translate} }}</label>&ndash;&gt;-->
<!--&lt;!&ndash;                      </ng-container>&ndash;&gt;-->
<!--                    </div>-->
<!--                    <input class="form-control" type="text" formControlName="bankVerificationNumber" inputRef-->
<!--                           placeholder="{{'placeholder.type' | translate:{field: 'bank_verification_number' | translate | lowercase} }}">-->
<!--                  </div>-->
                  <div class="col-12">
                    <div class="d-flex bank-details-switch-button mt-4">
                      <label class="action-switch-green-btn me-2 m-0">
                        <input type="checkbox" formControlName="isFeatured">
                        <span class="slider round"></span>
                      </label>

                      <h6 class="ms-2 fs-16 mb-0 w-100">
                        {{'by_ticking' | translate}}
                        <span class="cursor-pointer"
                              [routerLink]="['/vendor', 'payment-policy']">{{'payment_policy'| translate }}</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
