import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {CustomTableComponent} from "@kwot/admin-vendor-shared";
import {TranslateService} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {VendorState} from "../../+state/vendor.reducer";
import {
  GetMyReviews,
  GetProductReviews,
  ResetVendorState,
} from "../../+state/vendor.actions";
import {getReviews} from "../../+state/vendor.selectors";
import {APP_CONFIG} from "@kwot/app-config";

@Component({
  selector: 'kwot-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {

  selectedFilter: string | number = 'product_reviews';
  filters: any[] = [
    {
      value: 'product_reviews',
      label: 'Product Reviews',
    },
    {
      value: 'my_reviews',
      label: 'My Reviews',
    },
  ];
  customDatatableSettings: any = {};
  reviewsList: any[] = [];
  unsubscriber = new Subject();
  editId = '';
  allData: any;
  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;
  isDeleteAction = false;
  reviewsFoundFirstTime = false;

  constructor(
    private translateService: TranslateService,
    private vendorStore: Store<VendorState>,
    @Inject(APP_CONFIG) public appConfig: any,
  ) {
    this.vendorStore.dispatch(ResetVendorState({params: {error: '', success: '', reviews: null}}));
    this.subscribeToStore();
  }

  subscribeToStore() {
    this.vendorStore.pipe(select(getReviews))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(reviews => {
        if (reviews) {
          this.allData = reviews;
          this.reviewsList = [...reviews.data];
          if (this.reviewsList.length > 0 && !this.reviewsFoundFirstTime) {
            this.reviewsFoundFirstTime = true
          }
          if (this.customTableComponent.ajaxCallback) {
            this.customTableComponent.ajaxCallback({
              recordsTotal: reviews.count.total,
              recordsFiltered: reviews.count.total,
              data: []
            })

            setTimeout(() => {
              this.customTableComponent.reAdjustColumnsInTable();
            }, 500);
          }
        }
      })
  }

  ngOnInit(): void {
    this.customDatatableSettings = {
      paging: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      searchPlaceholder: this.translateService.instant('search_by', {field: this.translateService.instant('products_no_bracket')}),
      orderColumns: ['productName', 'buyer', 'review'],
      order: [[0, 'desc']]
    }
  }

  getAllReviews = (page = 0, perPage = 10, search = '', order = {
    filterBy: 'productName',
    filterType: 'dec'
  }) => {
    if (this.selectedFilter === 'my_reviews') {
      this.vendorStore.dispatch(GetMyReviews({
        params: {
          page, limit: perPage,
          search,
          ...order
        }
      }))
    } else {
      this.vendorStore.dispatch(GetProductReviews({
        params: {
          page, limit: perPage,
          search,
          ...order
        }
      }))
    }
  }

  filterProduct(value: string | number) {
    this.selectedFilter = value
    this.customDatatableSettings = {
      paging: true,
      serverSide: true,
      columns: this.selectedFilter === 'my_reviews' ? [{data: null}, {data: null}, {data: null}] : [{data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      searchPlaceholder: this.translateService.instant('search_by', {field: this.translateService.instant((this.selectedFilter === 'my_reviews' ? 'buyer' : 'products_no_bracket'))}),
      orderColumns: this.selectedFilter === 'my_reviews' ? ['buyer', 'review'] : ['productName', 'buyer', 'review'],
      order: [[0, 'desc']]
    }
    this.getAllReviews(undefined, undefined, undefined, undefined);
  }

  ngOnDestroy() {
    this.unsubscriber.next(true);
    this.unsubscriber.complete()
  }

}
