import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {ImageCroppedEvent} from "ngx-image-cropper";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'kwot-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  imageChangedEvent: any;
  @Input() resizeWidth: number;
  @Input() resizeHeight: number;
  @Input() event: any;
  croppedImage: any
  public closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
  ) {
  }

  ngOnInit(): void {
    this.imageChangedEvent = this.event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  submitForm() {
    this.closeEvent.emit(this.croppedImage);
    this.bsModalRef.hide();
  }
}
