<div class="modal-body">
  <div class="d-flex align-items-center justify-content-center mb-3">
    <h4 class="fw-bold fs-18 mb-0" *ngIf="title">{{title}}</h4>
    <span id="close">
    <img class="img-fluid close-icon cursor-pointer d-md-block d-none" src="/assets/images/icons/Cross.svg" alt="" (click)="bsModalRef.hide()">
  </span>
  </div>
  <div class="action--form">
    <div class="form-details {{customClass}}">
      <img class="img-fluid confirm-image" *ngIf="showImage" [src]="image">
      <h2 class="header-title text-center fw-bold" *ngIf="headerTitle">{{headerTitle}}</h2>
      <h6 class="fw-medium subtitle text-center mb-4" *ngIf="headerSubTitle">{{headerSubTitle}}</h6>

      <button class="btn-confirm me-3" (click)="submitForm()">{{confirmText}}</button>
      <button class="btn-cancel" (click)="!emitOnClose ? bsModalRef.hide() : submitForm(false)">{{closeText}}</button>
    </div>
  </div>
</div>
