<div class="contact pb-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-between align-items-center">
          <div class="img-wrapper">
            <img class="img-fluid" src="/assets/images/icons/back.svg" *ngIf="isMobile" alt=""/>
          </div>
        </div>
      </div>
      <div class="col-xl-12">
        <div class="position-relative mt-5 ">
          <div class="close-icon-btn" id="close" *ngIf="appConfig.type == 'vendor'">
            <img class="img-fluid close-icon cursor-pointer d-md-block d-none" src="/assets/images/icons/Cross.svg"
                 alt="" (click)="bsModalRef.hide()">
          </div>
          <div class="contact-title d-flex align-items-center">
            <p class="fs-16 d-lg-block d-none" *ngIf="appConfig.type == 'user'">KWOT<span>/CONTACT</span></p>
            <h1 class="fs-32 font-bold w-100 text-center">Contact</h1>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <form (ngSubmit)="!contactForm.invalid && onSubmitContact()" [formGroup]="contactForm">
          <div class="contact-form">
            <div class="title">
              <h2 class="fs-32 font-bold">How may we help you?</h2>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="m-0 fs-12 fw-bold">Name *</label>
                <ng-container
                  *ngIf="(form.partner_name.touched || form.partner_name.dirty) && form.partner_name.errors">
                  <label *ngIf="form.partner_name.errors.required"
                         class="text-danger mb-0 me-2 fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'name'} }}</label>
                </ng-container>
              </div>
              <input type="text" class="form-control" placeholder="Type your name" formControlName="partner_name"
                     inputRef/>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="m-0 fs-12 fw-bold">Email *</label>
                <ng-container
                  *ngIf="(form.partner_email.touched || form.partner_email.dirty) && form.partner_email.errors">
                  <label *ngIf="form.partner_email.errors.required" class="text-danger mb-0 me-2 fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'email' | translate} }}</label>
                  <label *ngIf="form.partner_email.errors.pattern" class="text-danger mb-0 me-2 fs-10 fw-bold">
                    {{ 'errors.enter_valid' | translate: {field: 'email_address' | translate} }}</label>
                </ng-container>
              </div>
              <input inputRef type="email" class="form-control" formControlName="partner_email"
                     placeholder="Type your email"/>
            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="mb-0 ms-2 fs-12 fw-bold">{{'mobile_number' | translate}}</label>
                <ng-container
                  *ngIf="form.partner_phone.dirty && form.partner_phone.touched && form.partner_phone.errors">
                  <label *ngIf="form.partner_phone.errors.validatePhoneNumber"
                         class="text-danger mb-0 ms-2 fs-10 fw-bold">
                    {{ 'errors.invalid' | translate: {field: 'mobile_number' | translate | lowercase} }}</label>
                </ng-container>
              </div>
              <ngx-intl-tel-input
                class="phone-input-control form-control w-100 p-0"
                cssClass="phone-input form-control border-0"
                formControlName="partner_phone"
                [enablePlaceholder]="true"
                customPlaceholder="000 000 000"
                [phoneValidation]="true"
                [numberFormat]="PhoneNumberFormat.International"
                [enableAutoCountrySelect]="true"
                [searchCountryFlag]="true"
                [separateDialCode]="true"
                [selectFirstCountry]="false"
                [selectedCountryISO]="selectedPhoneCountry"
                [maxLength]="18"
                name="partner_phone">
              </ngx-intl-tel-input>
            </div>

            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="m-0 fs-12 fw-bold">Contact reason *</label>
                <ng-container
                  *ngIf="(form.priority.touched || form.priority.dirty) && form.priority.errors">
                  <label *ngIf="form.priority.errors.required" class="text-danger mb-0 me-2 fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'Contact reason'} }}</label>
                </ng-container>
              </div>
              <kwot-custom-select
                class="contact-reason"
                [searchable]="true"
                [selectOptions]="contactUs"
                selectedValue="priority"
                [formGroup]="contactForm"
                (optionSelected)="reasonData($event)"
                placeholder="{{'Contact reason' | translate}}">
              </kwot-custom-select>

            </div>
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="m-0 fs-12 fw-bold">Message *</label>
                <ng-container
                  *ngIf="(form.description.touched || form.description.dirty) && form.description.errors">
                  <label *ngIf="form.description.errors.required" class="text-danger mb-0 me-2 fs-10 fw-bold">
                    {{ 'errors.is_required' | translate: {field: 'Description'} }}</label>
                </ng-container>
              </div>
              <textarea inputRef class="form-control" placeholder="Write your message here" rows="5"
                        formControlName="description"></textarea>
            </div>
            <div class="send-btn">
              <button class="btn-action"
                      [ngClass]="{'btn-disabled': contactForm.invalid, 'btn-action': !contactForm.invalid}"
                      type="submit" *ngIf="!isMobile">Send
              </button>
            </div>
            <div class="send-btn">
              <button class="btn-action px-3"
                      [ngClass]="{'btn-disabled': contactForm.invalid, 'btn-action': !contactForm.invalid}"
                      type="submit" *ngIf="isMobile">Send
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-5 ms-auto">
        <div class="contact-img">
          <div class="auth-img text-end">
            <img class="img-fluid" src="assets/images/bg/auth-Bg.png" alt=""/>
          </div>
          <div class="kwot-logo h-100 w-100 d-flex align-items-center justify-content-center">
            <img
              class="cursor-pointer img-fluid"
              src="assets/images/Logo.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
