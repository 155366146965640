import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {map} from 'rxjs';
import {AuthState, getLoggedInUser} from "@kwot/auth";
import {select, Store} from "@ngrx/store";
import {LocalstorageService} from "@kwot/app-config";

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

  isVerifyEmailRoute = false;

  constructor(
    private router: Router,
    private authStore: Store<AuthState>,
    private localStorageService: LocalstorageService
  ) {
    const url = window.location.href;
    if (url.includes('verify-email') || url.includes('email-verification')) {
      this.isVerifyEmailRoute = true;
    }
  }

  canActivate() {
    return this.authStore.pipe(select(getLoggedInUser)).pipe(
      map(currentUser => {
        const commonData = this.localStorageService.updateCommonKey('get');
        let allowRedirect = true;
        if (commonData) {
          allowRedirect = commonData.redirect;
        }
        if (currentUser && !this.isVerifyEmailRoute && allowRedirect) {
          if (Number(currentUser.profileStatus) === 7) {
            this.router.navigate(['/vendor', 'dashboard']);
          } else {
            this.router.navigate(['/vendor', 'store-setup']);
          }
          return false;
        }
        return true;
      })
    )
  }

}
