import {Pipe, PipeTransform} from '@angular/core';
import {TreeviewItem} from "ngx-treeview";

@Pipe({
  name: 'convertToTree'
})
export class ConvertToTreePipe implements PipeTransform {

  transform(items: any[], textField: string): TreeviewItem[] {
    if (!items) {
      return undefined;
    }

    return items.map(object => {
      return new TreeviewItem({
        text: object[textField],
        value: object.value,
        ...(object.hasOwnProperty('children') && object.children.length > 0 ? {
          children: object.children
        } : {}),
      })
    });
  }
}
