import {Component, EventEmitter, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'kwot-create-wishlist',
  templateUrl: './create-wishlist.component.html',
  styleUrls: ['./create-wishlist.component.scss']
})
export class CreateWishlist implements OnInit {

  title: string;
  headerTitle: string;
  headerSubTitle: string;
  item:string;
  showImage: boolean = true;
  image: string = '/assets/images/confirm-background.png';
  confirmText: string;
  closeText: string;
  customClass: string;
  wishListForm: UntypedFormGroup

  public closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private translateService: TranslateService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.title = this.translateService.instant('confirmation');
    this.confirmText = this.translateService.instant('confirm');
    this.closeText = this.translateService.instant('cancel');
    this.wishListForm = this.formBuilder.group({
      wishListName: ['', Validators.required]
    });
  }

  ngOnInit() {
  }

  get form() {
    return this.wishListForm.controls;
  }

  submitForm() {
    if(this.wishListForm.invalid) {
      return;
    }
    this.closeEvent.emit(this.wishListForm.value.wishListName);
    this.bsModalRef.hide();
  }

}
