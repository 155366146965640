import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {RouterModule, Routes} from '@angular/router';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  APP_CONFIG,
  HttpAuthInterceptService,
  ToastComponent,
} from '@kwot/app-config';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ToastrModule} from 'ngx-toastr';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import {
  NoAuthGuard,
  VendorSharedModule,
} from '@kwot/vendor-shared';
import {SharedModule} from "@kwot/shared";

const routes: Routes = [
  {path: '', redirectTo: 'vendor/dashboard', pathMatch: 'full'},
  {
    path: 'vendor',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@kwot/auth').then((module) => {
            module.AuthModule.environment = environment;
            return module.AuthModule;
          }),
        canActivate: [NoAuthGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('@kwot/vendor-shared').then((module) => module.VendorSharedModule)
      },
    ],
  },
];

@NgModule({
  declarations: [AppComponent, ToastComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    VendorSharedModule,
    SharedModule,
    ToastrModule.forRoot({
      closeButton: true,
      maxOpened: 2,
      autoDismiss: true,
      preventDuplicates: true,
      toastComponent: ToastComponent,
      toastClass: 'ngx-toastr custom-toast',
    }),
    RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'}),
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    ...(environment.gaTag
      ? [
        NgxGoogleAnalyticsModule.forRoot(environment.gaTag),
        NgxGoogleAnalyticsRouterModule,
      ]
      : []),
  ],
  providers: [
    {provide: APP_CONFIG, useValue: environment},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
