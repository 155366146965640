<div class="modal-body">
  <div class="d-flex align-items-center justify-content-center mb-3">
    <h4 class="fw-bold fs-18 mb-0">Resize Image</h4>
  </div>
  <div class="action--form">
    <div class="form-details">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [resizeToWidth]="resizeWidth"
        [resizeToHeight]="resizeHeight"
        [cropperStaticWidth]="resizeWidth"
        [cropperStaticHeight]="resizeHeight"
        [cropperMinWidth]="resizeWidth"
        [cropperMinHeight]="100"
        [cropperMaxWidth]="resizeWidth"
        [cropperMaxHeight]="resizeHeight"
        format="png"
        (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()"
        (loadImageFailed)="loadImageFailed()"
      ></image-cropper>
      <button class="btn-confirm" (click)="submitForm()">Confirm</button>
    </div>
  </div>
</div>
