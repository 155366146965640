import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {APP_CONFIG} from "@kwot/app-config";

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  ApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private environment: any
  ) {
    this.ApiUrl = `${environment.apiUrl}/${environment.type}`;
  }

  updateStoreProfile(body: any, query?: any) {
    return this.http.put(`${this.ApiUrl}/profileSetUp`, body, {params: query});
  }

  getAllCategories(params: any) {
    return this.http.get(`${this.ApiUrl}/getAllCategory`, {params});
  }

  getVendorDetails(id: string) {
    return this.http.get(`${this.ApiUrl}/getProfile`, {params: {id}});
  }

  checkStoreNameAvailability(storeName: string) {
    return this.http.get(`${this.ApiUrl}/checkStoreName`, {params: {storeName}});
  }

  getVendorBusinessDetails() {
    return this.http.get(`${this.ApiUrl}/getBuisnessDetails`);
  }

  getVendorBankDetails() {
    return this.http.get(`${this.ApiUrl}/getBankDetails`);
  }

  getVendorWalletBalance() {
    return this.http.get(`${this.ApiUrl}/getWallet`);
  }
  getWalletTransaction(params: any) {
    return this.http.get(`${this.ApiUrl}/getWalletTransaction`, {params});
  }

  addWalletAmount(body: any) {
    return this.http.post(`${this.ApiUrl}/addWalletAmount`, body);
  }

  getVendorAllProducts(params: any) {
    return this.http.get(`${this.ApiUrl}/getAllProduct`, {params});
  }

  deleteVendorProduct(id: string) {
    return this.http.delete(`${this.ApiUrl}/deleteProduct`, {params: {id}});
  }

  getVendorProduct(id: string) {
    return this.http.get(`${this.ApiUrl}/getProductById`, {params: {id}});
  }

  resendProfileVerification(params: any) {
    return this.http.post(`${this.ApiUrl}/sendProfileVerification`, params);
  }

  updateProduct(product: any) {
    return this.http.put(`${this.ApiUrl}/editProduct`, product);
  }

  getAllOrders(params: any) {
    return this.http.get(`${this.ApiUrl}/getAllOrder`, {params});
  }

  getVendorDashboard(params: any) {
    return this.http.get(`${this.ApiUrl}/dashboard`, {params});
  }

  orderManagement(body: any) {
    return this.http.put(`${this.ApiUrl}/orderManagement`, body);
  }

  getProductReviews(params: any) {
    return this.http.get(`${this.ApiUrl}/getAllReviews`, {params});
  }

  getMyReviews(params: any) {
    return this.http.get(`${this.ApiUrl}/getMyReviews`, {params});
  }

  addPayoutData(body: any) {
    return this.http.post(`${this.ApiUrl}/addPayoutData`, body);
  }

  getPayoutDetail() {
    return this.http.get(`${this.ApiUrl}/getPayout`);
  }

  getPolicyData(params: any) {
    return this.http.get(`${this.ApiUrl}/getPolicyData`, {params});
  }

  updatePolicyData(params: any) {
    return this.http.get(`${this.ApiUrl}/updatePolicyData`, {params});
  }
}
