<div class="product-view">
  <div class="product-view-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 d-flex justify-content-between align-items-center">
          <div class="back-button fs-16 d-flex align-items-center">
            <div class="d-flex align-items-center" (click)="redirectToPrev()">
              <img src="/assets/images/icons/back.svg" class="cursor-pointer ps-0"/>
              <p class="title-back mb-0 fs-16 cursor-pointer">{{'back' | translate}}</p>
            </div>
          </div>
          <div>
            <button *ngIf="product?.rejectStatus === 'REJECTED'" class="btn-action px-4 py-2 cursor-pointer me-2"
                    type="button"
                    (click)="submitForApproval()">
              {{'submit_for_approval' | translate}}
            </button>
            <button class="btn-action px-4 py-2 cursor-pointer" type="button" (click)="showActions(manageModel)">
              {{'manage' | translate}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="product-view-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-5 col-lg-6 px-md-3 px-0">
          <div class="product-details-bg">
            <div class="bg-img-wrapper">
              <img src="/assets/images/pending-order.svg" class="cursor-pointer img-fluid"/>
            </div>
            <div class="product-view-details d-flex">
              <div class="img-wrapper me-3">
                <img [lazyLoad]="product ? getImage(product, 'small') : ''" defaultImage="/assets/images/Photo.jpg"
                     class="cursor-pointer img-fluid"/>
              </div>
              <div class="content">
                <p class="fs-16 mb-0">
                  {{product?.sku}}
                </p>
                <h3 class="fs-24">
                  {{product?.productName}}
                </h3>
                <h2 class="fs-24">
                  {{product?.price | currencyConvert | async}}
                </h2>
                <p class="fs-16 ">
                  {{product?.quantity}} {{'in_stock' | translate}}
                </p>
                <div class="d-flex">
                  <div class="btn-white cursor-pointer fw-bold">
                    {{product?.categoryId?.name}}
                  </div>
                  <span class="status ms-3 fs-12 fw-bold {{(product?.isActive ? 'active': 'inactive')}}">
                    <span class="badge"></span>{{product?.isActive ? ('active' | translate) : ('inactive' | translate)}}
                  </span>
                </div>
                <div class="d-flex mt-3">
                  <span class="status fs-12 fw-bold {{(product?.isReturnAccepted ? 'return': 'inactive')}}">
                    <span
                      class="badge"></span>{{product?.isReturnAccepted ? ('return_accepted' | translate) : ('no_return' | translate)}}
                  </span>
                </div>
                <div *ngIf="product?.rejectStatus">
                  <h3 class="fs-24 mt-3">
                    Rejected Product
                  </h3>
                  <div class="d-flex mt-3" *ngIf="product?.rejectReason">
                    <span class="status fs-12 fw-bold">Reason: {{product?.rejectReason}}</span>
                  </div>
                  <div class="d-flex mt-3" *ngIf="product?.rejectMessage">
                  </div>
                  <span class="status fs-12 fw-bold">Message: {{product?.rejectMessage}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 ms-auto mt-lg-0 mt-3">
          <div class="product-description">
            <h5 class="fs-24 fw-bold text-black">{{'description' | translate}}</h5>
            <p class="fs-16 ">{{product?.description || ''}}</p>
            <div class="price-range me-3" *ngFor="let range of discountPrice">
              <p class="m-0" *ngIf="range.isFixed">{{range.from + '-' + range.to}} Pieces</p>
              <p class="m-0" *ngIf="!range.isFixed">{{'>' + range.from}}</p>
              <span>{{range.price | currencyConvert | async}}</span>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="product-view-tabs mt-5">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="product-media" data-bs-toggle="tab" data-bs-target="#media"
                        type="button" role="tab" aria-controls="media" aria-selected="true">{{'media' | translate}}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="product-color" data-bs-toggle="tab" data-bs-target="#color" type="button"
                        role="tab" aria-controls="color" aria-selected="false">{{'attributes' | translate}}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="product-variations" (click)="adjustColumn()" data-bs-toggle="tab"
                        data-bs-target="#variations"
                        type="button" role="tab" aria-controls="variations"
                        aria-selected="false">{{'variations' | translate}}
                </button>
              </li>
              <li class="nav-item" role="presentation" *ngIf="appConfig.applicationType === 'b2b'">
                <button class="nav-link" id="product-volumeDiscount" (click)="adjustColumn()" data-bs-toggle="tab"
                        data-bs-target="#volumeDiscount"
                        type="button" role="tab" aria-controls="volumeDiscount"
                        aria-selected="false">{{'Volume Discount' | translate}}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="product-inventory" (click)="adjustColumn()" data-bs-toggle="tab"
                        data-bs-target="#inventory"
                        type="button" role="tab" aria-controls="inventory"
                        aria-selected="false">{{'inventory_pricing' | translate}}
                </button>
              </li>
              <li class="nav-item" role="presentation" *ngIf="appConfig.applicationType !== 'b2b'">
                <button class="nav-link" id="product-personalization" data-bs-toggle="tab"
                        data-bs-target="#personalization" type="button" role="tab" aria-controls="personalization"
                        aria-selected="false">{{'personalisation' | translate}}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="product-shipping" data-bs-toggle="tab" data-bs-target="#shipping"
                        type="button" role="tab" aria-controls="shipping"
                        aria-selected="false">{{'shipping' | translate}}
                </button>
              </li>
            </ul>
            <div class="tab-content mt-5" id="myTabContent">
              <div class="tab-pane fade show active" id="media" role="tabpanel" aria-labelledby="product-media">
                <div class="col-12">
                  <div class="details">
                    <div class="title">
                      <h3 class="fs-24 fw-bold mb-3">
                        {{'extra_pictures' | translate}}
                      </h3>
                    </div>
                    <div class="view-images d-flex flex-wrap" *ngIf="product?.extraImages.length > 0">
                      <div class="img-wrapper" *ngFor="let image of product?.extraImages">
                        <img [lazyLoad]="getExtraImage(image, 'small')" defaultImage="/assets/images/Photo.jpg"
                             class="cursor-pointer img-fluid"/>
                      </div>
                    </div>
                    <div class="view-images" *ngIf="product?.extraImages.length <= 0">
                      <div class="no-images">
                        {{'no_extra_image' | translate}}
                      </div>
                    </div>
                  </div>
                  <div class="details mt-5">
                    <div class="title">
                      <h3 class="fs-24 fw-bold mb-3">{{'videos' | translate}}</h3>
                    </div>
                    <div class="view-images d-flex flex-wrap" *ngIf="product?.videos">
                      <video [src]="product?.videos" controls class="img-fluid cursor-pointer"></video>
                    </div>
                    <div class="view-images" *ngIf="!product?.videos">
                      <div class="no-images">
                        {{'no_video' | translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="color" role="tabpanel" aria-labelledby="product-color">
                <div class="col-lg-6">
                  <div class="details d-flex flex-wrap">
                    <ng-container *ngIf="product?.dynamicKey?.length > 0">
                      <ng-container *ngFor="let dynamic of product.dynamicKey; let i = index">
                        <ng-container *ngIf="dynamic.key && dynamic.key.isSelected">
                          <div class="color-palate me-3 mb-3">
                            <h6 class="color-text mb-0 fs-14">
                              {{dynamic.key.key | pascalCase}}: <span> {{dynamic.value || '-'}} </span>
                            </h6>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <div class="color-palate me-3 mb-3" *ngIf="selectedCategory?.pattern?.isSelected">
                      <h6 class="color-text mb-0 fs-14">
                        {{'pattern' | translate}}: <span> {{product?.pattern}} </span>
                      </h6>
                    </div>
                    <div class="color-palate me-3 mb-3" *ngIf="selectedCategory?.dimension?.isSelected">
                      <h6 class="color-text mb-0 fs-14">
                        {{'dimensions' | translate}}:
                        <span> {{product?.dimension?.length}}{{product?.dimension?.metrics}}
                          (L) * {{product?.dimension?.width}}{{product?.dimension?.metrics}}
                          (W) * {{product?.dimension?.height}}{{product?.dimension?.metrics}}
                          (H) </span>
                      </h6>
                    </div>
                    <div class="color-palate me-3 mb-3" *ngIf="selectedCategory?.weight?.isSelected">
                      <h6 class="color-text mb-0 fs-14">
                        {{'weight' | translate}} <span class="d-inline-block fs-16"> (kg) </span> :
                        <span> {{product?.weight}} </span>
                      </h6>
                    </div>
                    <div class="color-palate me-3 mb-3" *ngIf="selectedCategory?.size?.isSelected">
                      <h6 class="color-text mb-0 fs-14">
                        {{'size' | translate}} : <span> {{product?.size}} </span>
                      </h6>
                    </div>
                  </div>
                  <div class="details mt-4"
                       *ngIf="(selectedCategory?.primaryColour?.isSelected || selectedCategory?.secondaryColour?.isSelected)">
                    <div class="title">
                      <h3 class="fs-24 fw-bold mb-3">
                        {{'color' | translate}}
                      </h3>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-6 p-0" *ngIf="selectedCategory?.primaryColour?.isSelected">
                        <div class="color-palate me-2">
                          <h6 class="color-text mb-3 fs-14">
                            {{'primary_color' | translate}}:
                          </h6>
                          <span class="d-flex align-items-center colors">
                              <ng-container
                                *ngFor="let item of product?.primaryColour; let i = index">
                                <div class="d-flex align-items-center">
                                  <span [ngStyle]="{backgroundColor: item.hex}"
                                        class="color-span me-2"
                                        [ngClass]="{'ms-1': i !== 0}"></span>
                                  {{item.name}}{{i !== product?.primaryColour.length - 1 ? ',' : ''}}
                                </div>
                              </ng-container>
                            </span>
                        </div>
                      </div>
                      <div class="col-sm-6 p-0" *ngIf="selectedCategory?.secondaryColour?.isSelected">
                        <div class="color-palate me-2">
                          <h6 class="color-text mb-3 fs-14">
                            {{'primary_color' | translate}}:
                          </h6>
                          <span class="d-flex align-items-center colors">
                              <ng-container
                                *ngFor="let item of product?.secondaryColour; let i = index">
                                <div class="mb-3 d-flex align-items-center">
                                  <span [ngStyle]="{backgroundColor: item.hex}"
                                        class="color-span me-2"
                                        [ngClass]="{'ms-1': i !== 0}"></span>
                                  {{item.name}}{{i !== product?.secondaryColour.length - 1 ? ',' : ''}}
                                </div>
                              </ng-container>
                            </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="details mt-4"
                       *ngIf="(selectedCategory?.primaryMaterial?.isSelected || selectedCategory?.secondaryMaterial?.isSelected)">
                    <div class="title">
                      <h3 class="fs-24 fw-bold mb-3">
                        {{'material' | translate}}
                      </h3>
                    </div>
                    <div class="row mx-0">
                      <div class="col-sm-6 p-0 mb-sm-0 mb-3" *ngIf="selectedCategory?.primaryMaterial?.isSelected">
                        <div class="color-palate me-2">
                          <h6 class="color-text mb-3 fs-14">
                            {{'primary_material' | translate}}:
                          </h6>
                          <span class="d-flex align-items-center colors">
                            <ng-container *ngFor="let item of product?.primaryMaterial; let i = index">
                              <div class="mb-3 d-flex align-items-center">
                                <img [lazyLoad]="item.image" [defaultImage]="'/assets/images/add-photo.svg'"
                                     class="color-image me-2" [ngClass]="{'ms-1': i !== 0}"/>
                                {{item.name}}{{i !== product?.primaryMaterial.length - 1 ? ',' : ''}}
                              </div>
                            </ng-container>
                          </span>
                        </div>
                      </div>
                      <div class="col-sm-6 p-0" *ngIf="selectedCategory?.secondaryMaterial?.isSelected">
                        <div class="color-palate me-2">
                          <h6 class="color-text mb-3 fs-14">
                            {{'secondary_material' | translate}}:
                          </h6>
                          <span class="d-flex align-items-center colors">
                          <ng-container *ngFor="let item of product?.secondaryMaterial; let i = index">
                            <div class="mb-3 d-flex align-items-center">
                              <img [lazyLoad]="item.image" [defaultImage]="'/assets/images/add-photo.svg'"
                                   class="color-image me-2" [ngClass]="{'ms-1': i !== 0}"/>
                              {{item.name}}{{i !== product?.secondaryMaterial.length - 1 ? ',' : ''}}
                            </div>
                          </ng-container>
                        </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="variations" role="tabpanel" aria-labelledby="product-variations">
                <ng-container *ngIf="product?.variant.length <= 0">
                  <div class="col-md-5 mx-auto">
                    <div class="no-content text-center">
                      <div class="img-wrapper">
                        <img src="/assets/images/no-product-img.png" class="cursor-pointer img-fluid"/>
                      </div>
                      <h2 class="fs-32 fw-bold">
                        {{'no_data_for_product' | translate: {data: 'variations' | translate | lowercase} }}
                      </h2>
                      <p class="fs-16">
                        {{'press_manage_to_add_field' | translate:{field: 'variations' | translate | lowercase} }}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="product?.variant.length > 0">
                  <div class="custom-table">
                    <kwot-custom-table
                      #customTableComponent
                      [customOptions]="customDatatableSettings"
                      [tableHead]="tableHead" [tableBody]="tableBody">
                    </kwot-custom-table>
                    <ng-template #tableHead>
                      <thead>
                      <tr>
                        <td *ngFor="let header of tableHeaders">
                          <p
                            class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                            {{header}}
                          </p>
                        </td>
                      </tr>
                      </thead>
                    </ng-template>
                    <ng-template #tableBody>
                      <tbody *ngIf="tableList.length <= 0">
                      <tr>
                        <td [attr.colspan]="tableHeaders.length">{{'no_data_found' | translate}}</td>
                      </tr>
                      </tbody>
                      <tbody *ngIf="tableList.length > 0">
                      <tr *ngFor="let variation of tableList; let i = index">
                        <td *ngFor="let item of valueKeys">
                          <ng-container
                            *ngIf="item === 'primaryColour' || item === 'secondaryColour' || item === 'primaryMaterial' || item === 'secondaryMaterial'; else defaultView">
                            <ng-container *ngIf="item === 'primaryColour' || item === 'secondaryColour'">
                              <p class="mb-0 fs-16 cursor-pointer store-content-details">
                                  <span
                                    [style]="{backgroundColor: variation[item]?.hex}"></span> {{variation[item]?.name}}
                              </p>
                            </ng-container>
                            <ng-container *ngIf="item === 'primaryMaterial' || item === 'secondaryMaterial'">
                              <p class="mb-0 fs-16 cursor-pointer store-content-details"
                                 *ngIf="variation && variation[item]; else noData">
                                <img [lazyLoad]="variation[item].image"
                                     [defaultImage]="'/assets/images/add-photo.svg'" class="search-icon me-2" alt="">
                                {{variation[item].name}}
                              </p>
                              <ng-template #noData>-</ng-template>
                            </ng-container>
                          </ng-container>
                          <ng-template #defaultView>
                            <p class="mb-0 fs-16 cursor-pointer store-content-details">
                              {{variation[item]}}
                            </p>
                          </ng-template>
                        </td>
                        <td *ngIf="variationsOptions.varyPrice">
                          <div class="mb-0 fs-16 cursor-pointer store-content-details">
                            {{variation?.price | currencyConvert | async}}
                          </div>
                        </td>
                        <td *ngIf="variationsOptions.varyQuantity">
                          <p class="mb-0 fs-16 cursor-pointer store-content-details">
                            {{variation?.qtyAvailable}}
                          </p>
                        </td>
                      </tr>
                      </tbody>
                    </ng-template>
                  </div>
                </ng-container>
              </div>
              <div class="tab-pane fade" id="volumeDiscount" role="tabpanel" aria-labelledby="product-volumeDiscount">
                <div class="test-range px-0">
                  <div class="test-header">
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-title">
                          <h6 class="fs-14 fw-bold m-0">From</h6>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-title">
                          <h6 class="fs-14 fw-bold m-0">To</h6>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-title">
                          <h6 class="fs-14 fw-bold m-0">Discount</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="test-body">
                    <ng-container *ngFor="let variation of priceRange">
                      <div class="row align-items-center mb-3" *ngFor="let item of variation.priceRange">
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group position-relative" *ngIf="item.isFixed">
                            {{item.from}}
                          </div>
                          <div class="form-group position-relative" *ngIf="!item.isFixed">
                            {{'>' + item.from}}
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group position-relative">
                            {{item.to || '-'}}
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                          <div class="form-group position-relative">
                            {{item.discount + '%'}}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="inventory" role="tabpanel" aria-labelledby="product-inventory">
                <div class="row">
                  <div class="col-lg-4">
                    <div class="basic-price">
                      <p class="fs-24 fw-bold text-black m-0">{{'Base Price'}}</p>
                      <span>{{product?.price | currencyConvert | async}}</span>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="units">
                      <p class="fs-24 fw-bold text-black m-0">{{'Units'}}</p>
                      <span>{{product?.qtyAvailable}}</span>
                    </div>
                  </div>
                </div>
                <div class="test-range px-0 mt-4" *ngIf="appConfig.applicationType === 'b2b'">
                  <div class="test-header">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-title">
                          <h6 class="fs-14 fw-bold m-0">From</h6>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-title">
                          <h6 class="fs-14 fw-bold m-0">To</h6>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-title">
                          <h6 class="fs-14 fw-bold m-0">Discount</h6>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-title">
                          <h6 class="fs-14 fw-bold m-0">Price with discount</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="test-body">
                    <ng-container *ngFor="let range of discountPrice">
                      <div class="row align-items-center mb-3">
                        <div class="col-lg-3 col-md-6">
                          <div class="form-group position-relative" *ngIf="range.isFixed">
                            {{range.from}}
                          </div>
                          <div class="form-group position-relative" *ngIf="!range.isFixed">
                            {{'>' + range.from}}
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                          <div class="form-group position-relative">
                            {{range.to || '-'}}
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                          <div class="form-group position-relative">
                            {{range.discount + '%'}}
                          </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                          <div class="form-group position-relative">
                            {{range.price | currencyConvert | async}}
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="personalization" role="tabpanel"
                   aria-labelledby="product-personalization">
                <div class="col-lg-5 col-md-6 col-sm-8 mx-auto" *ngIf="!product?.isPersonalizationEnabled">
                  <div class="no-content text-center">
                    <div class="img-wrapper mx-auto">
                      <img src="/assets/images/no-product-img.png" class="cursor-pointer  img-fluid"/>
                    </div>
                    <h2 class="fs-32 fw-bold">
                      {{'no_data_for_product' | translate: {data: 'personalization' | translate | lowercase} }}
                    </h2>
                    <p class="fs-16">
                      {{'press_manage_to_add_field' | translate:{field: 'personalization' | translate | lowercase} }}
                    </p>
                  </div>
                </div>
                <div class="col-md-4" *ngIf="product?.isPersonalizationEnabled">
                  <div class="shipping-details">
                    <h2 class="fs-24">{{'personalized_text' | translate}}</h2>
                    <p class="fs-16 mb-0">{{product?.message}}</p>
                  </div>
                  <div class="shipping-details mt-4">
                    <h2 class="fs-24">{{'character_limit' | translate}}</h2>
                    <p class="fs-16 mb-0">{{product?.messageCharLimit}}</p>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="shipping" role="tabpanel" aria-labelledby="product-shipping">
                <div class="col-xl-4 col-md-6 col-sm-8">
                  <div class="details">
                    <div class="color-palate me-3 mb-3" *ngIf="product?.isFreeShipping">
                      <h6 class="color-text mb-0 fs-14">{{'free_shipping' | translate}}</h6>
                    </div>
                    <!--                    <div class="color-palate me-3 mb-3" *ngIf="!product?.isFreeShipping">-->
                    <!--                      <h6 class="color-text mb-0 fs-14">-->
                    <!--                        {{'shipping_cost' | translate}}:-->
                    <!--                        <span> {{product?.shippingCost | currencyConvert | async}} </span>-->
                    <!--                      </h6>-->
                    <!--                    </div>-->
                  </div>
                  <div class="shipping-details mt-4">
                    <h2 class="fs-24">{{'origin_location' | translate}}</h2>
                    <p class="fs-16 address mb-0">
                      {{product?.addressId?.streetName}}, {{product?.addressId?.streetNumber}}
                      , {{product?.addressId?.other}}, {{product?.addressId?.city}},
                      <br> {{product?.addressId?.state}}, {{product?.addressId?.country}}
                      , {{product?.addressId?.postalCode}}
                    </p>
                  </div>
                  <div class="policy mt-4">
                    <h6 class="d-flex align-items-center fs-16">
                      <img src="/assets/images/icons/copy.svg" class="cursor-pointer img-fluid me-4"/>
                      {{'shipping_and_taxes_policy' | translate}}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <!-- Modal -->
  <ng-template #manageModel>
    <div class="modal-header">
      <h5 class="modal-title text-center fs-16 fw-bold text-black"
          id="staticBackdropLabel">{{'actions' | translate}}</h5>
      <div class="img-wrapper">
        <img src="/assets/images/icons/Cross.svg" (click)="modalRef?.hide()" class="cursor-pointer img-fluid"/>
      </div>
    </div>
    <div class="modal-body">
      <div class="action-view" (click)="modalRef?.hide()" [routerLink]="['../../view-product', product?._id]">
        <div class="img-wrapper">
          <img src="/assets/images/icons/edit-eye.svg" (click)="modalRef?.hide()" class="cursor-pointer img-fluid"/>
        </div>
        <h6 class="mb-0">
          {{'view_on_kwot' | translate}}
        </h6>
      </div>
      <div class="action-view mt-3" (click)="modalRef?.hide()" [routerLink]="['../../edit-product', product?._id]">
        <div class="img-wrapper">
          <img src="/assets/images/icons/edit-white.svg" (click)="modalRef?.hide()" class="cursor-pointer img-fluid"/>
        </div>
        <h6 class="mb-0">
          {{'edit' | translate}}
        </h6>
      </div>
      <div class="action-view mt-3" (click)="modalRef?.hide()" [routerLink]="['../../copy-product', product?._id]">
        <div class="img-wrapper">
          <img src="/assets/images/icons/copy-icon-white.svg" (click)="modalRef?.hide()"
               class="cursor-pointer img-fluid"/>
        </div>
        <h6 class="mb-0">
          {{'duplicate' | translate}}
        </h6>
      </div>
      <div class="action-view justify-content-between mt-3">
        <h6 class="mb-0">
          {{'active' | translate}}
        </h6>
        <div>
          <label class="action-switch-green-btn me-2 m-0" (click)="changeProductStatus($event, product)">
            <input type="checkbox" [checked]="product?.isActive">
            <span class="slider round"></span>
          </label>
        </div>
      </div>
      <button (click)="deleteProduct(product)"
              class="btn-transparent d-flex justify-content-center align-items-center w-100 py-3 px-4 cursor-pointer mt-2">
        <div class="img-wrapper me-2">
          <img src="/assets/images/icons/Delete-icon.svg" class="cursor-pointer img-fluid"/>
        </div>
        {{'remove_product' | translate}}
      </button>
    </div>
  </ng-template>
</div>
