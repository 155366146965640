<div class="modal-body">
  <div class="d-flex align-items-center justify-content-center mb-3">
    <h4 class="fw-bold fs-18 mb-0">Policy updates</h4>
  </div>
  <div class="action--form">
    <div class="form-details">
      <ng-container *ngFor="let policy of policies">
        <h2 class="header-title fw-bold fs-18 mb-1">We have updated our {{policy.policyName}}</h2>
        <p class="fw-medium subtitle mb-4 fs-14">
          Click here to read the new
          <span class="cursor-pointer" *ngIf="policy.terms === 'sellerTerms' || policy.terms === 'buyerTerms'"
                (click)="showPrivacyTerms('terms', policy.terms)">{{policy.policyName}}</span>
          <span class="cursor-pointer" *ngIf="policy.terms === 'sellerPolicy' || policy.terms === 'buyerPolicy'"
                (click)="showPrivacyTerms('privacy', policy.terms)">{{policy.policyName}}</span>
          <span class="cursor-pointer" *ngIf="policy.terms === 'cookiePolicy'"
                (click)="showCookies()">{{policy.policyName}}</span>
          <span class="cursor-pointer" *ngIf="policy.terms === 'paymentPolicy'"
                (click)="showPayment()">{{policy.policyName}}</span>
          <span class="cursor-pointer" *ngIf="policy.terms === 'returnPolicy'"
                (click)="showCookies('returnPolicy', 'return_policy')">{{policy.policyName}}</span>
          <span class="cursor-pointer" *ngIf="policy.terms === 'shippingPolicy'"
                (click)="showCookies('shippingPolicy', 'shopping_info')">{{policy.policyName}}</span>
        </p>
      </ng-container>
      <button class="btn-action me-3 w-25" (click)="confirmAll()">Accept All</button>
    </div>
  </div>
</div>
