<div class="modal-body">
  <div class="header-title d-flex align-items-center justify-content-center mb-3">
    <h2 class="title text-center fs-24 fw-bold" *ngIf="headerTitle">{{ headerTitle }}</h2>
    <span id="close">
      <img class="close-icon cursor-pointer" src="/assets/images/icons/Cross.svg" alt="" (click)="bsModalRef.hide()"/>
    </span>
  </div>
  <div class="product-section">
    <div class="form-details {{ customClass }}">
      <h6 class="fs-16 fw-medium subtitle text-center mb-4" *ngIf="headerSubTitle">
        {{ headerSubTitle }}
      </h6>
      <form [formGroup]="wishListForm" novalidate>
        <div class="form-group">
          <div class="d-flex justify-content-between">
            <label class="m-0 fs-12 fw-bold">Wish list name</label>
            <ng-container *ngIf="(form.wishListName.touched || form.wishListName.dirty) && form.wishListName.errors">
              <p *ngIf="form.wishListName.errors.required"
                 class="error_color error-label font-weight-bold mb-0 ml-2 ff-dmsans fs-10 ps-2">
                {{'errors.empty_wishlist_name' | translate }}
              </p>
            </ng-container>
          </div>
          <input type="name" class="form-control" formControlName="wishListName" placeholder="Enter your wish list name"
                 [value]="item"/>
        </div>
        <div  class="save-btn">
          <button (click)="submitForm()" [ngClass]="{
                    'btn-disabled': (wishListForm.invalid),
                    'btn-action': (!wishListForm.invalid)
                    }">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
