<div class="products-list">
  <div class="products-list-title">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="back d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <img src="/assets/images/icons/back.svg" class="cursor-pointer" [routerLink]="['/vendor', 'dashboard']"/>
            <p class="title-back mb-0 fs-16 cursor-pointer"
               [routerLink]="['/vendor', 'dashboard']">{{'back' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="products-list-wrapper">
    <div [ngClass]="{'d-none': reviewsList.length <= 0 && !reviewsFoundFirstTime}">
      <h2 class="fs-32 fw-bold">{{'reviews' | translate}}</h2>
      <div class="custom-table">
<!--        <div class="tag-filters">-->
<!--        <span class="tag" [ngClass]="{'active': selectedFilter === filter.value}"-->
<!--              (click)="filterProduct(filter.value)"-->
<!--              *ngFor="let filter of filters">{{filter.label}}</span>-->
<!--        </div>-->
        <kwot-custom-table
          #customTableComponent
          [customOptions]="customDatatableSettings"
          [ajaxFunction]="getAllReviews"
          [tableHead]="tableHead" [tableBody]="tableBody">
        </kwot-custom-table>
        <ng-template #tableHead>
          <thead>
          <tr>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'product' | translate}}
              </p>
            </td>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'buyer' | translate}}
              </p>
            </td>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'review' | translate}}
              </p>
            </td>
            <td></td>
          </tr>
          </thead>
        </ng-template>
        <ng-template #tableBody>
          <tbody *ngIf="reviewsList.length <= 0">
          <tr>
            <td colspan="3">{{'no_data_found' | translate}}</td>
          </tr>
          </tbody>
          <tbody *ngIf="reviewsList.length > 0">
          <tr *ngFor="let review of reviewsList">
            <td>
              <p class="mb-0 fs-16 cursor-pointer store-content-details"
                 [routerLink]="['/vendor', 'products', 'view-product', review?.product?._id]">
                <img [lazyLoad]="review?.product?.coverImage"
                     [defaultImage]="'/assets/images/Photo.jpg'" class="search-icon me-2" alt="">
                {{review?.product?.productName}}
              </p>
            </td>
            <td>
              <p class="mb-0 fs-16 cursor-pointer store-content-details">
                <img [lazyLoad]="review?.user?.image"
                     [ngStyle]="{ height: '25px', width: '25px', borderRadius: '16px'}"
                     [defaultImage]="'/assets/images/Photo.jpg'" class="search-icon me-2" alt="">
                {{review?.user?.firstName}} {{review?.user?.lastName}}
              </p>
            </td>
            <td>
              <div
                class="mb-0 fs-16 store-content-details d-flex flex-column align-items-start justify-content-start">
                <ul class="list-unstyled p-0 m-0 d-flex">
                  <li *ngFor="let stars of [].constructor(review.rating || 0)">
                    <img [ngStyle]="{width: '15px', height: '15px'}" src="assets/images/icons/star-icon.svg" alt=""/>
                  </li>
                  <li *ngFor="let stars of [].constructor(5 - (review.rating || 0))">
                    <img [ngStyle]="{width: '15px', height: '15px'}" src="assets/images/icons/star-border-icon.svg"
                         alt=""/>
                  </li>
                </ul>
                {{review.review}}
              </div>
            </td>
            <td class="action">
              <button type="button" class="btn-icon float-end" placement="bottom right"
                      containerClass="custom-popover no-arrow no-last-border"
                      [outsideClick]="true" container="body" [adaptivePosition]="false"
                      [popover]="popTemplate">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ng-template #popTemplate>
                <div class="item">
                  <a target="_blank"
                     [href]="selectedFilter === 'my_reviews' ? (appConfig?.buyerUrl + '/user/store/' + review?.vendorId) : (appConfig?.buyerUrl + '/user/product/' + review?.product?._id)">
                    <img src="/assets/images/icons/edit-eye.svg" class="icon"/>
                    <span>{{'view_on_kwot' | translate}}</span>
                  </a>
                </div>
              </ng-template>
            </td>
          </tr>
          </tbody>
        </ng-template>
      </div>
      <div [ngClass]="{'d-none': reviewsList.length > 0}">
        <div class="no-product">
          <img src="/assets/images/no-product-img.png" alt="No Products" class="img-fluid">
          <h2 class="text-center fw-bold mb-0" [innerHTML]="'no_reviews_in_your_store' | translate"></h2>
          <p class="subtitle text-center">{{'no_reviews_in_your_store_note' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
