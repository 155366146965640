<div class="finances position-relative">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="back-btn d-flex align-items-center" [routerLink]="['/vendor', 'dashboard']">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer"/>
          <p class="title-back mb-0 fs-16 cursor-pointer">{{'back' | translate}}</p>
        </div>
        <div>
          <h1 class="fs-32 fw-bold mb-3">Finances</h1>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active w-100 fw-bold" id="payout-tab" data-bs-toggle="tab" data-bs-target="#payout"
                    type="button" role="tab" aria-selected="true">Payouts
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link w-100 fw-bold" id="payout-details" data-bs-toggle="tab"
                    data-bs-target="#payoutdetails" type="button" role="tab" aria-selected="false">Payout details
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link w-100 fw-bold" id="billing-details" data-bs-toggle="tab" data-bs-target="#billing"
                    type="button" role="tab" aria-selected="false">Billing
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="payout" role="tabpanel" aria-labelledby="payout-tab">
            <div class="finances-list">
              <p><b>NOTE:</b> {{'exchange_rate_note' | translate}}</p>
              <a [routerLink]="['/vendor', 'payoutDetail']" class="btn-transparent">
                <i class="fa-solid fa-gear me-3"></i>
                {{'payment_settings' | translate }}
              </a>
              <div class="mb-2 mt-0 ms-1 text-end">
                <a class="fs-16 fw-bold policy" [routerLink]="['/vendor', 'payment-policy']"> Payment Policy </a>
              </div>
              <div class="information" id="personal" *ngIf="!this.vendorWalletDetail?.hasOwnProperty('wallet')">
                <div class="alert" role="alert">
                  <div class="d-flex">
                    <div class="img-wrapper align-self-center">
                      <img class="img-fluid" src="/assets/images/icons/Complete-info.svg"/>
                    </div>
                    <div class="mx-4">
                      <h2 class="fs-24 fw-bold">{{'complete_your_information' | translate}}</h2>
                      <p class="fs-16 mb-0">{{'information' | translate}}</p>
                    </div>
                    <div class="mx-auto justify-content-end align-self-center me-0">
                      <h6 class="fs-16 fw-bold mb-0">{{'complete_setup' | translate}}</h6>
                    </div>
                  </div>
                </div>
                <div class="keep-payouts mt-5 text-center">
                  <div class="img-wrapper keep-track text-center">
                    <img class="img-fluid" src="/assets/images/no-product-img.png"/>
                  </div>
                  <h2 class="fs-32 fw-bold pt-5">{{'keep_track of_your_payouts' | translate}}</h2>
                  <p class="fs-16 mb-0">{{"payout_transaction" | translate}}</p>
                </div>
              </div>
              <div class="wallet-balance" *ngIf="this.vendorWalletDetail?.hasOwnProperty('wallet')">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-3 text-center">
                      <h6 class="fs-14">{{'Available' | translate}}</h6>
                      <h2 class="fs-24 fw-bold">
                        {{this.vendorWalletDetail?.wallet | currencyConvert | async}}
                        <div *ngIf="this.vendorWalletDetail?.wallet !== 0">
                          <button class="btn-action p-16 w-auto" (click)="withdrawAmountModel(withdrawAmountModelData, this.vendorWalletDetail?._id)">
                            {{'withdraw' | translate}}
                          </button>
                        </div>
                      </h2>
                    </div>
                    <div class="col-md-3 text-center">
                      <h6 class="fs-14">{{'payout_pending' | translate}}</h6>
                      <h2 class="fs-24 fw-bold">
                        {{((this.vendorPayoutDetail || 0) | currencyConvert | async)}}
                      </h2>
                    </div>
                    <div class="col-md-3 text-center">
                      <h6 class="fs-14">{{'on_hold' | translate}} / <span
                        class="fs-12">{{'orders_in_process' | translate}}</span></h6>
                      <h2
                        class="fs-24 fw-bold">{{this.vendorWalletDetail?.pendingAmount | currencyConvert | async}}</h2>
                    </div>
                    <div class="col-md-3 text-center">
                      <h6 class="fs-14">{{'refund_cancellations' | translate}}</h6>
                      <h2
                        class="fs-24 fw-bold">{{this.vendorWalletDetail?.refundAmount | currencyConvert | async}}</h2>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container-fluid">
                <form [formGroup]="dateFilter">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="form-label fs-12 m-0">{{'Form' | translate}}</label>
                        <kwot-custom-select
                          class="filter-wallet-data"
                          [searchable]="true"
                          [placeholder]="'Select from month'"
                          [selectOptions]="totalMonth"
                          selectedValue="fromDate"
                          [formGroup]="dateFilter"
                          (optionSelected)="filterWalletData()"
                        >
                        </kwot-custom-select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="form-label fs-12 m-0">{{'To' | translate}}</label>
                        <kwot-custom-select
                          class="filter-wallet-data"
                          [searchable]="true"
                          [placeholder]="'Select to month'"
                          [selectOptions]="totalMonth"
                          selectedValue="toDate"
                          (optionSelected)="filterWalletData()"
                          [formGroup]="dateFilter">
                        </kwot-custom-select>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="form-label fs-12 m-0">{{'Date range' | translate}}</label>
                        <kwot-custom-select
                          class="filter-wallet-data"
                          [searchable]="true"
                          [placeholder]="'Select Date'"
                          [selectOptions]="month"
                          selectedValue="monthRange"
                          (optionSelected)="filterWalletData()"
                          [formGroup]="dateFilter">
                        </kwot-custom-select>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="container-fluid">
                <div class="row">
                  <div>
                    <div class="custom-table finances-table">
                      <kwot-custom-table
                        #customTableComponent
                        [customOptions]="customDatatableSettings"
                        [tableHead]="tableHead"
                        [ajaxFunction]="getAllWalletTransaction"
                        [tableBody]="tableBody">
                      </kwot-custom-table>
                      <ng-template #tableHead>
                        <thead>
                        <tr>
                          <td>
                            <p
                              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                              {{'Transaction' | translate}}
                            </p>
                          </td>
                          <td>
                            <p
                              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                              {{'Type' | translate}}
                            </p>
                          </td>
                          <td>
                            <p
                              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                              {{'Transacion’s date' | translate}}
                            </p>
                          </td>
                          <td>
                            <p
                              class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                              {{'Amount' | translate}}
                            </p>
                          </td>
                        </tr>
                        </thead>
                      </ng-template>
                      <ng-template #tableBody>
                        <tbody *ngIf="monthlyTransactionList.length <= 0">
                        <tr>
                          <td colspan="4">{{'no_data_found' | translate}}</td>
                        </tr>
                        </tbody>
                        <tbody *ngIf="monthlyTransactionList.length > 0">

                        <ng-container *ngFor="let transaction of monthlyTransactionList">
                          <tr *ngIf="transaction.type === 'group'; else listTemplate" class="group">
                            <td colspan="4">{{transaction.value}}</td>
                          </tr>
                          <ng-template #listTemplate>
                            <tr>
                              <td>
                                <p class="mb-0 fs-16 store-content-details">
                                  <img class="img-fluid" src="/assets/images/icons/credit-wallet.svg"
                                       *ngIf="transaction.type === 'CREDIT'"/>
                                  <img class="img-fluid" src="/assets/images/icons/debit-wallet.svg"
                                       *ngIf="transaction.type === 'DEBIT'"/>
                                  #{{transaction?.orderId?.orderNo}}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 fs-16 store-content-details">
                                  {{transaction.type | pascalCase}}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 fs-16 store-content-details">
                                  {{transaction?.createdAt | date: 'dd/MM/yyyy'}}
                                </p>
                              </td>
                              <td>
                                <p class="mb-0 fs-16 store-content-details"
                                   [ngClass]="{'debitAmount': transaction.type === 'DEBIT' , 'creditAmount' : transaction.type === 'CREDIT' }">
                                  {{transaction.type === 'DEBIT' ? '-' : ''}}
                                  {{ transaction.amount | currencyConvert | async}}
                                </p>
                              </td>
                            </tr>
                          </ng-template>
                        </ng-container>
                        </tbody>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="tab-pane fade" id="payoutdetails" role="tabpanel" aria-labelledby="payout-details">
            <div class="payout-details">
              <div class="row">
                <div class="col-12">
                  <button class="btn-action save-btn" (click)="submitScheduleData()">
                    Save
                  </button>
                  <div class="about-payout d-flex align-items-center mb-3">
                    <p class="fs-16 fw-bold mb-0 finances-detlis">You will receive the money in your account once the
                      product has been received by the buyer.</p>
                    <a class="fs-16 fw-bold mb-0 ms-1 policy" [routerLink]="['/vendor', 'payment-policy']"> Read
                      more about payouts</a>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="bank-account p-4 d-flex">
                    <div>
                      <h3 class="fs-16 fw-bold">{{vendorBillingDetail?.bankDetails?.bankName}}</h3>
                      <p class="fs-16 mb-0">Ended
                        in{{getAccountDetail(vendorBillingDetail?.bankDetails?.accountString)}}</p>
                    </div>
                    <div class="img-wrapper mx-auto me-0" data-bs-toggle="modal" data-bs-target="#bank-details">
                      <a [routerLink]="['/vendor', 'payoutDetail']" class="fs-16">
                        <img class="img-fluid" src="/assets/images/icons/Edit-orange.png"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="payout-schedule-title">
                <h2 class="fs-24 fw-bold my-5 mb-0">Payout schedule</h2>
                <p class="fs-16 pb-3 pt-3">Customise when and how you want to receive payouts</p>
                <div class="container-fluid p-0">
                  <div class="row mb-5">
                    <div class="col-md-5">
                      <div class="action-radio-orange-box pb-5">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                               (click)="showPayoutMenu('Automatic')" [checked]="isScheduleSelected === 'Automatic'">
                        <label class="form-check-label fs-14 fw-bold ms-2" for="flexRadioDefault1">
                          Automatic schedule
                        </label>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="action-radio-orange-box pb-5">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" value="Manual"
                               (click)="showPayoutMenu('Manual')" [checked]="isScheduleSelected === 'Manual'">
                        <label class="form-check-label fs-14 fw-bold ms-2" for="flexRadioDefault2">
                          Manual schedule
                        </label>
                      </div>
                    </div>
                    <div class="col-12">
                      <form [formGroup]="scheduleForm">
                        <ul class="nav nav-tabs p-0" id="PayOutTabs" role="tablist" *ngIf="!isMenuShow">
                            <li class="nav-item radio-details" role="presentation">
                                <div class="action-radio-box category-radio me-2 form-check mb-2 ps-0"  data-bs-toggle="tab" id="weeklyRadio" role="tab"
                                       data-bs-target="#weekly" aria-selected="true">
                                  <input type="radio" class="nav-link fs-12 fw-bold" value="Weekly" [checked]="scheduleTypes === 'Weekly'"
                                         name="scheduleType" (click)="showinputValue('Weekly')">
                                  <button class="btn-transparent radio-btn fs-12 fw-bold">Weekly</button>
                                </div>
                            </li>
                            <li class="nav-item radio-details" role="presentation">
                              <div class="action-radio-box category-radio me-2 form-check mb-2 ps-0"  data-bs-toggle="tab" id="monthlyRadio" role="tab"
                                   data-bs-target="#weekly" aria-selected="true">
                                <input type="radio" class="nav-link fs-12 fw-bold" value="Monthly" [checked]="scheduleTypes === 'Monthly'"
                                       name="scheduleType" (click)="showinputValue('Monthly')">
                                <button class="btn-transparent radio-btn fs-12 fw-bold">Monthly</button>
                              </div>
                            </li>
                        </ul>
                        <div class="tab-content" id="PayOutTabsContent">
                          <div class="tab-pane fade active show" id="weekly" role="tabpanel" aria-labelledby="weekly">
                            <div class="custom-details">
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-title" *ngIf="scheduleTypes === 'Weekly'">
                                    <kwot-custom-select
                                      class="filter-wallet-data"
                                      [searchable]="true"
                                      [placeholder]="'Select day'"
                                      [selectOptions]="dayName"
                                      selectedValue="scheduleDay"
                                      [formGroup]="scheduleForm"
                                      (optionSelected)="selectDay()"
                                    >
                                    </kwot-custom-select>
                                  </div>
                                  <div class="form-title" *ngIf="scheduleTypes === 'Monthly'">
                                    <div class="form-group">
                                      <input class="form-control" bsDatepicker type="text" formControlName="scheduleDate" readonly
                                             [bsConfig]="{
                                                isAnimated: true, adaptivePosition: true,
                                                showWeekNumbers: false, containerClass: 'custom-datepicker',
                                                dateInputFormat: 'DD/MM/YYYY'
                                              }"
                                             placeholder="Select date" (ngModelChange)="selectDate()">
                                    </div>
                                  </div>
                                </div>
                                <div [hidden]="scheduleMenu === 'Manual'">
                                  <div class="col-12">
                                    <div class="weekly-details d-flex align-items-center mt-4">
                                      <label class="action-switch-green-btn me-2 m-0">
                                        <input type="checkbox" (click)="showinput()" [checked]="isChecked">
                                        <span class="slider round"></span>
                                      </label>
                                      <h6 class="fs-16 mb-0 amount-title">Set minimum amount</h6>
                                    </div>
                                    <p class="fs-16 mt-3">
                                      This will trigger the withdrawal at the set frequency only when the available fund
                                      exceeds the set amount.
                                    </p>
                                  </div>
                                  <div class="col-md-4" *ngIf="showAmountBox">
                                    <div class="form-group">
                                      <label class="fs-12 mb-0 fw-bold">Minimum amount</label>
                                      <div class="position-relative">
                                        <input type="text" placeholder="1,000" class="form-control" formControlName="scheduleAmount">
                                        <span class="amount-icon fs-14">
                                    $
                                  </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div>

                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="billing" role="tabpanel" aria-labelledby="billing-details">
            <div class="row">
              <div class="col-md-4">
                <div class="billing-details p-4 d-flex">
                  <div>
                    <h3
                      class="fs-16 fw-bold">{{vendorBillingDetail?.billingDetails?.firstName}} {{vendorBillingDetail?.billingDetails?.lastName}}</h3>
                    <p
                      class="fs-16 billing-title mb-0">{{vendorBillingDetail?.billingDetails?.streetNumber}}
                      {{vendorBillingDetail?.billingDetails?.streetName}}
                      {{vendorBillingDetail?.billingDetails?.postalCode}}
                      {{vendorBillingDetail?.billingDetails?.city}}
                      {{vendorBillingDetail?.billingDetails?.state}}
                      {{vendorBillingDetail?.billingDetails?.country}}
                    </p>
                  </div>
                  <div class="img-wrapper mx-auto me-0" data-bs-toggle="modal" data-bs-target="#edit-bank-details">
                    <a [routerLink]="['/vendor', 'billingDetail']" class="fs-16">
                      <img class="img-fluid" src="/assets/images/icons/Edit-orange.png"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #withdrawAmountModelData>
    <div class="shipping-address">
      <div class="modal-header justify-content-center">
        <h4 class="modal-title fs-16 fw-bold text-center mb-0">{{'Withdraw Amount' | translate}}</h4>
        <button type="button" class="btn-close m-0" (click)="modalRef?.hide()"></button>
      </div>
      <div class="modal-body pt-0">
        <div class="account-content">
          <div class="mb-4">
            <div class="img-wrapper text-center">
              <img class="img-fluid" src="/assets/images/confirm-background.png"/>
            </div>
            <h2 class="fs-24 fw-bold text-center pt-4 m-0">{{'Are you sure you want to withdraw your amount?' | translate}}</h2>
            <p class="fs-16 text-center m-0">{{'delete_account_sub_note' | translate}}</p>
          </div>
          <div class="d-flex">
            <h6 class="fs-16 title fw-bold">KWOT Transaction Ref #</h6>
            <h6 class="ms-auto fs-16 title">{{this.vendorWalletDetail?._id}}</h6>
          </div>
          <div class="d-flex">
            <h6 class="fs-16 title fw-bold">Amount</h6>
            <h6 class="ms-auto fs-16 title">{{this.vendorWalletDetail?.wallet | currencyConvert | async}}</h6>
          </div>
          <div class="d-flex">
            <h6 class="fs-16 title fw-bold">Transaction Fees</h6>
            <h6 class="ms-auto fs-16 title">{{0 | currencyConvert | async}}</h6>
          </div>
          <div class="d-flex">
            <h6 class="fs-16 title fw-bold">Total Amount to be credited</h6>
            <h6 class="ms-auto fs-16 title">{{this.vendorWalletDetail?.wallet | currencyConvert | async}}</h6>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex flex-wrap justify-content-evenly m-0 mb-4">
        <button type="button" class="remove-btn fs-14 fw-bold btn-green-border mx-2 btn" (click)="withdrawAmount()"
                aria-label="Close">{{'Withdraw Amount' | translate}}</button>
        <button type="button" class="cancle-btn fs-14 fw-bold mx-2 btn-action btn" (click)="modalRef?.hide()"
                aria-label="Close">{{'cancel' | translate}}</button>
      </div>
    </div>
  </ng-template>
</div>
