<div class="orders-list">
  <div class="orders-list-title">
    <div class="row align-items-center">
      <div class="col-lg-5">
        <div class="back d-flex align-items-center">
          <img src="/assets/images/icons/back.svg" class="cursor-pointer" [routerLink]="['/vendor', 'dashboard']"/>
          <p class="title-back mb-0 fs-16 cursor-pointer"
             [routerLink]="['/vendor', 'dashboard']">{{'back' | translate}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="orders-list-wrapper">
    <div [ngClass]="{'d-none': ordersList.length <= 0 && !ordersFoundFirstTime}">
      <h2 class="fs-32 fw-bold">{{'orders' | translate}}</h2>
      <div class="custom-table order-list-custom-table">
        <div class="tag-filters">
        <span class="tag" [ngClass]="{'active': selectedFilter === filter.value}"
              (click)="filterOrders(filter.value)"
              *ngFor="let filter of filters">{{filter.label}}</span>
        </div>
        <kwot-custom-table
          #customTableComponent
          [customOptions]="customDatatableSettings"
          [ajaxFunction]="getAllOrders"
          [tableHead]="tableHead" [tableBody]="tableBody">
        </kwot-custom-table>
        <ng-template #tableHead>
          <thead *ngIf="!isMobile">
          <tr>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'order' | translate}}
              </p>
            </td>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'order_product_item' | translate}}
              </p>
            </td>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'date_time' | translate}}
              </p>
            </td>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'buyer' | translate}}
              </p>
            </td>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'product' | translate}}
              </p>
            </td>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'amount' | translate}}
              </p>
            </td>
            <td>
              <p
                class="title d-flex align-items-center position-relative mb-0 fs-14 fw-bold store-content-details">
                {{'status' | translate}}
              </p>
            </td>
            <td></td>
          </tr>
          </thead>
        </ng-template>
        <ng-template #tableBody>
          <tbody *ngIf="ordersList.length <= 0">
          <tr>
            <td colspan="8">{{'no_data_found' | translate}}</td>
          </tr>
          </tbody>
          <tbody *ngIf="ordersList.length > 0 && !isMobile">
          <tr *ngFor="let order of ordersList">
            <td>
              <p class="mb-0 fs-16 store-content-details cursor-pointer"
                 [routerLink]="['/vendor', 'orders', 'order-info', order._id]">
                #{{order.orderNo}}
              </p>
            </td>
            <td>
              <p class="mb-0 fs-16 store-content-details cursor-pointer">
                {{order['product'].sku}}
              </p>
            </td>
            <td>
              <p class="mb-0 fs-16 store-content-details">
                {{order.createdAt | date: 'dd/MM/yyyy'}}
              </p>
            </td>
            <td>
              <p class="mb-0 fs-16 cursor-pointer store-content-details">
                {{order.user?.firstName}} {{order.user?.lastName}}
              </p>
            </td>
            <td>
              <p class="mb-0 fs-16 store-content-details">
                {{order['product'].productName}}
              </p>
            </td>
            <td>
              <p class="mb-0 fs-16 store-content-details">
                {{(order.productOriginalPrice * order.quantity) | currencyConvert | async}}
              </p>
            </td>
            <td>
              <p class="mb-0 fs-16 store-content-details">
              <span class="status {{orderStatus[order.status] | slugify}}" *ngIf="!order?.isReturning">
                <span class="badge"></span>{{order.status == 5 ? 'Canceled By Buyer' : orderStatus[order.status]}}
              </span>
                <span class="status {{returnOrderStatus[order.returnStatus] | slugify}}" *ngIf="order?.isReturning">
                <span class="badge"></span>{{returnOrderStatus[order.returnStatus]}}
              </span>
              </p>
            </td>
            <td class="actions">
              <button type="button" class="btn-icon float-end" placement="bottom right"
                      containerClass="custom-popover no-arrow no-last-border"
                      [outsideClick]="true" container="body" [adaptivePosition]="false"
                      [popover]="popTemplate">
                <i class="fas fa-ellipsis-v"></i>
              </button>
              <ng-template #popTemplate>
                <ng-container *ngIf="order.status === 0 && !order.isReturning">
                  <div class="item" [routerLink]="['/vendor', 'orders', 'purchase-shipping-label', order?._id]">
                    <img src="/assets/images/icons/purchase_lables.svg" class="icon"/>
                    <span>{{'purchase_shipping_label' | translate}}</span>
                  </div>
                  <div class="item" [routerLink]="['/vendor', 'orders', 'order-info', order?._id]">
                    <i class="fa fa-check icon"></i>
                    <span>{{'mark_as_shipped' | translate}}</span>
                  </div>
                </ng-container>
                <!--                <ng-container *ngIf="order.status === 5">-->
                <!--                  <div class="item">-->
                <!--                    <img src="/assets/images/icons/inbox.svg" class="icon"/>-->
                <!--                    <span>{{'contact_buyer' | translate}}</span>-->
                <!--                  </div>-->
                <!--                </ng-container>-->
                <ng-container *ngIf="order.status === 3 && !order.isReturning">
                  <div class="item" [routerLink]="['/vendor', 'orders', 'order-info', order?._id]">
                    <img src="/assets/images/icons/track_order.svg" class="icon"/>
                    <span>{{'track_order' | translate}}</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="(order.status === 4 || order.status === 1) && !order.isReturning">
                  <div class="item" [routerLink]="['/vendor', 'orders', 'order-info', order?._id]">
                    <img src="/assets/images/icons/edit-eye.svg" class="icon"/>
                    <span>{{'view_order' | translate}}</span>
                  </div>
                </ng-container>

                <ng-container *ngIf="(order.status === 5 || order.status === 3) && !order.isReturning">
                  <div class="item" [routerLink]="['/vendor', 'orders', 'order-info', order?._id]">
                    <img src="/assets/images/icons/edit-eye.svg" class="icon"/>
                    <span>{{'view_invoice' | translate}}</span>
                  </div>
                </ng-container>
                <ng-container *ngIf="order.isReturning">
                  <ng-container *ngIf="order.returnStatus === 0">
                    <div class="item" [routerLink]="['/vendor', 'orders', 'return-shipping-label', order?._id]">
                      <img src="/assets/images/icons/return_product.svg" class="icon"/>
                      <span>{{'return_product' | translate}}</span>
                    </div>
                    <div class="item" (click)="rejectReturn(order?._id)">
                      <i class="fa fa-cancel icon"></i>
                      <span>{{'reject' | translate}}</span>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="order.returnStatus !== 0">
                    <div class="item" [routerLink]="['/vendor', 'orders', 'order-info', order?._id]">
                      <img src="/assets/images/icons/edit-eye.svg" class="icon"/>
                      <span>{{'view_invoice' | translate}}</span>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-template>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="ordersList.length > 0 && isMobile">
          <tr class="mobile-product-list">
            <td colspan="7">
              <div class="products-list-mobile" *ngFor="let order of ordersList">
                <div class="products-list-details text-start p-4">
                  <div class="product-list-title pb-4 flex-wrap justify-content-between store-content-details">
                    <span class="" [routerLink]="['/vendor', 'orders', 'order-info', order._id]">#{{order.orderNo}} | {{order.createdAt | date: 'dd/MM/yyyy'}}
                      at {{order.createdAt | date: 'h:mm a'}}</span>
                    <div class="pending-title">
                          <span class="status {{orderStatus[order.status] | slugify}}" *ngIf="!order?.isReturning">
                            <span class="badge"></span>{{orderStatus[order.status]}}
                          </span>
                      <span class="status {{returnOrderStatus[order.returnStatus] | slugify}}"
                            *ngIf="order?.isReturning">
                            <span class="badge"></span>{{returnOrderStatus[order.returnStatus]}}
                          </span>
                    </div>
                  </div>

                  <h5 class="pb-2 mb-0">{{order.user?.firstName}} {{order.user?.lastName}}</h5>
                  <div class="product-name-goes d-flex justify-content-between">
                    <p class="mb-0">{{order['product'].productName}}</p>
                    <h6 class="mb-0">{{(order.productOriginalPrice * order.quantity) | currencyConvert | async}}</h6>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </ng-template>
      </div>
    </div>
    <div [ngClass]="{'d-none': ordersList.length > 0}">
      <div class="no-order">
        <img src="/assets/images/product-stock.png" alt="No Products" class="img-fluid">
        <h2 class="text-center fw-bold mb-0" [innerHTML]="'no_order_title' | translate"></h2>
        <div class="no-order-notes">
          <div class="title">{{'no_order_notes.title' | translate}}</div>
          <div class="points">
            <p class="point"><i class="fa fa-check"></i>{{'no_order_notes.point1' | translate}}</p>
            <p class="point"><i class="fa fa-check"></i>{{'no_order_notes.point2' | translate}}</p>
            <p class="point"><i class="fa fa-check"></i>{{'no_order_notes.point3' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
