import {Component, Inject, Input, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {User} from "@kwot/data-models";
import {APP_CONFIG} from "@kwot/app-config";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ContactUsComponent} from "../../../../../vendor-buyer-shared/src/lib/components/contact-us/contact-us.component";

@Component({
  selector: 'kwot-vendor-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  currentRoute: string;
  @Input() currentUser: User;

  constructor(
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: any,
    private modelService: BsModalService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.split('/').filter(t => t);
        if (url[1]) {
          this.currentRoute = url[1];
        } else {
          this.currentRoute = 'dashboard';
        }
      }
    })
  }

  ngOnInit(): void {
  }

  openOdooModel() {
    if (this.appConfig.type === 'admin') {
      return
    }
    const modelRef: BsModalRef = this.modelService.show(ContactUsComponent, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model odoo-model',
    });
    modelRef.content.closeEvent?.subscribe((result: any) => {
    });
  }

}
