import {Component, OnInit, ViewChild} from '@angular/core';
import {
  AddPayoutData,
  AddScheduleData, GetPayoutDetail,
  GetVendorBankDetails,
  GetVendorBusinessDetails,
  GetWalletBalance, GetWalletTransaction, ResetVendorState
} from "../../+state/vendor.actions";
import {select, Store} from "@ngrx/store";
import {VendorState} from "../../+state/vendor.reducer";
import {Subject, takeUntil} from "rxjs";
import {
  getPayoutData,
  getVendorDetails,
  getwallet,
  getWalletTransaction
} from "../../+state/vendor.selectors";
import {Store as StoreModel, WalletDetails} from "@kwot/data-models";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {CustomTableComponent} from "@kwot/admin-vendor-shared";
import * as moment from "moment";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'kwot-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss']
})
export class FinanceComponent implements OnInit {
  vendor: StoreModel;
  vendorBillingDetail: StoreModel;
  vendorWalletDetail: WalletDetails;
  vendorWalletTransactionDetail: any = [];
  submitted = false;
  isMenuShow = false;
  unsubscriber = new Subject();
  allData: any;
  dateFilter: UntypedFormGroup;
  totalMonth: any[] = [
    {
      name: 'January',
      id: '01'
    },
    {
      name: 'February',
      id: '02'
    },
    {
      name: 'March',
      id: '03'
    },
    {
      name: 'April',
      id: '04'
    },
    {
      name: 'May',
      id: '05'
    },
    {
      name: 'June',
      id: '06'
    },
    {
      name: 'July',
      id: '07'
    },
    {
      name: 'August',
      id: '08'
    },
    {
      name: 'September',
      id: '09'
    },
    {
      name: 'October',
      id: '10'
    },
    {
      name: 'November',
      id: '11'
    },
    {
      name: 'December',
      id: '12'
    },
  ];

  currMonth: any[] = []

  month: any[] = [
    {
      name: 'Current Week',
      id: `${moment().startOf('week').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`
    },
    {
      name: 'Current Month',
      id: `${moment().startOf('month').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 30 days',
      id: `${moment().subtract(30, 'days').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 6 month',
      id: `${moment().subtract('6', 'month').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`
    },
    {
      name: 'Last 12 Months',
      id: `${moment().subtract('12', 'month').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`
    },
    {
      name: `Current Year (${new Date().getFullYear()})`,
      id: `${moment().startOf('year').format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`
    },
  ];
  customDatatableSettings: any = {};
  transactionDate: any = [];
  dates: any;
  selectedFilter: string | number = '';
  walletData: any;
  monthlyTransactionList: any = []
  currentPage = 1;
  itemsPerPage = 10;
  showAmountBox: boolean = false;
  scheduleForm: UntypedFormGroup;
  scheduleTypes = 'Weekly';
  scheduleMenu: any;
  walletId: any
  modalRef?: BsModalRef | null;
  vendorPayoutDetail: number;
  isChecked: boolean = false;
  isScheduleSelected = 'Automatic';
  scheduleDate: any;
  scheduleDay: any;

  dayName: any[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ]

  @ViewChild('customTableComponent', {static: true}) customTableComponent: CustomTableComponent;


  constructor(
    private vendorStore: Store<VendorState>,
    private formBuilder: UntypedFormBuilder,
    private modelService: BsModalService,
    private toastr: ToastrService
  ) {
    this.dateFilter = this.formBuilder.group({
      fromDate: [null, [Validators.required]],
      toDate: [null, [Validators.required]],
      monthRange: [null, [Validators.required]],
    })

    this.scheduleForm = this.formBuilder.group({
      scheduleType: [null, [Validators.required]],
      scheduleAmount: [null, [Validators.required]],
      scheduleDate: [null],
      scheduleDay: [null],
    })
    this.vendorStore.dispatch(ResetVendorState({params: {walletTransaction: null}}));
    this.subscribeToStore()
  }

  ngOnInit(): void {
    this.vendorStore.dispatch(GetVendorBankDetails());
    this.vendorStore.dispatch(GetVendorBusinessDetails());
    this.vendorStore.dispatch(GetWalletBalance());
    this.vendorStore.dispatch(GetPayoutDetail());

    this.customDatatableSettings = {
      paging: true,
      serverSide: true,
      columns: [{data: null}, {data: null}, {data: null}, {data: null}],
      addAjax: true,
      orderColumns: ['orderNo', 'type', 'createdAt', 'amount'],
      order: [[1, 'desc']],
      searching: false,
    }

    // const currentMonth = `${moment().startOf('month').format('MM')}`;
    // this.currMonth = this.totalMonth.slice(0, Number(currentMonth));

  }

  getAccountDetail(account: string) {
    return account?.toString().replace(new RegExp(/.(?=.{2})/g), " ");
  }

  subscribeToStore() {
    this.vendorStore.pipe(select(getVendorDetails))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(vendor => {
        if (vendor) {
          this.vendor = vendor;
        }
      });

    this.vendorStore.pipe(select(getVendorDetails))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(vendor => {
        if (vendor) {
          this.vendorBillingDetail = vendor;
        }
      })

    this.vendorStore.pipe(select(getwallet))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(walletAmount => {
        if (walletAmount) {
          this.vendorWalletDetail = walletAmount;
          if(this.vendorWalletDetail.scheduleType !== 'Manual') {
            this.scheduleTypes = this.vendorWalletDetail.scheduleType ? this.vendorWalletDetail.scheduleType : 'Weekly';
            this.isScheduleSelected = 'Automatic'
            if(this.vendorWalletDetail.scheduleAmount) {
              this.isChecked = true
              this.showAmountBox = true
            }
            this.scheduleForm.patchValue({
              scheduleAmount: this.vendorWalletDetail.scheduleAmount ? this.vendorWalletDetail.scheduleAmount : null,
              scheduleDate: this.vendorWalletDetail.scheduleDate ? moment(this.vendorWalletDetail.scheduleDate, 'YYYY-MM-DD[T]HH[:]mm[:]ss[.000Z]').format('DD/MM/YYYY') : null,
              scheduleDay: this.vendorWalletDetail.scheduleDay ? this.vendorWalletDetail.scheduleDay : null,
            })
          } else {
            this.isScheduleSelected = 'Manual'
            this.scheduleMenu = 'Manual'
            this.isMenuShow = true
            this.scheduleTypes = null;
          }
        }
      })

    this.vendorStore.pipe(select(getPayoutData))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(payout => {
        if(payout) {
          this.vendorPayoutDetail = payout.reduce((a, b) => a + b.amount, 0);
          this.modalRef?.hide();
        }
      })

    this.vendorStore.pipe(select(getWalletTransaction))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(getWalletTransaction => {
        if (getWalletTransaction) {
          this.vendorWalletTransactionDetail = getWalletTransaction;
          let groupViceTransaction: any = [];
          this.vendorWalletTransactionDetail.data.forEach((item: any) => {
            const formattedDate = moment(item.createdAt).startOf('month').format('MMMM YYYY')
            const currentList = groupViceTransaction[formattedDate] ?? []
            Object.assign(groupViceTransaction, {[formattedDate]: [...currentList, item]})
          })

          const groupViceTransactionValue: any = Object.values(groupViceTransaction);
          const groupViceTransactionIndex: any = Object.keys(groupViceTransaction);
          this.monthlyTransactionList = [];
          for (let i = 0; i < groupViceTransactionValue.length; i++) {
            this.monthlyTransactionList.push({value: groupViceTransactionIndex[i], type: 'group'});

            for (let j = 0; j < groupViceTransactionValue[i].length; j++) {
              this.monthlyTransactionList.push(groupViceTransactionValue[i][j]);
            }
          }
          if (this.customTableComponent.ajaxCallback) {
            this.customTableComponent.ajaxCallback({
              recordsTotal: this.vendorWalletTransactionDetail.count.total,
              recordsFiltered: this.vendorWalletTransactionDetail.count.total,
              data: []
            })

            setTimeout(() => {
              this.customTableComponent.reAdjustColumnsInTable();
            }, 500);
          }
        }
      })
  }

  filterWalletData(page: any = {}) {
    if (page?.page) {
      this.currentPage = page?.page;
    }
    if (this.dateFilter.value.monthRange) {
      this.dateFilter.patchValue({
        fromDate : null,
        toDate : null
      })
    }
    if ((this.dateFilter.value.fromDate && this.dateFilter.value.toDate) || this.dateFilter.value.monthRange) {
      this.vendorStore.dispatch(GetWalletTransaction({
        params: {
          page: this.currentPage - 1,
          limit: this.itemsPerPage,
          fromDate: this.dateFilter.value.fromDate,
          toDate: this.dateFilter.value.toDate,
          monthRange: this.dateFilter.value.monthRange
        }
      }))
    }
  }

  getAllWalletTransaction = (page = 0, perPage = 10, search = '', order = {
    filterBy: 'status',
    filterType: 'dec'
  }) => {
    this.vendorStore.dispatch(GetWalletTransaction({
      params: {
        page, limit: perPage,
        search,
        ...order
      }
    }))
  }

  showPayoutMenu(schedule: any) {
    this.scheduleMenu = schedule;
    if (this.scheduleMenu === 'Manual') {
      this.scheduleTypes = null;
    } else {
      this.scheduleTypes = 'Weekly'
    }
    this.isMenuShow = schedule !== 'Automatic';
  }

  showinput() {
    this.showAmountBox = !this.showAmountBox;
  }
  showinputValue(type: any) {
    this.scheduleTypes = type;
  }

  selectDay() {
    if(this.scheduleForm.value.scheduleDay) {
      this.scheduleDay = true
    }
  }

  selectDate() {
    if(this.scheduleForm.value.scheduleDate) {
      this.scheduleDate = true
    }
  }

  submitScheduleData() {
    let body;
    if(this.scheduleMenu === 'Manual') {
      body = {
        scheduleType: this.scheduleMenu,
      }
    } else {
      body = {
        scheduleType: this.scheduleTypes,
        scheduleAmount: this.scheduleForm.value.scheduleAmount ? this.scheduleForm.value.scheduleAmount : 1000,
        scheduleDate: this.scheduleDay ? '' : this.scheduleForm.value.scheduleDate,
        scheduleDay: this.scheduleDate ? '' : this.scheduleForm.value.scheduleDay,
      }
    }
    this.vendorStore.dispatch(AddScheduleData({body}));
  }

  withdrawAmountModel(template: any, walletId: any) {
    this.walletId = walletId;
    this.modalRef = this.modelService.show(template, {
      keyboard: true,
      animated: true,
      ignoreBackdropClick: false,
      class: 'modal-md modal-dialog-centered custom-model'
    })
  }

  withdrawAmount() {
    const body = {
      walletId: this.walletId
    }
    this.vendorStore.dispatch(AddPayoutData({body}));
    this.vendorStore.dispatch(AddScheduleData({body}));
    this.modalRef?.hide();
    this.toastr.success('Withdrawal Request was successfully raised');
  }
}
